import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';


export interface CanComponentDeactivate{
  confirm() : boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CandeactivateGuard implements CanDeactivate<CanComponentDeactivate>{
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      console.log('canDeactivate fired! Trying to navigate away from the component.');
    return component.confirm();
    /*let url: string = state.url;
    console.log('Url: '+ url);

    return component.canDeactivate ? component.canDeactivate() : true;*/
  }
}
