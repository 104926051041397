<!-- <div class="row">
    <div class="col-md-12" style="background-color: #b6d7a8;">
        <h3 style="padding: 1rem;">Cancel Follow Up Visit</h3>
    </div>
</div> -->
<div *ngIf="loading" class="spinner" style="height: 1em;">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Loading...</p>
</div>
<div *ngIf="!loading && !cancel && appointmentStatus==undefined">
    <div class="col-md-12">
        <div class="col-md-6" style="background: white;margin-top: 1em;overflow-y:auto">
            <div>
                <p class="highlight" style="margin-top: 1em;text-align: center;">Scheduled Follow up visit</p>
            </div>
            <div *ngFor="let content  of  recurringAppoinmentArrary;let j=index">
                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <div style="font-size: 40px;text-align: end;" class="col-md-2">
                                {{j+1}}
                            </div>
                            <div class="col-md-7">
                                <div class="appointment_slot">
                                    <p>{{ content.date | date}}
                                        {{
                                        content.slot
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6" style="background: white;margin-top: 2em;overflow-y:auto">
            <div>
                <p class="highlight" style="text-align: center;">Contact Information</p>
            </div>
            <form>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <input matInput name="first_name" [(ngModel)]="contactInfo.first_name" size="100"
                                    placeholder="First Name" readonly>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                                <input matInput name="last_name" [(ngModel)]="contactInfo.last_name"
                                    placeholder="Last Name" readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Removed phone number pattern check -->
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field class="example-full-width">

                                <input matInput id="phone_no" #country_code="ngModel" name="country_code"
                                    [(ngModel)]="contactInfo.countryCode" pattern="" placeholder="Country Code"
                                    readonly />
                                <!-- <mat-select name="countryCode" [(ngModel)]="contactInfo.countryCode" [disabled]="true">
                    <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                      {{ country.code }} ({{ country.name }})
                    </mat-option>
                  </mat-select> -->
                            </mat-form-field>
                        </div>
                        <div class="col-md-9">
                            <mat-form-field class="example-full-width">
                                <input matInput id="phone_no" #phone_no="ngModel" name="phone_no"
                                    [(ngModel)]="contactInfo.phone" pattern="" placeholder="Phone Number" readonly />
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Removed email pattern check [A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$ -->
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field class="example-full-width">
                                <input matInput id="email" #email="ngModel" name="email" [(ngModel)]="contactInfo.email"
                                    placeholder="Email" readonly />
                            </mat-form-field>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="col-md-12 text-center" id="bottom">
        <button style="margin-top: 1em;" color='primary' mat-raised-button type="submit" (click)="cancelAppointments()">
            Cancel All Appointment
        </button>
    </div>
</div>

<div *ngIf="cancel">
    <h3 style="text-align: center;
    padding-top: 5em;">All Appointments Cancelled Succesfully</h3>
</div>
<div *ngIf="appointmentStatus!=undefined">
    <div class="msg">
        <div class="border">
            <i class="material-icons">notification_important</i>
            <p class="notify">This appointment has already been {{appointmentStatus}}!</p>
        </div>
    </div>
</div>