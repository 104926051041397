<div class="container-fluid">
  <div *ngIf="isReady; then ready else not_ready">
  </div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <!-- <p *ngIf="providers.length!==0 && !islocation" class="center">Please select your preferred provider</p>
    <p *ngIf="providers.length!==0 && islocation" class="center">Please select your preferred provider at
      {{locationName| titlecase}}</p> -->
    <!-- <div *ngIf="providers.length!==0">
      <app-header></app-header>

    </div> -->
    <br>
    <div class="row" id="subHeader">
      <div class="col-md-4" id="locationBackButton">


        <div *ngIf="appointmentService.routerPaths.length>1">
          <div
            *ngIf="appointmentService.routerPaths[appointmentService.routerPaths.length-2].url.substr(1,9)=='locations';else back">
            <span style="cursor: pointer;margin-top: -15px;" class="material-icons arrow" (click)="goBack()">
              keyboard_arrow_left
            </span>
            <a (click)="goBack()">Select Another Location</a>
          </div>
          <ng-template #back>
            <span style="cursor: pointer;margin-top: -10px;" class="material-icons arrow" (click)="goBack()">
              keyboard_arrow_left
            </span>
            <a (click)="goBack()"> Back</a>
          </ng-template>
        </div>


      </div>
      <div class="col-md-4" style="text-align: center;">
        <b>Select a Provider</b>
      </div>
      <div class="col-md-4">

      </div>

    </div>


    <div *ngIf="providers.length==0">No Provider</div>

    <div id="card-container-desk" *ngIf="providers.length >= 1">
      <div *ngFor="let provider of providers">
        <mat-card id="matCard">
          <div *ngIf="provider.img_url;else doctorImg">
            <img [src]="provider.img_url" alt="imageAlt">
          </div>
          <ng-template #doctorImg>
            <div>
              <img src="assets/doctor.jpg" alt="imageAlt">
            </div>
          </ng-template>

          <h5 style="font-size: 1.6rem;">{{ provider.fullname |titlecase }}</h5>
          <!-- <a *ngIf="islocation" (click)="callService(location)"><h4>{{ provider.fullname }}</h4></a> -->
          <!-- <a *ngIf="islocation" [routerLink]="['/services', acc_slug, provider.slug,location ]">
            <h4>{{ provider.fullname|titlecase }}</h4>
          </a>
          <p>{{ provider.degree}}</p>
          <p style="overflow-wrap: break-word;">{{provider.email}}</p>
          <a href="tel:{{ provider.phone }}">{{provider.phone}}</a> -->
          <div *ngIf="!islocation" [routerLink]="['/locations', acc_slug, provider.slug ]" id="bookApoinment">
            <h4>{{appointmentService.accDetails.requestedAppointment?'Request Appointment':'Book Appointment'}}</h4>
          </div>
          <div *ngIf="islocation" [routerLink]="['/services', acc_slug, provider.slug,location ]" id="bookApoinment">
            <h4>{{appointmentService.accDetails.requestedAppointment?'Request Appointment':'Book Appointment'}}</h4>
          </div>
        </mat-card>
      </div>
    </div>
    <div id="card-container-mobile" *ngIf="providers.length >= 1">
      <div *ngFor="let provider of providers">
        <mat-card id="matCard">
          <div *ngIf="provider.img_url;else doctorImg">
            <img [src]="provider.img_url" alt="imageAlt">
          </div>
          <ng-template #doctorImg>
            <div>
              <img src="assets/doctor.jpg" alt="imageAlt">
            </div>
          </ng-template>

          <h5 style="font-size: 1.6rem;">{{ provider.fullname |titlecase }}</h5>
          <!-- <a *ngIf="islocation" (click)="callService(location)"><h4>{{ provider.fullname }}</h4></a> -->
          <!-- <a *ngIf="islocation" [routerLink]="['/services', acc_slug, provider.slug,location ]">
            <h4>{{ provider.fullname|titlecase }}</h4>
          </a>
          <p>{{ provider.degree}}</p>
          <p style="overflow-wrap: break-word;">{{provider.email}}</p>
          <a href="tel:{{ provider.phone }}">{{provider.phone}}</a> -->
          <div *ngIf="!islocation" [routerLink]="['/locations', acc_slug, provider.slug ]" id="bookApoinment">
            <h4>{{appointmentService.accDetails.requestedAppointment?'Request Appointment':'Book Appointment'}}</h4>
          </div>
          <div *ngIf="islocation" [routerLink]="['/services', acc_slug, provider.slug,location ]" id="bookApoinment">
            <h4>{{appointmentService.accDetails.requestedAppointment?'Request Appointment':'Book Appointment'}}</h4>
          </div>
        </mat-card>
      </div>
    </div>
  </ng-template>
</div>
<div class="footer">
  <app-footer [back]="true" [summary]="false" [next]="false" (goBack)="goBack()">
  </app-footer>
</div>