import { Component, OnInit, ViewChild, Input, Output, } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { COUNTRY_CODES } from "../_constants/country_phone_codes";
import * as moment from "moment-timezone";
import { PaypalService } from "../_services/paypal.service";
declare var paypal;
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-paypal-payment',
  templateUrl: './paypal-payment.component.html',
  styleUrls: ['./paypal-payment.component.scss']
})
export class PaypalPaymentComponent implements OnInit {
  @ViewChild('paypal') paypalElement;
  @Input() formData: any;
  @Input() paymentDetails: any;
  @Output() paymentStatus = new EventEmitter<any>();
  countryCodes = COUNTRY_CODES;
  accSlug = "the-hairloss-clinic";

  country_code: any = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  ).country_code;
  constructor(
    private paypalService: PaypalService,
  ) { }

  ngOnInit() {
    console.log(this.formData);
    let data = {
      "intent": "authorize",
      "payer": {
        "payment_method": "paypal"
      },
      "redirect_urls": {
        "return_url": "http://localhost:4200/payments/the-hairloss-clinic",
        "cancel_url": "http://localhost:4200/payments/the-hairloss-clinic"
      },
      "transactions": [{
        "item_list": {
          "items": [{
            "name": "item",
            "sku": "item",
            "price": "1.00",
            "currency": "USD",
            "quantity": 1
          }]
        },
        "amount": {
          "currency": "USD",
          "total": "1.00"
        },
        "description": "This is the payment description."
      }]
    };
    // this.paypalService.createPaymentRequest("the-hairloss-clinic",data);
    // paypal.Buttons({
    //   createOrder:(data, actions)=>{
    //     // This function sets up the details of the transaction, including the amount and line item details.
    //     this.paymentStatus.emit({status:'initiated'});
    //     return actions.order.create({
    //       intent: 'CAPTURE',
    //       payer: {
    //         name: {
    //           given_name: this.formData.first_name,
    //           surname: this.formData.last_name
    //         },
    //         email_address: this.formData.email,
    //         phone:{
    //           phone_type:'MOBILE',
    //           phone_number:{
    //             national_number:this.formData.phone_no,//"4084105025",
    //           }
    //         },
    //         address:{
    //           address_line_1:this.formData.street,
    //           address_line_2:'',
    //           admin_area_1: this.formData.state,
    //           admin_area_2: this.formData.city,
    //           postal_code:this.formData.postalCode,
    //           country_code:this.country_code

    //         }
    //       },
    //       purchase_units: [
    //         {
    //           amount: {
    //             currency_code: this.paymentDetails.currency_code,//'USD',
    //             value: this.paymentDetails.amount//'31.97'
    //           }
    //         }
    //       ],
    //       application_context:{
    //         shipping_preference:'NO_SHIPPING',
    //       },
    //       advanced: {
    //         commit: 'true'
    //       },
    //       style: {
    //         label: 'paypal',
    //         layout: 'vertical'
    //       },
    //     });
    //   },
    //   onApprove:(data, actions) =>{
    //     // This function captures the funds from the transaction.
    //     return actions.order.capture().then((details)=> {
    //       // This function shows a transaction success message to your buyer.
    //       console.log(details)
    //       this.paymentStatus.emit({status:'successful',data:details});
    //       alert('Transaction completed by ' + details.payer.name.given_name);
    //     });
    //   },
    //   onShippingChange:(data, actions)=> {
    //     //if not needed do nothing..
    //     console.log("shipping changed");
    //     return actions.resolve();
    //   },
    //   onCancel: (data) => { // Show a cancel page, or return to cart
    //     console.log(data);
    //     this.paymentStatus.emit({status:'closed'});
    //     console.log("cancled");
    //    },
    //   onError: error => {
    //     this.paymentStatus.emit({status:'error',data:error});
    //     console.log("error",error);
    //   }
    // }).render(this.paypalElement.nativeElement)
  }


}
