<div class="container-fluid top-space">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <p class="center">Please select your preferred provider</p>
    <div id="card-container">
      <div *ngFor="let provider of providers" class="card-block plan">
        <mat-card *ngIf="provider.calendarData.length == 1">
          <a
            (click)="setCalendarRef(provider.calendar_link[0], provider.docId)"
            [routerLink]="[
              '/reschedule-calendar',
              acc_slug,
              provider.calendarData[0].slug
            ]"
            [queryParams]="{ multipleProviders: true }"
            ><h4>{{ provider.name }}</h4></a
          >
          <p>{{ provider.qualification }}</p>
          <!-- <a href="tel:{{calendar.location.phone}}">{{ calendar.location.phone }}</a> -->
        </mat-card>
        <mat-card *ngIf="provider.calendarData.length > 1">
          <a
            (click)="setproviderName(provider.docId)"
            [routerLink]="[
              '/reschedule-provider-locations',
              acc_slug,
              provider.docId
            ]"
            [queryParams]="{ multipleProviders: true }"
            ><h4>{{ provider.name }}</h4></a
          >
          <p>{{ provider.qualification }}</p>
          <!-- <a href="tel:{{calendar.location.phone}}">{{ calendar.location.phone }}</a> -->
        </mat-card>
      </div>
    </div>
  </ng-template>
</div>
