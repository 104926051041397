import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';

@Component({
  selector: 'app-reschedule-calendar-groups',
  templateUrl: './reschedule-calendar-groups.component.html',
  styleUrls: ['./reschedule-calendar-groups.component.css']
})
export class RescheduleCalendarGroupsComponent implements OnInit {

  acc_slug: string;
  calendars: any = [];
  isReady : boolean = false;
  redirectUrl : string;
  group_slug: string;
  startUrl:string;
  oldDate:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: RescheduleAppointmentService
  ) { }


    ngOnInit() {
      console.log(this.router.url);
      this.redirectUrl = this.router.url;
    // this.appointmentService.saveRedirectUrl(this.redirectUrl);
      this.route.queryParams
          .subscribe(
            queryParams => {
              console.log(queryParams.source);
              let source = this.appointmentService.getSource();
              if(source === undefined) {
                source = queryParams.source === undefined ? 'website' : queryParams.source;
                this.appointmentService.setSource(source);
              }
            }
          );

      this.route.url
                .subscribe(
                  url => {
                    this.startUrl = url[0].path;
                    console.log(this.startUrl);
                    this.acc_slug = url[1].path;
                    this.group_slug = url[2].path;
                    this.appointmentService.getCalendarGroups(this.acc_slug, this.group_slug)
                                           .then(
                                             calendars => {
                                                this.calendars = calendars;
                                                console.log(this.calendars);
                                                this.isReady= true;
                                             }

                                           );
                                        /*   if(this.startUrl == "reschedule-calendar-groups"){
                                             this.oldDate = this.appointmentService.getOldAppointmentDate();
                                           }*/


                  }

                );


    }

  }
