import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment-timezone";
import { CalendarComponent } from '../calendar/calendar.component';
import { AppointmentService } from '../_services/appointment.service';
import { DatePipe } from '@angular/common'
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';

@Component({
  selector: 'app-strip-clender',
  templateUrl: './strip-clender.component.html',
  styleUrls: ['./strip-clender.component.css']
})

export class StripClenderComponent implements OnInit {
  @Output() onDateSelect = new EventEmitter<any>();
  isReady: boolean = false
  selectedDate = -1;
  monthTitle: string = '';
  rollingWeeksArray: any = [];
  today = 0;
  maxDate: number;
  minDate: number;
  starting = 0;
  ending = 5;
  dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  blocked_dates: any = [];
  filterDays: any = [];
  setting: any = {};
  oldDate: any;
  oldTimeSlot: string;
  weeKChangeCount: number = 0
  constructor(
    private appointmentService: AppointmentService,
    public datepipe: DatePipe,
    public rescheduleAppointmentService: RescheduleAppointmentService,
  ) {

  }

  ngOnInit() {
    if (this.appointmentService.stripCalenderEnding > 0) {
      this.ending = this.appointmentService.stripCalenderEnding;
      this.starting = this.appointmentService.stripCalenderStarting;
      this.setMonthTitle()
    }

    this.appointmentService.slotIndexFound$.subscribe(data => {
      if (data.index >= 0 && this.appointmentService.stripCalenderEnding == -1) {
        console.log(data.index)
        let weekIndex = ~~(data.index / 5);
        this.ending = (weekIndex + 1) * 5;
        this.starting = this.ending - 5;
        this.onDateChange(data.index);
        this.setMonthTitle();
        this.isReady = true
      }
      if (this.appointmentService.stripCalenderEnding > 0 && !this.isReady) {
        this.isReady = true
        this.ending = this.appointmentService.stripCalenderEnding;
        this.starting = this.appointmentService.stripCalenderStarting;
        this.selectFirstDateOfWeek()
      }

    })

    this.oldDate = this.rescheduleAppointmentService.getOldAppointmentDate();
    this.oldTimeSlot = this.rescheduleAppointmentService.getOldAppointmentTime();

    this.setting = this.appointmentService.getSettings();
    this.blocked_dates = this.setting.blocked_dates;
    this.filterDays = this.setting.filterDays;
    this.maxDate = this.setting.maxDate.key;
    this.minDate = this.setting.minDate.key;
    var day = moment().format('ddd, MMMM DD YYYY, H:MM')
    this.rollingWeeksArray.push(day);
    for (let i = 1; i <= (7 * this.maxDate) + 6; i++) {
      var newDay = moment(this.rollingWeeksArray[this.rollingWeeksArray.length - 1]).add(1, 'day').format('ddd, MMMM DD YYYY, H:MM')
      this.rollingWeeksArray.push(newDay);
    }

    if (this.appointmentService.getSelectedDate()) {
      this.selectedDate = this.appointmentService.getSelectedDate();
    }
    else {
      for (let i = 0; i < this.rollingWeeksArray.length; i++) {
        if (!this.isBlock(i)) {
          // this.onDateChange(i);
          break
        }
      }
    }

    this.setMonthTitle();
    this.setStripCalenderSelectedDate();
  }

  weekIncrement() {
    this.weeKChangeCount = this.weeKChangeCount + 1
    if ((this.rollingWeeksArray.length - this.ending) >= 6) {
      this.starting = this.starting + 5;
      this.ending = this.ending + 5;
      this.setMonthTitle();
      this.selectFirstDateOfWeek()
    }
  }

  selectFirstDateOfWeek() {
    for (let i = this.starting; i < this.ending; i++) {
      if (!this.isBlock(i)) {
        this.onDateChange(i);
        break
      }
    }
  }


  weekDeccrement() {
    this.weeKChangeCount = this.weeKChangeCount + 1
    if (this.starting != 0) {
      this.starting = this.starting - 5;
      this.ending = this.ending - 5;
    }
    this.setMonthTitle();
    this.selectFirstDateOfWeek()

  }

  setMonthTitle() {
    if (this.ending > 5 && this.starting > 0) {
      this.appointmentService.stripCalenderEnding = this.ending;
      this.appointmentService.stripCalenderStarting = this.starting
    }
    else {
      if (this.weeKChangeCount > 0) {
        this.appointmentService.stripCalenderEnding = this.ending;
        this.appointmentService.stripCalenderStarting = this.starting
      }
    }
    var firstDayofWeek = new Date(this.rollingWeeksArray[this.starting]);
    var lastDayofWeek = new Date(this.rollingWeeksArray[this.ending - 1]);

    if (firstDayofWeek.toLocaleString('default', { month: 'long' }) == lastDayofWeek.toLocaleString('default', { month: 'long' }))
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    else
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    if (lastDayofWeek.getUTCFullYear() != firstDayofWeek.getUTCFullYear())
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + firstDayofWeek.getUTCFullYear() + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()
  }

  isShow(index) {
    if (index >= this.starting && index < this.ending)
      return true

    else
      return false
  }

  onDateChange(index) {
    this.selectedDate = index
    let selectedDateOnStrip = moment(this.rollingWeeksArray[index]);
    this.appointmentService.setSelectedDate(selectedDateOnStrip);
    this.onDateSelect.emit(selectedDateOnStrip);
  }

  isBlock(index) {

    if (index > 7 * this.maxDate)
      return true

    if (index < this.minDate)
      return true

    for (let i = 0; i < this.filterDays.length; i++) {
      if (this.rollingWeeksArray[index].substring(0, 3) == this.dayOfWeek[this.filterDays[i]])
        return true
    }

    for (let i = 0; i < this.blocked_dates.length; i++) {
      var date = new Date(this.rollingWeeksArray[index]);
      if (this.datepipe.transform(date, 'yyyy-MM-dd') == this.blocked_dates[i])
        return true
    }

    return false
  }

  setStripCalenderSelectedDate() {
    this.selectedDate = this.appointmentService.getSelectedDate();
    for (let i = 0; i <= (7 * (this.maxDate + 1)); i++) {
      if (this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd') == this.datepipe.transform(this.rollingWeeksArray[i], 'yyyy-MM-dd')) {
        this.selectedDate = i;
        for (let j = 1; j < this.maxDate + 1; j++) {
          if (this.selectedDate >= (7 * j) && this.selectedDate < (7 * (j + 1))) {
            this.starting = 7 * j;
            this.ending = 7 * (j + 1);
          }
        }
        break;
      }
    }
  }
}
