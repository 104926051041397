<div *ngIf="showSummryForMobile==true; " class="mobile-view-summary">
  <app-appointment-details></app-appointment-details>
</div>
<div id="payment-page" class="center">

  <div id="deskView">
    <div class="row">
      <div class="row" id="subHeader">
        <div class="col-xs-4">
          <span *ngIf="isReady">
            <span *ngIf="settings.terms_conditions; else elseBlock">
              <span style="cursor: pointer;margin-top: -15px;" class="material-icons arrow"
                [routerLink]="['/confirm-appointment']">
                keyboard_arrow_left
              </span>
              <a [routerLink]="['/terms-and-conditions']">Terms And Conditions</a>
            </span>
            <ng-template #elseBlock>
              <span style="cursor: pointer;margin-top: -15px;" class="material-icons arrow"
                [routerLink]="['/confirm-appointment']">
                keyboard_arrow_left
              </span>
              <a [routerLink]="['/confirm-appointment']">Contact Information</a>
            </ng-template>
          </span>
        </div>
        <div style="text-align: center;" class="col-xs-4">
          <b>Payment</b>
        </div>
        <div class="col-xs-4">
        </div>
      </div>
    </div>

  </div>
  <div id="mobileView">
    <div class="col-xs-12" id="subHeader">
      <div class="col-xs-12" style="font-size:large;text-align: center;">
        <b>Payment</b>
      </div>
    </div>
  </div>

  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 " id="payment">
          <div class="paymentDiv">
            <div style="display: inline-flex;">
              <mat-card>
                <mat-card-content>
                  <div [innerHTML]="paymentInfo"
                    style="display: flex;max-height:250px;overflow-y:auto;padding: 5px;text-align: initial;font-size: large;font-size: large;">
                  </div>
                  <div *ngIf="settings.paymentDetails!==undefined else paymentDetailsNotSet">
                    <div class="row" style="padding: 5px;font-size: initial;display: flex;">

                      <div class="col" style="text-align: right;padding: 5px; width: 50%;"><b>Amount :</b></div>
                      <div class="col" style="text-align: left;padding: 5px;width: 50%;">
                        <b>{{settings.paymentDetails.amount|currency:settings.paymentDetails.currency_code:'symbol':"1.2-2"}}</b>
                      </div>
                    </div>
                    <div
                      *ngIf="settings.paymentDetails.paymentMethods==undefined || settings.paymentDetails.paymentMethods?.includes('paypal') ">
                      <div style="width: 100%; text-align: center; padding:5px;">
                        <img class="paypal-button" style="cursor: pointer;" src="../../assets/icon/paypal.png"
                          alt="Check out with PayPal" (click)="generatePaymentAndSaveAppointment()" />
                        <!-- <button mat-raised-button color="primary" type="button"
                            (click)="generatePaymentAndSaveAppointment()">Paynow</button> -->
                      </div>
                    </div>
                    <div *ngIf="settings.paymentDetails.paymentMethods?.includes('paytm') ">
                      <div style="width: 100%; text-align: center; padding:5px;">
                        <img class="paytmButton" src="../../assets/icon/paytm.svg" (click)="paymentRequestForPaytm()">
                        <!-- <button mat-raised-button color="primary" type="button"
                            (click)="paymentRequestForPaytm()">Paynow</button> -->
                      </div>
                    </div>

                  </div>
                  <ng-template #paymentDetailsNotSet>
                    <div class="col-md-12 text-center">
                      <button mat-raised-button color="primary" type="button" #apptConfirm
                        (click)="confirmAppointment(); apptConfirm.disabled=true;">
                        Confirm Appointment
                      </button>
                    </div>
                  </ng-template>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>
        <div class="col-md-3" id="summary">
          <app-appointment-details></app-appointment-details>
        </div>
      </div>
    </div>



  </ng-template>
</div>

<div *ngIf="isReady" class="footer">
  <app-footer [back]="true" [summary]="true" (summaryShow)="summaryShow($event)" (goBack)="goBack()">
  </app-footer>
</div>