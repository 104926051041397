<div [hidden]="!hideCalendar" style="overflow-x: hidden">
  <div *ngIf="loading" class="spinner" style="height: 1em;">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Loading...</p>
  </div>

  <div *ngIf="!loading" style="overflow-x: hidden;background: #efefef;">
    <ng-container *ngFor="let recurring of  recurringSchedule;let i=index;">
      <div *ngIf="showClaendar==i" class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <div class="row" style="margin-top: 1em; background: white;height: 35em;">
              <div class="col-md-6">
                <div>
                  <p class="highlight" style="text-align: center;margin-top: 1em;">Please Select a Date for
                    {{numberApend[showClaendar]}} follow up visit
                  </p>
                </div>
                <mat-calendar [minDate]="recurring.minDate" [maxDate]="recurring.maxDate" [dateFilter]="myFilter"
                  [selected]="recurring.selectedDate" (selectedChange)="onDateSeleted($event,i)" class="fixedPosition">
                </mat-calendar>
              </div>
              <div class="col-md-6" style="margin-top: 6em;">
                <div *ngIf="recurring.dateSelected">
                  <div class="container-fluid">
                    <div class="row">
                      <div style="height: 28em;overflow-y: auto;">
                        <app-recuuring-timeslots [selectedDate]="recurring.date" [index]="i"
                          (onTimeSlotSelected)="onTimeSlotChange($event)"></app-recuuring-timeslots>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-md-1">
            <div class="vertical_dotted_line"></div>
          </div>

          <div class="col-md-4" style="height: 35em;background: white;margin-top: 1em;overflow-y:auto">
            <div>
              <p class="highlight" style="margin-top: 1em;"> Selected Slots for follow up visit
              </p>
            </div>
            <div *ngFor="let content  of  recurringSchedule;let j=index">
              <div *ngIf="content.selectedSlot.start">
                <div>
                  <div class="row">
                    <div style="font-size: 40px;text-align: start;" class="col-md-1">
                      {{j+1}}
                    </div>
                    <div class="col-md-8">
                      <div class="appointment_slot">
                        <p>{{ content.date | date}}
                          {{ appointmentService.convertToLocalTime(content.selectedSlot.start)}} -
                          {{ appointmentService.convertToLocalTime(content.selectedSlot.end)}}
                        </p>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div *ngIf="content.slotConfirm">
                        <button style="margin-top: 1em;" mat-raised-button color="primary" (click)="changeSlot(j)">
                          Change
                        </button>
                        <!-- <span class="material-icons">
                          edit
                        </span> -->
                      </div>
                      <div *ngIf="!content.slotConfirm">
                        <button style="margin-top: 1em;" mat-raised-button color="primary" (click)="confirmSlot(j)">
                          Confirm
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="appointmentService.routerPaths.length>1 else go">
      <div class="row">
        <div class="col-md-6 text-center">
          <button style="margin-top: 1em;margin-bottom: 1em;margin-left: 8em;" mat-raised-button color="primary"
            (click)="goBack()" type="submit">
            Back
          </button>
        </div>
        <div *ngIf="recurringSchedule.length==this.recurringSetting.numberOfVisit" class="col-md-6 text-center">
          <button style="margin-top: 1em;margin-bottom: 1em;margin-left: 8em;" mat-raised-button color="primary"
            (click)="hideCalendar=false" [disabled]="!allSlotAreSelected" type="submit">
            Continue
          </button>
        </div>
      </div>
    </div>
    <ng-template #go>
      <div class="row" *ngIf="recurringSchedule.length==this.recurringSetting.numberOfVisit">
        <div class="col-md-12 text-center">
          <button style="margin-top: 1em;margin-bottom: 1em;margin-left: 8em;" mat-raised-button color="primary"
            (click)="hideCalendar=false" [disabled]="!allSlotAreSelected" type="submit">
            Continue
          </button>
        </div>
      </div>
    </ng-template>

  </div>
</div>
<div *ngIf="!hideCalendar">
  <app-recurring-conact-details (goBack)="back($event)" (confirmAppointment)="confirmAppointment()"
    [contactInfo]="contactInfo" [recurringSchedule]="recurringSchedule">
  </app-recurring-conact-details>
</div>