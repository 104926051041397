import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-calendar-providers-group',
  templateUrl: './calendar-providers-group.component.html',
  styleUrls: ['./calendar-providers-group.component.css']
})
export class CalendarProvidersGroupComponent implements OnInit {
  acc_slug: string;
  redirectUrl : string;
  providers : any =[];
  isReady : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService

  ) { }

  ngOnInit() {
    console.log(this.router.url);
    this.route.queryParams
        .subscribe(
          queryParams => {
            // console.log(queryParams.source);
            let source = this.appointmentService.getSource();
            let category = this.appointmentService.getCategory();
            if(source === undefined) {
              source = queryParams.source === undefined ? 'website' : queryParams.source;
              this.appointmentService.setSource(source);
            }
            if(category === undefined) {
              category = queryParams.category === undefined ? '' : queryParams.category;
              this.appointmentService.setCategory(category);
            }
          }
        );

    this.route.url
              .subscribe(
                url => {
                  this.acc_slug = url[1].path;
                  let group_slug = url[2].path;
                  this.appointmentService.getProvidersCalendar(this.acc_slug, group_slug)
                                         .then(
                                           providers => {
                                              this.providers = providers;
                                              this.appointmentService.multipleProviders=true;
                                              console.log(this.providers);
                                              this.isReady= true;
                                           console.log(this.appointmentService.getProviderCalendarData("88ObcbVf16ScYrDnuisU"));
                                           }

                                         );


                });



//   this.providers= [{
//     name : 'Aditya Patil',
//     qualification : 'MD'
//   }
//   {
//     name : 'Harshal Oval',
//     qualification : 'MBBS'
//   }
//
// ];

  }
  setCalendarRef(ref,docId){
    this.appointmentService.calendarRef = ref;
    this.appointmentService.setProviderDetails(docId);
  }

}
