<div style="overflow-y: hidden" id="stripTimeslotDiv">
  <div *ngIf="isReady;else not_ready">
    <div class="center">
      <app-strip-clender (onDateSelect)="onDateChange($event)"></app-strip-clender>
      <div class="spinner" *ngIf="loading" style=" margin-top: -1em;">
        <div class="spinner">
          <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
          <p>Loading...</p>
        </div>
      </div>
      <div class="container-fluid" *ngIf="!loading">
        <div *ngIf="availableSlots.length > 0">
          <p class="highlight" style="text-align: center;">What time works for you on {{dayMonthFormatDate()}}?
          </p>
          <div style="text-align: center;">
            <small>* Timings are in
              {{ localTimezone }}</small>
          </div>

          <div style="overflow-y: auto;height: 58vh;padding-bottom: 7em;">
            <div class="appointment_slot" [class.selected]="selectedSlot === timeslot"
              *ngFor="let timeslot of availableSlots" (click)="setTimeslot(timeslot)">
              <!-- <p>{{ timeslot.start }} - {{ timeslot.end }}</p> -->
              <p>
                {{ timeslot.localTimezoneStart}} -
                {{ timeslot.localTimezoneEnd}}
              </p>
            </div>
          </div>

        </div>
        <div *ngIf="availableSlots.length == 0">
          <!-- <i class="material-icons status-symbol">cancel</i> -->
          <p class="highlight" style="text-align: center;">
            Sorry, all slots for {{dayMonthFormatDate()}} have been booked.
          </p>
        </div>
      </div>

    </div>
  </div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>

</div>