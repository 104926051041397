<div class="container-fluid top-space">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <p class="center">Please select your preferred location</p>
    <div id="card-container">
      <div *ngFor="let calendar of calendars" class="card-block plan">
        <mat-card>
          <a
            [routerLink]="['/calendar', acc_slug, calendar.slug]"
            [queryParams]="{ multipleLocations: true }"
            ><h4>{{ calendar.location.name }}</h4></a
          >
          <p style="word-wrap: break-word;">{{ calendar.location.address }}</p>
          <a href="tel:{{ calendar.location.phone }}">{{
            calendar.location.phone
          }}</a>
        </mat-card>
      </div>
    </div>
  </ng-template>
</div>
