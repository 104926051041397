import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";
import { Location } from "@angular/common"
import { RescheduleAppointmentConfirmationComponent } from "../reschedule-appointment-confirmation/reschedule-appointment-confirmation.component";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";
import { isThisQuarter } from "date-fns";

@Component({
  selector: "app-appointment-status",
  templateUrl: "./appointment-status.component.html",
  styleUrls: ["./appointment-status.component.css"],
})
export class AppointmentStatusComponent implements OnInit {
  status: string;
  errorIn: string;
  appointmentWith: string;
  selectedDate: any;
  selectedSlot: any;
  selectedServices: string;
  confirmationMsg: any;
  providersName: string;
  location: any = {};
  showLogo: boolean = false;
  settings: any;
  providerDeatils: any = {};
  setting: any = {};
  services: any;
  loading: boolean = true;
  clinic_img_url: any;
  calendar_configurations: any;
  errorExist: boolean = false;


  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public appointmentService: AppointmentService,
    public _location: Location,
    public rescheduleApptService: RescheduleAppointmentService
  ) { }

  ngOnInit() {
    this.getProviderDetail();
    this.calendar_configurations = this.appointmentService.getSettings();
    this.route.queryParams.subscribe((queryParams) => {
      this.status = queryParams.status;
      if (this.status === "confirmed") {
        this.appointmentService.apptConfirmed = true
        this.appointmentWith = this.appointmentService.acc_name;
        this.providersName = this.appointmentService.providersName;
        this.location = this.appointmentService.getSettings().location
        this.settings = this.appointmentService.getSettings();
        if (this.settings.showLogo !== undefined) {
          this.showLogo = this.settings.showLogo;
        }
        let location = this.appointmentService.getSettings().location;
        let message =
          this.appointmentService.getSettings().message === undefined
            ? ""
            : this.appointmentService.getSettings().message;

        if (location.address === "") {
          this.confirmationMsg = `
                          <p>${message}</p>`;
        } else {
          this.confirmationMsg = `
                      <p><strong>You can reach us at</strong></p>
                      <p>${location.address}</p>

                      <p>Phone: <a href="tel:${location.phone}">${location.phone}</a></p>
                      <p><a href="${location.map_link}" target="_blank">Get Directions</a></p><br />
                      <p>${message}</p>`;
        }
        /*
                    this.confirmationMsg = `
                      <p>
                        <strong>Our office address: </strong><br />
                        1300 Rock Avenue, Ste. A4<br />
                        North Plainfield, NJ 07060<br />
                        [Across from Costco on Route 22 West]<br />
                        <strong>Contact No:</strong> (908) 756-6623
                      </p>
                    `;
                    */
        let date = this.appointmentService.getSelectedDate();
        if (date !== undefined) {
          this.selectedDate = date.format("dddd, MMMM D YYYY");
          this.selectedSlot = this.appointmentService.getSelectedSlot();
          this.selectedServices = this.appointmentService.getSelectedServices();
        } else {
          if (this.appointmentService.multipleLocations === true) {
            this.router.navigate(
              [
                "/calendar",
                this.appointmentService.acc_slug,
                this.appointmentService.calendar_slug,
              ],
              { queryParams: { multipleLocations: true } }
            );
          } else {
            this.router.navigateByUrl(
              `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
            );
          }
        }
      } else if (this.status === "unconfirmed") {
        this.settings = this.appointmentService.getSettings();
        if (this.settings && this.settings.showLogo !== undefined) {
          this.showLogo = this.settings.showLogo;
        }
        this.errorIn = queryParams.errorIn;
        this.errorExist = true
      }
    });
  }

  goBack() {
    if (this.appointmentService.multipleLocations === true) {
      this.router.navigate(
        [
          "/calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug,
        ],
        { queryParams: { multipleLocations: true } }
      );
    } else {
      this.router.navigateByUrl(
        `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }

  gobackToCalendarProject() {
    this.bookAnotherAppointmentClick()
  }

  get showRedirectUrlIfNotInIframe() {
    return this.showLogo && window.location == window.parent.location ? true : false;
  }

  getProviderDetail() {
    this.setting = this.appointmentService.getSettings();
    if (this.setting.providerReference) {
      this.setting.providerReference.get().then(resp => {
        console.log(resp.data())
        this.providerDeatils = resp.data();
      })
    }
    this.setting.locationReference.get().then(resp => {
      console.log(resp.data())
      this.location = resp.data();
      this.loading = false;
    })

    this.clinic_img_url = this.appointmentService.getClincImage();
    this.selectedDate = this.appointmentService.getSelectedDate();
    this.selectedSlot = this.appointmentService.getSelectedSlot();
    this.selectedServices = this.appointmentService.getSelectedServices();
    console.log(this.selectedDate, this.selectedSlot)

  }

  back() {
    this._location.back()
  }

  bookAnotherAppointmentClick() {
    this.appointmentService.bookAnotherAppointment()
  }


}
