import { Injectable } from "@angular/core";
import "firebase/firestore";
// import "firebase/storage";
import { ActivatedRoute, Router } from "@angular/router";

import firebase from "firebase/app";
import { Location } from "../_models/interfaces";

@Injectable({
  providedIn: "root",
})
export class LocationsService {
  constructor() { }
  async getLocations(account_id: string): Promise<Array<Location>> {
    /**
     *
     */
    let locations: Array<Location> = [];
    let locationDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_id)
      .collection("locations")
      .get();

    locationDocsSnapshot.forEach((results) => {
      let location = results.data();
      locations.push(<Location>{
        name: location.name,
        address: location.address,
        phone: location.phone,
        map_link: location.map_link,
        calendar_link: location.calendar_link,
        slug: location.slug,
      });
    });
    return locations;
  }

  async getLocationForProvider(
    account_id: string,
    providerRef: firebase.firestore.DocumentReference
  ) {
    let locations: Array<Location> = [];
    let calendarDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_id)
      .collection("calendars")
      .where("providerReference", "==", providerRef)
      .get();

    let unresolvedPromises = [];
    calendarDocsSnapshot.forEach((calendar) => {
      let locationRef = calendar.get("locationReference");
      //locations.push(locationRef);
      console.log(locationRef)
      unresolvedPromises.push(this.getLocation(locationRef));

      /*
      calendar.data().locationReference.get().then(locationSnap=>{
        let locDetails = locationSnap.data();
        locations.push(<Location> {
          "name": locDetails.name,
          "address": locDetails.address,
          "phone":locDetails.phone,
          "map_link":locDetails.map_link,
          "calendar_link":locDetails.calendar_link,
          "slug":locDetails.slug,
        });
      });
      */
    });

    locations = await Promise.all(unresolvedPromises);
    return locations;
  }

  async getLocationRef(acc_id, locationSlug) {
    const locationSnap = await firebase
      .firestore()
      .collection("accounts")
      .doc(acc_id)
      .collection("locations")
      .where("slug", "==", locationSlug)
      .get();
    if (locationSnap.empty) {
      return;
    } else {
      return locationSnap.docs[0];
    }
  }

  async getLocation(locationRef: any) {
    let locationSnapshot = await locationRef.get();

    let location = locationSnapshot.data();

    return <Location>{
      name: location.name,
      address: location.address,
      phone: location.phone,
      map_link: location.map_link,
      calendar_link: location.calendar_link,
      slug: location.slug,
    };
  }

  async getCalendarForLocation(calendarRef: firebase.firestore.DocumentReference) {
    let calendar = await calendarRef.get();

    return calendar.get("slug");
  }
}
