<mat-icon style="font-size:30px;" data-dismiss="modal" [mat-dialog-close]="false" class="material-icons close">close
</mat-icon>


<div class="text-center">
    <p *ngIf="dialogData.value!=='invalidOtp'" style="padding-bottom:5px;"> Verification code sent to {{full_phone}}
    </p>
    <div>
        <h4 style="text-align: center;">Verify phone number </h4>
        <input class="form-control" style="text-align: center;  position: relative; margin: 0 auto;" [disabled]="true"
            [(ngModel)]="full_phone" name="full_phone" pInputText>
    </div>

    <p style="padding-top:20px;">Please enter the verification code</p>
    <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config">
    </ng-otp-input>
    <p style="color:red;" *ngIf="verificationStatus == false"> Please enter valid verification code
    </p>

    <div style="padding-top: 5%;" class="text-center">
        <button style="background-color: var(--button-bg-color);" type="submit" class="btn btn-primary" id="button"
            [mat-dialog-close]="true" (click)="verifyOTP()"
            [disabled]="ngOtpInput.value == '' || !verificationStatus">Verify</button>
    </div>


    <p>Did not receive the verification code?<br>
        <b (click)="resendOtp()"><a style="color: var(--button-bg-color);">Resend verification code</a></b>
    </p>
    <p>Entered wrong phone? <br>
        <b [mat-dialog-close]="false"><a style="color: var(--button-bg-color);">Change Phone number</a></b>
    </p>
</div>