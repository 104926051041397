import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { AppointmentService } from "../_services/appointment.service";
import { PaypalService } from "../_services/paypal.service";
import * as moment from "moment-timezone";
declare const dataLayer: any;

@Component({
  selector: 'app-payments-processing',
  templateUrl: './payments-processing.component.html',
  styleUrls: ['./payments-processing.component.css']
})
export class PaymentsProcessingComponent implements OnInit {
  acc_slug: string;
  cal_slug: string;
  token: string;
  payerId: string;
  isReady: false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apptService: AppointmentService,
    private paypalService: PaypalService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
      this.payerId = params.PayerID;
      let source = this.apptService.getSource();
      if (source === undefined) {
        source = params.source === undefined ? "website" : params.source;
        this.apptService.setSource(source);
      }
      this.route.url.subscribe(url => {
        this.acc_slug = url[1].path;
        this.cal_slug = url[2].path;
        this.apptService.getAppointmentDetails(this.acc_slug, this.token, this.cal_slug).then(
          appt => {
            if (appt) {
              if (this.payerId !== undefined) {
                this.paypalService.checkPaymentStatus(this.token, this.acc_slug, this.payerId).then(
                  resp => {
                    let transactionDetails = resp.data.result;
                    let paymentDetails = {
                      orderId: transactionDetails.id,
                      transactionId: transactionDetails.purchase_units[0].payments.captures[0].id,
                      first_name: transactionDetails.payer.name.given_name,
                      last_name: transactionDetails.payer.name.surname,
                      emailId: transactionDetails.payer.email_address,
                      phone: transactionDetails.phone == undefined ? '' : transactionDetails.phone.phone_number.national_number,
                      status: transactionDetails.status,
                      method: 'paypal',
                      payed_on: moment(transactionDetails.purchase_units[0].payments.captures[0].create_time).toDate(),
                      payerId: this.payerId
                    };
                    let appointmentInfo = appt;
                    if (transactionDetails.status == "COMPLETED") {
                      this.apptService.updatePaymentDetailsInAppointment(appointmentInfo, paymentDetails).then(
                        apptSnap => {
                          console.log("updated appointment with payment details")
                          this.router.navigate(["/appointment-status"], {
                            queryParams: { status: "confirmed" },
                          });
                        },
                        err => {
                          console.log("error in update payment details", err);
                          this.router.navigate(["/appointment-status"], {
                            queryParams: { status: "confirmed" },
                          });
                        });
                    }
                    else {
                      appt.apptRef.delete();
                      this.router.navigate(["/appointment-status"], {
                        queryParams: { status: "unconfirmed", errorIn: "payment" },
                      });
                      console.log("appointment deleted");
                    }
                  },
                  err => {
                    console.log(err);
                  });
              }
              else {
                appt.apptRef.delete();
                this.router.navigate(["/appointment-status"], {
                  queryParams: { status: "unconfirmed", errorIn: "paymentCanceled" },
                });
                console.log("appointment deleted");
              }
            } else {
              this.router.navigate(["/appointment-status"], {
                queryParams: { status: "unconfirmed", errorIn: "timeout" },
              });
            }
          },
          err => {
            console.log(err);
          });

      })
    })
  }

}
