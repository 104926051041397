<div *ngIf="showSummryForMobile==false; " class="container-fluid">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <p class="navigation" *ngIf="appointmentService.multipleLocations">
      <a (click)="goBack()"><i class="material-icons icon-btn">keyboard_backspace</i> Pick another
        location</a>
    </p>

    <p class="navigation" *ngIf="appointmentService.providerLocations">
      <a (click)="goBack()"><i class="material-icons icon-btn">keyboard_backspace</i> Pick another
        location</a>
    </p>
    <p class="navigation"
      *ngIf="appointmentService.multipleProviders &&        appointmentService.providerLocations == false">
      <a (click)="goBack()">
        <i class="material-icons icon-btn">keyboard_backspace</i> Pick another provider</a>
    </p>
    <div *ngIf="rescheduleAppt;else noReschedule">
      <app-desktop-calendar [rescheduleAppt]="true" (rescheduleDateSlected)="setDate($event)" class="rolling">
      </app-desktop-calendar>
      <app-timeslot class="strip" [rescheduleAppt]="true" (rescheduleDateSlected)="setDate($event)"></app-timeslot>
    </div>
    <ng-template #noReschedule>
      <app-desktop-calendar class="rolling" [isDebuging]="isDebuging">
      </app-desktop-calendar>
      <app-timeslot class="strip"></app-timeslot>
    </ng-template>
  </ng-template>
</div>

<div *ngIf="showSummryForMobile==true; " class="mobile-view-summary">
  <app-appointment-details [selectedServices]="services_chosen"></app-appointment-details>
</div>
<div class="footer">
  <app-footer [back]="true" [summary]="true" [next]="false" (summaryShow)="summaryShow($event)"
    (goBack)="goBackTopreviousUrl()"></app-footer>
</div>