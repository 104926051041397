import { ActivatedRoute, Router } from "@angular/router";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-reschedule-provider-locationgroup",
  templateUrl: "./reschedule-provider-locationgroup.component.html",
  styleUrls: ["./reschedule-provider-locationgroup.component.css"]
})
export class RescheduleProviderLocationgroupComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: RescheduleAppointmentService
  ) {}

  acc_slug: string;
  providerId: string;
  providerLocData: any = [];
  calendars: any = [];
  groupType: string;
  isReady: Boolean = false;
  providersName: string;

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      console.log(queryParams.source);
      console.log(this.appointmentService.providersName);

      let source = this.appointmentService.getSource();
      // let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }

      this.appointmentService.multipleProviders =
        queryParams.multipleProviders !== undefined ? true : false;

      // if(category === undefined) {
      //   category = queryParams.category === undefined ? '' : queryParams.category;
      //   this.appointmentService.setCategory(category);
      // }
    });

    this.route.url.subscribe(url => {
      this.acc_slug = url[1].path;
      this.groupType = url[0].path;
      this.providerId = url[2].path;

      this.appointmentService.providerId = this.providerId;
      console.log(this.acc_slug, this.providerId);

      this.providerLocData = this.appointmentService.getProviderCalendarData(
        this.providerId
      );

      console.log(this.providerLocData);
      this.calendars = this.providerLocData[0].calendarData;
      console.log(this.calendars);
      this.isReady = true;
      this.providersName = this.appointmentService.providersName;
      console.log(this.providersName);
    });
  }

  setCalendarRef(ref) {
    this.appointmentService.calendarRef = ref;
  }

  goBack() {
    this.router.navigateByUrl(
      `/reschedule-provider-groups/${this.acc_slug}/${this.appointmentService.provider_slug}`
    );
  }
}
