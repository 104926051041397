<div class="container-fluid">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div class="center">
      <p class="navigation">
        <a (click)="goBack()"
          ><i class="material-icons icon-btn">keyboard_backspace</i> Pick
          another date</a
        >
      </p>
      <p class="highlight" *ngIf="appointmentService.providersName !== undefined">
        {{ providersName }} at {{ location }}
      </p>

      <p class="highlight" *ngIf="appointmentService.multipleLocations === true && appointmentService.providersName === undefined">
         {{ location }}
      </p>

      <p class="highlight">{{ selectedDate }}</p>
      <!--<div class="old-slot" [class.disabled]="true"><p>{{oldTimeslot}}</p></div>-->
      <div *ngIf="timeslots.slots.length > 0">
        <p class="highlight">What time works for you?</p>
        <div *ngIf="showSlot" class="old-slot">
          <p>{{ oldTimeslot }}</p>
        </div>
        <div
          class="appointment_slot"
          [class.selected]="selectedSlot === timeslot"
          *ngFor="let timeslot of timeslots.slots"
          (click)="setTimeslot(timeslot)"
        >
          <p>
            {{ appointmentService.convertToLocalTime(timeslot.start) }} -
            {{ appointmentService.convertToLocalTime(timeslot.end) }}
          </p>
        </div>
        <small
          ><em
            >*The above timings are in
            {{ appointmentService.getLocalTimezoneAbbr() }}</em
          ></small
        >
      </div>
      <div *ngIf="timeslots.slots.length == 0">
        <div *ngIf="showSlot" class="old-slot">
          <p>{{ oldTimeslot }}</p>
        </div>
        <!-- <i class="material-icons status-symbol">cancel</i> -->
        <p class="highlight">
          Sorry, all slots for this date have been booked.
        </p>
      </div>
    </div>
  </ng-template>
</div>
