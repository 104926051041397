import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment-timezone";
import { CalendarComponent } from '../calendar/calendar.component';
import { AppointmentService } from '../_services/appointment.service';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.css']
})
export class TrialComponent implements OnInit {
  @Output() onDateSelect = new EventEmitter<Date>();
  selectedDate = -1;
  monthTitle: string = '';
  rollingWeeksArray: any = [];
  today = 0;
  maxDate: number;
  minDate: number;
  starting = 0;
  ending = 5;
  dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  blocked_dates: any = [];
  filterDays: any = [];
  setting: any = {};
  constructor(
    private appointmentService: AppointmentService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit() {
    var curr = new Date; // get current date
    var first = curr.getDate();// today date
    var day = new Date(curr.setDate(first)).toUTCString();
    this.rollingWeeksArray.push(day);
    for (let i = 1; i <= 5; i++) {
      var lastDay = new Date(this.rollingWeeksArray[this.rollingWeeksArray.length - 1]);
      var increseDay = lastDay.getDate();
      var newDay = new Date(lastDay.setDate(increseDay + 1)).toUTCString();
      this.rollingWeeksArray.push(newDay);
    }

    // if (this.appointmentService.getSelectedDate()) {
    //   this.selectedDate = this.appointmentService.getSelectedDate();
    // }
    // else {
    //   for (let i = 0; i < this.rollingWeeksArray.length; i++) {
    //     if (!this.isBlock(i)) {
    //       this.onDateChange(i);
    //       break
    //     }
    //   }
    // }

    this.setMonthTitle();
    // this.setStripCalenderSelectedDate();
  }

  weekIncrement() {
    if (!(this.ending == this.rollingWeeksArray.length)) {
      this.starting = this.starting + 5;
      this.ending = this.ending + 5;
      this.setMonthTitle();
    }

  }

  weekDeccrement() {
    if (this.starting != 0) {
      this.starting = this.starting - 5;
      this.ending = this.ending - 5;
    }
    this.setMonthTitle();
  }

  setMonthTitle() {
    var firstDayofWeek = new Date(this.rollingWeeksArray[this.starting]);
    var lastDayofWeek = new Date(this.rollingWeeksArray[this.ending - 1]);

    if (firstDayofWeek.toLocaleString('default', { month: 'long' }) == lastDayofWeek.toLocaleString('default', { month: 'long' }))
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    else
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    if (lastDayofWeek.getUTCFullYear() != firstDayofWeek.getUTCFullYear())
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + firstDayofWeek.getUTCFullYear() + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()
  }

  isShow(index) {
    if (index >= this.starting && index < this.ending)
      return true

    else
      return false
  }

  onDateChange(index) {
    this.selectedDate = index
    let selectedDateOnStrip = moment(this.rollingWeeksArray[index]);
    this.appointmentService.setSelectedDate(selectedDateOnStrip);
    // this.onDateSelect.emit(selectedDateOnStrip);
  }

  isBlock(index) {

    if (index > 7 * this.maxDate)
      return true

    if (index < this.minDate)
      return true

    for (let i = 0; i < this.filterDays.length; i++) {
      if (this.rollingWeeksArray[index].substring(0, 3) == this.dayOfWeek[this.filterDays[i]])
        return true
    }

    for (let i = 0; i < this.blocked_dates.length; i++) {
      var date = new Date(this.rollingWeeksArray[index]);
      if (this.datepipe.transform(date, 'yyyy-MM-dd') == this.blocked_dates[i])
        return true
    }

    return false
  }

  setStripCalenderSelectedDate() {
    this.selectedDate = this.appointmentService.getSelectedDate();
    for (let i = 0; i <= (7 * (this.maxDate + 1)); i++) {
      if (this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd') == this.datepipe.transform(this.rollingWeeksArray[i], 'yyyy-MM-dd')) {
        this.selectedDate = i;
        for (let j = 1; j < this.maxDate + 1; j++) {
          if (this.selectedDate >= (7 * j) && this.selectedDate < (7 * (j + 1))) {
            this.starting = 7 * j;
            this.ending = 7 * (j + 1);
          }
        }
        break;
      }
    }
  }
}
