import { Component, Input, OnInit } from '@angular/core';
import { AppointmentService } from '../_services/appointment.service';
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.css']
})
export class AppointmentDetailsComponent implements OnInit {
  @Input() selectedServices: any
  providerDeatils: any = {};
  setting: any = {};
  location: any = {};
  selectedSlot: any;
  selectedDate: any;
  loading: boolean = true;
  clinic_img_url: string;
  oldDate: any;
  oldTimeSlot: string;


  constructor(
    public appointmentService: AppointmentService,
    public rescheduleAppointmentService: RescheduleAppointmentService,
  ) { }

  ngOnInit() {
    this.clinic_img_url = this.appointmentService.getClincImage();
    console.log(this.clinic_img_url)
    this.setting = this.appointmentService.getSettings();
    if (this.setting.providerReference) {
      this.setting.providerReference.get().then(resp => {
        console.log(resp.data())
        this.providerDeatils = resp.data();

      })
    }

    this.setting.locationReference.get().then(resp => {
      console.log(resp.data())
      this.location = resp.data();
      this.loading = false;
    })

    this.selectedDate = this.appointmentService.getSelectedDate();
    this.selectedSlot = this.appointmentService.getSelectedSlot();
    console.log(this.selectedDate, this.selectedSlot)

    this.oldDate = this.rescheduleAppointmentService.getOldAppointmentDate();
    this.oldTimeSlot = this.rescheduleAppointmentService.getOldAppointmentTime();
    console.log(this.oldDate)
    if (this.selectedServices == undefined)
      this.selectedServices = this.appointmentService.formData?.services
  }


}
