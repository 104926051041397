<div class="container-fluid">
  <div *ngIf="!isCancel; then not_cancel else cancel">
  </div>
  <ng-template #not_cancel>
    <div *ngIf="isReady; then ready else not_ready">
    </div>
    <ng-template #not_ready>
      <div class="spinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </ng-template>
    <ng-template #ready>
      <div *ngIf="this.appt_status === 'booked' && this.oldDate >= this.currentDate">
        <div class="msg">
          <div class="box border">
            <p class="highlight">Are you sure you want to cancel this appointment?</p>
            <i class="material-icons">query_builder</i>
            <p>{{oldDate | date: "MMM d, y"}}</p>
            <p>{{timeslot}}</p>
            <button mat-raised-button class="btn" (click)="cancelAppointment()" [disabled]="canceling">Cancel</button>
          </div>
        </div>
      </div>
      <div *ngIf="this.oldDate <= this.currentDate">
        <div class="msg">
          <div class="box border">
            <i class="material-icons">warning</i>
            <p class="notify">You are trying to cancel a past event!</p>
          </div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="this.appt_status === 'cancelled'">
      <div class="msg">
        <div class="border">
          <i class="material-icons">notification_important</i>
          <p class="notify">This appointment has already been cancelled!</p>
        </div>
      </div>
    </div>
    <div *ngIf="this.appt_status === 'rescheduled'">
      <div class="msg">
        <div class="border">
          <i class="material-icons">notification_important</i>
          <p class="notify">This appointment has been rescheduled!</p>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #cancel>
    <div class="msg">
      <div class="border">
        <i class="material-icons">notification_important</i>
        <p class="notify">This appointment has been cancelled!</p>
        <p class="strike">{{oldDate | date: "MMM d, y"}}</p>
        <p class="strike">{{timeslot}}</p>
      </div>
    </div>
  </ng-template>

  <!--<div *ngIf="this.appt_status === 'booked' && this.oldDate <= this.currentDate">
    <div class="msg">
    <div class="border">
      <i class="material-icons">notification_important</i>
    <p class="highlight">You are trying to cancel a past event!</p>
    </div>
    </div>
  </div>-->


  <!---  <div *ngIf="isCancel">
    <div class="msg">
    <div class="border">
    <p class="highlight">Your appointment is cancelled!</p>
    <p class="strike">{{oldDate | date: "MMM d, y"}}</p>
    <p class="strike">{{timeslot}}</p>
    </div>
    </div>
  </div>-->



</div>