<div class="spinner" *ngIf="loading;else summaryDetails">
  <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>

</div>
<ng-template #summaryDetails>

  <div id="deskView" style="text-align: center;">
    <div>
      <h4>Appointment With</h4>
    </div>
    <div *ngIf="providerDeatils.name">
      <div *ngIf="providerDeatils.img_url">
        <img id="doctorImg" [src]="providerDeatils.img_url" alt="imageAlt">
        <div style="height: 2em;">
          <h4>{{providerDeatils.name}}</h4>
        </div>
      </div>

    </div>
    <ng-container *ngIf="setting.clinic_img_url;else accountImage">
      <div style="height: 6em;">
        <img style="height: 65px;max-width:100%" [src]="setting.clinic_img_url">
      </div>
    </ng-container>
    <ng-template #accountImage>
      <div style="height: 6em;" *ngIf="clinic_img_url">
        <img style="height: 65px;max-width:100%" [src]="clinic_img_url">
      </div>
    </ng-template>

    <div class="column">
      <div class="row ">
        <div class="col-md-1">
          <span style="font-size: xx-large;margin-left: -5px;" class="material-icons">
            location_on
          </span>
        </div>
        <div style="text-align: left;" class="col-md-10">
          <span>{{location.address}}</span>
        </div>

      </div>
      <div class="row">
        <div class="col-md-1">
          <span class="material-icons">
            watch_later
          </span>
        </div>
        <div class="col-md-10" style="text-align: left;" *ngIf="selectedDate && selectedSlot;else selectDate">
          <div *ngIf="oldDate != undefined;else ShowSelectedDate">
            <span>
              Rescheduling appointment form <br> <b style="text-decoration: line-through;">{{oldDate | date}}
                {{oldTimeSlot}}</b>
            </span>
            to <br>
            <span> <b> {{selectedDate |date}}, {{"
                "}}{{appointmentService.convertToLocalTime(selectedSlot.start)}} -
                {{appointmentService.convertToLocalTime(selectedSlot.end)}}
              </b></span>

          </div>
          <ng-template #ShowSelectedDate>
            <span> {{selectedDate |date}}, {{"
              "}}{{appointmentService.convertToLocalTime(selectedSlot.start)}} -
              {{appointmentService.convertToLocalTime(selectedSlot.end)}}</span>
          </ng-template>
        </div>
        <ng-template #selectDate>
          <div style="text-align: left;" class="col-md-10">
            <div *ngIf="oldDate != undefined;else pleasSelectDate">
              <b>Please select Date and Time to Reschedule <br> {{oldDate | date}} {{oldTimeSlot}}</b>
            </div>
            <ng-template #pleasSelectDate><b>Please Select Date and Time</b></ng-template>
          </div>
        </ng-template>
      </div>
      <div class="row" *ngIf="selectedDate && selectedSlot && setting.services.enabled==true">
        <div class="col-md-1">
          <span class="material-icons">
            add_circle
          </span>
        </div>
        <div class="col-md-10" style="text-align: left;" *ngIf="selectedServices;else selectServices">
          <span> {{selectedServices}}</span>
        </div>
        <ng-template #selectServices>
          <div style="text-align: left;" class="col-md-10">
            <span><b>Please Select a Service</b></span>
          </div>
        </ng-template>
      </div>
    </div>

  </div>

  <div id="mobileView" style="text-align: center;">
    <div *ngIf="providerDeatils.name">
      <div style="padding: 10px;">
        <h4>Appointment With</h4>
      </div>
      <div *ngIf="providerDeatils.img_url">
        <img id="doctorImg" [src]="providerDeatils.img_url" alt="imageAlt">
        <div style="height: 2em;">
          <h4>{{providerDeatils.name}}</h4>
        </div>
      </div>

    </div>
  
    <ng-container *ngIf="setting.clinic_img_url;else MobileAccountImage">
      <div style="height: 6em;">
        <img style="height: 55px;max-width:100%" [src]="setting.clinic_img_url">
      </div>
    </ng-container>
    <ng-template #MobileAccountImage>
      <div style="height: 6em;" *ngIf="clinic_img_url">
        <img style="height: 55px;max-width:100%" [src]="clinic_img_url">
      </div>
    </ng-template>
    <div class="row ">
      <div class=" col-xs-3" style="text-align: end;">
        <span style="font-size: xx-large;margin-left: 40px;" class="material-icons">
          location_on
        </span>
      </div>
      <div class=" col-xs-7" style="font-size: large;text-align: start;">
        <span>{{location.address}}</span>
      </div>
      <div class="col-xs-2"></div>
    </div>
    <div class="row ">
      <div class=" col-xs-3" style="text-align: end;">
        <span class="material-icons">
          watch_later
        </span>
      </div>
      <div class=" col-xs-7" style="font-size: large;text-align: start;"
        *ngIf="selectedDate && selectedSlot;else selectDate">
        <div *ngIf="oldDate != undefined;else ShowSelectedDate">
          <span>
            Rescheduling appointment form <br> <b style="text-decoration: line-through;">{{oldDate | date}}
              {{oldTimeSlot}}</b>
          </span>
          to <br>
          <span> <b> {{selectedDate |date}}, {{"
              "}}{{appointmentService.convertToLocalTime(selectedSlot.start)}} -
              {{appointmentService.convertToLocalTime(selectedSlot.end)}}
            </b></span>

        </div>
        <ng-template #ShowSelectedDate>
          <span> {{selectedDate |date}}, {{"
            "}}{{appointmentService.convertToLocalTime(selectedSlot.start)}} -
            {{appointmentService.convertToLocalTime(selectedSlot.end)}}</span>
        </ng-template>
      </div>

      <ng-template #selectDate>
        <div style="font-size: large;text-align: start;" class=" col-xs-7">
          <div *ngIf="oldDate != undefined;else pleasSelectDate">
            <b>Please select Date and Time to Reschedule <br> {{oldDate | date}} {{oldTimeSlot}}</b>
          </div>
          <ng-template #pleasSelectDate><b>Please Select Date and Time</b>
          </ng-template>
        </div>
      </ng-template>
      <div class="col-xs-2"></div>
    </div>
    <div class="row " *ngIf="selectedDate && selectedSlot && setting.services.enabled==true">
      <div class=" col-xs-3" style="text-align: end;">
        <span class="material-icons">
          add_circle
        </span>
      </div>
      <div class=" col-xs-7" style="font-size: large;text-align: start;" *ngIf="selectedServices;else selectServices">
        <span> {{selectedServices}}</span>
      </div>
      <ng-template #selectServices>
        <div class=" col-xs-7" style="font-size: large;text-align: start;">
          <span><b>Please Select a Service</b></span>
        </div>
      </ng-template>
      <div class="col-xs-2"></div>
    </div>

  </div>
</ng-template>