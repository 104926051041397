export const COUNTRY_CODES = [
  {
    "timezones": [
      "Asia/Kolkata",
      "Asia/Calcutta"
    ],
    "name": "India",
    "code": "+91",
    "country_code":'IN'
  },
  {
    "timezones": [
      "Europe/London"
    ],
    "name": "United Kingdom",
    "code": "+44",
    "country_code":'UK'
  },
  {
    "timezones": [
      "America/Los_Angeles",
      "America/Denver",
      "America/Chicago",
      "America/New_York",
    ],
    "name": "United States",
    "code": "+1",
    "country_code":'US'
  }
];
