<div class="container-fluid top-space">
  <div *ngIf="isReady; then ready else not_ready">
  </div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>

    <div id="deskView" *ngIf="calendars.length!==0" style="text-align: center;">
      <div class="row" id="subHeader">
        <div class="col-md-4" style="    text-align: left;">
          <div *ngIf="appointmentService.routerPaths.length>1">
            <!-- <div *ngIf="appointmentService.routerPaths[appointmentService.routerPaths.length-2].isAutoRoute!=true"> -->
            <div>
              <span style="cursor: pointer;margin-top: -10px;" class="material-icons arrow" (click)="goBack()">
                keyboard_arrow_left
              </span>
              <a (click)="goBack()"> Back</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div style="text-align: center;">
            <b>Please select Service</b>
          </div>
        </div>
        <div class="col-md-4">
        </div>
      </div>
    </div>
    <div id="mobileView" *ngIf="calendars.length!==0" style="text-align: center;">
      <div class="row" id="subHeader">
        <div style="text-align: center;">
          <b>Please select Service</b>
        </div>
      </div>
    </div>

    <div *ngIf="calendars.length==0">No calendar for this account</div>

    <div id="card-container" *ngIf="calendars.length > 1">
      <div *ngFor="let calendar of calendars" class="card-block plan">
        <mat-card>
          <a [routerLink]="['/calendar', acc_slug, calendar.slug]">
            <h4 *ngIf="calendar.name!==undefined || calendar.name!=''">
              {{ calendar.name|titlecase }}
            </h4>
            <h4 *ngIf="calendar.name===undefined || calendar.name===''">
              {{ calendar.slug |titlecase }}
            </h4>
          </a>
        </mat-card>
      </div>
    </div>
  </ng-template>
  <div class="footer">
    <app-footer [back]="false" [summary]="false" [next]="false">
    </app-footer>
  </div>
</div>