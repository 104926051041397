<!-- <mat-toolbar color="primary">
  <mat-toolbar-row>
    <div class="tootlbarRow1">
      <h1>
        {{monthTitle}}
      </h1>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row style="height:  5em;">
    <div id="arrowButton">
      <button (click)="weekDeccrement()" mat-icon-button class=" example-icon"
        aria-label="Example icon-button with menu icon">
        <span class="material-icons">
          keyboard_arrow_left
        </span>
      </button>
    </div> -->
<!-- <div class="mobile-view">
        <div class="container-fluid" style="width:100%;cursor: pointer;">
          <ng-container *ngFor="let day of oneWeekArry;let i = index;"
            style="width:fit-content;text-align: center;cursor: pointer;">
            <div (click)="selectedDate = i" *ngIf="isShow(i)" style="padding-left: -10%;width: 30%;"
              [ngStyle]="day.substring(0,3)=='Sun' ?{'pointer-events': 'none','opacity': '0.7;','color':'gray'}:{'color':'#f7f7f7'}">
              <div class="displayDate" *ngIf="selectedDate!=i" style="margin-top: 5px">
                {{day.substring(0,3) | uppercase }}
              </div>
              <div class="selectDate" style="display: inline" *ngIf="selectedDate==i">
                <button class="button column">
                  <div class="column" style="margin-top: -10px">
                    <div class="displayDate" style="margin-top: 5px">{{day.substring(0,3) | uppercase }}</div>
                    <div style="margin-top: -10px">{{day| date:'dd'}}</div>
                  </div>
                </button>
              </div>
              <div style="margin-top: -10px" *ngIf="selectedDate!=i">
                {{day| date:'dd'}}
              </div>
            </div>
          </ng-container>
        </div>
      </div> -->
<!--Desktop view-->
<!-- <div class="flex-container">
      <div *ngFor="let day of oneWeekArry;let i = index;" (click)="onDateChange(i)"
        [ngStyle]="isBlock(i)==true ?{'pointer-events': 'none','opacity': '0.7;','color':'gray'}:{'color':'#f7f7f7'}">

        <div id="dayDisplay">
          {{day.substring(0,3) | uppercase }}
        </div>
        <div>
          {{day| date:'dd'}}
        </div> -->
<!-- <ng-container *ngIf="isShow(i)" class="column" style="text-align: center;">
            <div *ngIf="selectedDate!=i" id="dayDisplay">
              {{day.substring(0,3) | uppercase }}
            </div>
            <div class="selectDate" *ngIf="selectedDate==i">
              <button class="button column">
                <div class="column">
                  <div id="dayDisplay">{{day.substring(0,3) | uppercase }}</div>
                  <div>{{day| date:'dd'}}</div>
                </div>
              </button>
            </div>
            <div *ngIf="selectedDate!=i">
              {{day| date:'dd'}}
            </div>
          </ng-container> -->
<!-- </div>
    </div> -->

<!--mobile View-->
<!-- <div class="container-fluid" (scroll)="scrollHandler($event)" id="mydiv">
        <div class="row seven-cols">
          <div *ngFor="let day of oneWeekArry;let i = index;" (click)="selectedDate = i"
            style="text-align: center;cursor: pointer;" class="col-md-1"
            [ngStyle]="day.substring(0,3)=='Sun' ?{'pointer-events': 'none','opacity': '0.7;','color':'gray'}:{'color':'#f7f7f7'}"
            [ngStyle]="selectedDate==i?{'color':'black'}:{'color':'#f7f7f7'}">
            <div>
              <span *ngIf="selectedDate!=i" style="font-size: 12px;">
                {{day.substring(0,3) | uppercase }}
              </span>
              <br>
              <div class="selectDate" *ngIf="selectedDate==i">
                <button class="button column">
                  <div class="column">
                    <div style="font-size: 12px;">{{day.substring(0,3) | uppercase }}</div>
                    <div>{{day| date:'dd'}}</div>
                  </div>
                </button>
              </div>
              <span *ngIf="selectedDate!=i">
                {{day| date:'dd'}}
              </span>
            </div>
          </div>
        </div>
      </div> -->
<!-- <div id="arrowButton">
      <button mat-icon-button class="example-icon" (click)="weekIncrement()"
        aria-label="Example icon-button with menu icon">
        <span class="material-icons">
          keyboard_arrow_right
        </span>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar> -->
<!-- [ngStyle]=" i==0 ?{'pointer-events': 'none' ,'opacity': '0.7;' ,'color':'red'}:{'color':'#f7f7f7'}"-->
<div class="toolbar">
  <div class="tootlbarRow1">
    <h4>
      {{monthTitle}}
    </h4>
  </div>
  <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
      <div class="flex-container">
        <div class="arrow-padding">
          <button (click)="weekDeccrement()" [disableRipple]="true" mat-icon-button
            aria-label="Example icon-button with menu icon">
            <span class="material-icons arrow" [style.color]="starting==0 ? '#cbc5bc' : 'black'">
              keyboard_arrow_left
            </span>
          </button>
        </div>
        <ng-container *ngFor="let day of rollingWeeksArray;let i = index;">
          <div *ngIf="isShow(i)" (click)="onDateChange(i)" style="cursor: pointer"
            [ngStyle]="isBlock(i)==true ?{'pointer-events': 'none','opacity': '0.7;','color':'#d5cbcb'}:{'color':'black'}">
            <div id="dayDisplay">
              {{day.substring(0,3) | uppercase }}
            </div>
            <div [ngClass]="{'highlight-date': selectedDate === i}">
              {{day| date:'dd'}}
            </div>
          </div>
        </ng-container>
        <div class="arrow-padding">
          <button mat-icon-button (click)="weekIncrement()" [disableRipple]="true"
            aria-label="Example icon-button with menu icon">
            <span class="material-icons arrow" [style.color]="ending==rollingWeeksArray.length ? '#cbc5bc' : 'black'">
              keyboard_arrow_right
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>