import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { COUNTRY_CODES } from "../../_constants/country_phone_codes";
import * as moment from "moment-timezone";
import { AppointmentService } from '../../_services/appointment.service';

@Component({
  selector: 'app-recurring-conact-details',
  templateUrl: './recurring-conact-details.component.html',
  styleUrls: ['./recurring-conact-details.component.css']
})
export class RecurringConactDetailsComponent implements OnInit {

  @Input() contactInfo: any;
  @Input() recurringSchedule: any;
  @Output() goBack = new EventEmitter();
  @Output() confirmAppointment = new EventEmitter();
  country_code

  countryCodes: any = COUNTRY_CODES;
  countryFound = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  )
  countryCode: any = this.countryFound ? this.countryFound.code : '+1';


  constructor(public appointmentService: AppointmentService,) { }

  ngOnInit() {
    console.log(this.recurringSchedule)
  }

  confirmApoinments() {
    this.confirmAppointment.emit()
  }

  back() {
    this.goBack.emit();
  }
}
