import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from "firebase/app";
import { environment } from "../../environments/environment";
import { AppointmentService } from './appointment.service';
import "firebase/functions";
import "firebase/firestore";
// import "firebase/database";
import { COUNTRY_CODES } from "../_constants/country_phone_codes";
import * as moment from "moment-timezone";

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  countryCodes = COUNTRY_CODES;
  accSlug = this.appointmentService.acc_slug;
  country_code: any = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  ).country_code;
  userDetails = this.appointmentService.getFormData();
  constructor(
    private http: HttpClient,
    private appointmentService: AppointmentService,
  ) { }

  createPaymentRequest(settings, formFields, slot, acc_slug) {
    this.userDetails = formFields;
    let returnAndCancelUrl = this.appointmentService.gclid ? `${environment.clientURL}/payments/${acc_slug}/${settings.slug}?gclid=${this.appointmentService.gclid}` : `${environment.clientURL}/payments/${acc_slug}/${settings.slug}`
    let paymentInformation = {
      "intent": "CAPTURE",
      "application_context": {
        "return_url": returnAndCancelUrl,
        "cancel_url": returnAndCancelUrl,
        "brand_name": this.appointmentService.acc_name,
        "locale": "en-US",
        "shipping_preference": "NO_SHIPPING",
        "landing_page": "BILLING",
        "solution_type": "SOLE",
        "user_action": "PAY_NOW"
      },
      "payer": {
        "name": {
          "given_name": this.userDetails.first_name,
          "surname": this.userDetails.last_name
        },
        "email_address": this.userDetails.email,
        "phone": {
          "phone_type": "MOBILE",
          "phone_number": {
            "national_number": this.userDetails.phone_no
          }
        },
        "address": {
          "address_line_1": this.userDetails.street,
          "address_line_2": "",
          "admin_area_1": this.userDetails.state,
          "admin_area_2": this.userDetails.city,
          "postal_code": this.userDetails.postalCode,
          "country_code": this.country_code
        }
      },
      "purchase_units": [
        {
          "description": settings.location.name,
          "amount": {
            "currency_code": settings.paymentDetails.currency_code,
            "value": settings.paymentDetails.amount,
            "breakdown": {
              "item_total": {
                "currency_code": settings.paymentDetails.currency_code,
                "value": settings.paymentDetails.amount,
              }
            }
          },
          "items": [
            {
              "name": "Appoitment Deposit",
              "description": `${slot.start} - ${slot.end}`,
              "quantity": "1",
              "unit_amount": {
                "currency_code": settings.paymentDetails.currency_code,
                "value": settings.paymentDetails.amount
              }
            }
          ]
        }
      ]
    };
    //  let  a = {
    //     "intent": "CAPTURE",
    //     "application_context": {
    //         "return_url": `${environment.clientURL}/payments/the-hairloss-clinic`,
    //         "cancel_url": `${environment.clientURL}/payments/the-hairloss-clinic`,
    //         "brand_name": "EXAMPLE INC",
    //         "locale": "en-US",
    //         "landing_page": "BILLING",
    //         "user_action": "CONTINUE"
    //     },
    //     "purchase_units": [
    //         {
    //             "description": "Sporting Goods",
    //             "soft_descriptor": "HighFashions",
    //             "amount": {
    //                 "currency_code":settings.paymentDetails.currency_code ,
    //                 "value": settings.paymentDetails.amount
    //             }
    //         }
    //     ]
    // };
    console.log(paymentInformation);
    let data = {
      acc_slug: acc_slug,
      payment: paymentInformation
    };
    return firebase
      .functions()
      .httpsCallable("createPaypalPayment")(data)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }
  checkPaymentStatus(transaction, accSlug, payerId) {
    let data = {
      acc_slug: accSlug,
      token: transaction,
    };
    let header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(data);
    // return this.http.post("http://localhost:4200/executePaypalPaymentRequest",JSON.stringify(data),header);
    // .subscribe(
    //   resp=>{
    //     console.log(resp);
    //   });
    return firebase
      .functions()
      .httpsCallable("executePaypalPaymentV2")(data)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }
}
