import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-provider-locations-group",
  templateUrl: "./provider-locations-group.component.html",
  styleUrls: ["./provider-locations-group.component.css"]
})
export class ProviderLocationsGroupComponent implements OnInit {
  acc_slug: string;
  providerId: string;
  providerLocData: any = [];
  calendars: any = [];
  groupType: string;
  isReady: Boolean = false;
  providersName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      console.log(queryParams.source);
      let source = this.appointmentService.getSource();
      let category = this.appointmentService.getCategory();

      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }
      if (category === undefined) {
        category =
          queryParams.category === undefined ? "" : queryParams.category;
        this.appointmentService.setCategory(category);
      }
      this.appointmentService.multipleProviders =
        queryParams.multipleProviders !== undefined ? true : false;
      // this.appointmentService.multipleLocations = queryParams.multipleLocations !== undefined ? true : false;
    });

    this.route.url.subscribe(url => {
      this.groupType = url[0].path;
      this.acc_slug = url[1].path;
      this.providerId = url[2].path;

      this.appointmentService.providerId = this.providerId;
      console.log(this.acc_slug, this.providerId);
      this.providerLocData = this.appointmentService.getProviderCalendarData(
        this.providerId
      );

      console.log(this.providerLocData);
      this.appointmentService.setProviderDetails(this.providerId);
      this.calendars = this.providerLocData[0].calendarData;
      console.log(this.calendars);
      this.isReady = true;
      this.providersName = this.appointmentService.providersName;
      console.log(this.providersName);
    });
  }
  // setgroupSlug()
  // {
  // 	this.appointmentService.setGroupSlug(this.groupType,this.providerId);
  // }
  setCalendarRef(ref) {
    this.appointmentService.calendarRef = ref;
  }

  goBack() {
    this.router.navigateByUrl(
      `/providers-groups/${this.acc_slug}/${this.appointmentService.provider_slug}`
    );
  }
}
