import { DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-reccuring-reschedule-difference',
  templateUrl: './reccuring-reschedule-difference.component.html',
  styleUrls: ['./reccuring-reschedule-difference.component.css']
})
export class ReccuringRescheduleDifferenceComponent implements OnInit {
  @Input() recurringSchedule: any;
  @Input() contactInfo: any;
  @Input() rescheduleRecurringArrary: any;
  @Output() confirmAppointment = new EventEmitter();
  @Output() goBack = new EventEmitter();
  displayReccuringArray: any = []
  constructor(
    public appointmentService: AppointmentService,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {

    console.log(this.contactInfo, this.recurringSchedule, this.rescheduleRecurringArrary)

    for (let i = 0; i < this.recurringSchedule.length; i++) {
      this.displayReccuringArray.push(
        {
          date: this.datepipe.transform(this.recurringSchedule[i].date, 'MMMM d, y').toString(),
          slot: `${this.recurringSchedule[i].selectedSlot.start} - ${this.recurringSchedule[i].selectedSlot.end}`
        }
      )
    }

  }
  confirm() {
    this.confirmAppointment.emit()
  }
  back() {
    this.goBack.emit();
  }

}
