import { Injectable } from "@angular/core";
import "firebase/firestore";
// import "firebase/storage";
import { ActivatedRoute, Router } from "@angular/router";

import firebase from "firebase/app";
import { Provider } from "../_models/interfaces";
// import { DocumentReference } from "angularfire2/firestore";
@Injectable({
  providedIn: "root",
})
export class ProvidersService {
  groupType: string;
  acc_id: string;
  constructor(private router: Router, private route: ActivatedRoute) { }
  async getProviders(account_id: string): Promise<Array<Provider>> {
    /**
     * For the given account, query the providerDetails (providers) collection.
     * Return all documents formatted to array of Provider objects.
     *
     */
    let providers: Array<Provider> = [];
    let providerDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_id)
      .collection("providersDetails")
      .get();

    providerDocsSnapshot.forEach((results) => {
      let provider = results.data();
      providers.push(<Provider>{
        fullname: provider.name,
        email: provider.email,
        degree: provider.speciality,
        phone: provider.phone,
        slug: provider.slug,
        img_url: provider.img_url
      });
    });
    return providers;
  }

  async getProvidersForLocation(
    account_id: string,
    locationRef: firebase.firestore.DocumentReference
  ): Promise<Array<Provider>> {
    let providers: Array<Provider> = [];
    let calendarDocsSnapshot = await firebase
      .firestore()
      .collection("accounts")
      .doc(account_id)
      .collection("calendars")
      .where("locationReference", "==", locationRef)
      .get();

    let unresolvedPromises = [];
    calendarDocsSnapshot.forEach((calendar) => {
      let providerRef = calendar.get("providerReference");
      //locations.push(locationRef);

      unresolvedPromises.push(this.getProvider(providerRef));

      /*
    calendar.data().providerReference.get().then( providerSnap=>{
      let provider= providerSnap.data();
      providers.push(<Provider> {
        "fullname": provider.name,
        "email": provider.email,
        "degree": provider.speciality,
        "phone":provider.phone,
        "slug":provider.slug,
      });
    });
    */
    });

    // });
    providers = await Promise.all(unresolvedPromises);
    return providers;
  }

  async getProviderRef(acc_id, providerSlug) {
    const providerSnap = await firebase
      .firestore()
      .collection("accounts")
      .doc(acc_id)
      .collection("providersDetails")
      .where("slug", "==", providerSlug)
      .get();
    if (!providerSnap.empty) {
      return providerSnap.docs[0];
    } else {
      return;
    }
  }

  async getAccId(acc_slug) {
    /**
     * for given account slug return account id.
     */
    let docID;
    let snapshot = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", acc_slug)
      .get();
    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        docID = doc.id;
        this.acc_id = doc.id;
      });
      return {
        id: snapshot.docs[0].id,
        showProviders: snapshot.docs[0].get("showProviders"),
      };
    } else {
      return;
    }
  }

  async getProvider(providerRef: any) {
    let providerSnapshot = await providerRef.get();

    let provider = providerSnapshot.data();

    return <Provider>{
      fullname: provider.name,
      email: provider.email,
      degree: provider.speciality,
      phone: provider.phone,
      slug: provider.slug,
      img_url: provider.img_url
    };
  }
}
