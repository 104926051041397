import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";
import { COUNTRY_CODES } from "../_constants/country_phone_codes";
import * as moment from "moment-timezone";
import { DatePipe, formatDate, Location } from '@angular/common';
import { Input, Output, EventEmitter } from "@angular/core";
import { US_States, IN_States } from "../_models/state";
import { NgForm } from "@angular/forms";

import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog
}
  from
  '@angular/material/dialog'
  ;
import { OTPVerificationComponent } from "../otp-verification/otp-verification.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-appointment-confirmation",
  templateUrl: "./appointment-confirmation.component.html",
  styleUrls: ["./appointment-confirmation.component.css"],
})
export class AppointmentConfirmationComponent implements OnInit {
  @Input() rescheduleAppt: string;
  @Input() showLoadingRes: boolean
  @Output() confirmRescheduleAppointment = new EventEmitter()
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email_id: string = "";
  street: string = "";
  city: string = "";
  state: string = "";
  message: string = "";
  title: string = "";
  selectedDate: any;
  selectedSlot: any;
  services: any;
  servicesSelected: any = [];
  services_chosen: string = '';
  appt_id: string;
  appointmentRef: any;
  contact: any;

  countryCodes: any = COUNTRY_CODES;
  countryFound = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  )
  countryCode: any = this.countryFound ? this.countryFound.code : '+1';

  providersName: string;
  address_status: boolean;
  message_status: boolean;
  calendar_configurations: any;
  location: string;
  isContinue: boolean = false;
  patientType: string;
  patientOptions: string[] = ["Yes", "No"];
  settings: any = {};
  states: any = [];
  postalCode: string;
  apointmentTypeOptions: string[] = ["In-person", "Virtual Visit"];
  emergency = ['Yes', 'No']
  type: any;
  loading: boolean = true;
  day: number;
  timeslots: any = [];
  showForm: boolean = false;
  isEmergency: string;
  birthDate: any;
  send_text_reminders: boolean = false
  showSummryForMobile: boolean = false;
  @ViewChild('formData') public contactInfoForm: NgForm;
  selectedServicesForSummary: any;
  checkBoxValidation: boolean = true
  firstTimeReloadPage: boolean = true
  formSubmitted: boolean = false;
  gobackClick: boolean = false;
  dateOfBirth: any;
  numberStatus: any;
  showConfirm: boolean = true;
  showLoading: any;
  codeCountry: any;
  showStatus: boolean = false;
  @ViewChild("spinner") spinner: ElementRef;
  @ViewChild('dialogRef')
  dialogRef!: TemplateRef<any>;
  showOtpDialog: boolean = false;
  matDialogCloseSub$: any;
  dialogCloseLoading: any = false;
  countries = []
  searchedCountries: any[];

  phoneNumberInputMask: string = '(000) 000-0000';
  birthdateInputMask: string = '00/00/0000'
  dateFormat = "MM/DD/YYYY";
  appointmentConfirmLoading: boolean = false;
  constructor(
    private router: Router,
    public appointmentService: AppointmentService,
    public rescheduleService: RescheduleAppointmentService,
    public _location: Location,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    this.patientType = "";
    console.log("COnstructor called");
    console.log(this.showLoadingRes);
    console.log(this.numberStatus);
  }

  ngOnInit() {

    this.iniTializeComponent();
    this.getCountries()
    if (this.rescheduleAppt !== undefined && this.rescheduleAppt == 'true') {
      this.showLoading = false;
      this.showConfirm = false;
    }
    console.log(this.appointmentService.formData);
    if (this.appointmentService.formData !== undefined) {
      this.showLoading = false;
      this.showConfirm = false;
      let phone = this.appointmentService.formData.phone_no;
      let country_code = this.appointmentService.formData.countryCode;
      this.appointmentService.getNumberStatus(phone, country_code).then(docSnap => {
        docSnap.forEach(doc => {
          console.log(doc.data());
          if (doc.data().status == 'valid') {
            this.showLoading = false;
            this.showConfirm = false;
          }
          else {
            this.showConfirm = true;
          }
        })
      })
    }

  }

  iniTializeComponent() {
    this.decideInputMask()
    this.appointmentService.getTwilioCredentials();

    if (this.appointmentService.apptConfirmed)
      this.appointmentService.bookAnotherAppointment()
    this.providersName = this.appointmentService.providersName;
    this.calendar_configurations = this.appointmentService.getSettings();

    this.location = this.calendar_configurations.location.name;
    if (this.appointmentService.formData !== undefined) {
      let formData = this.appointmentService.formData;
      this.first_name = formData.first_name;
      this.last_name = formData.last_name;
      // this.countryCode = formData.countryCode;
      this.phone = formData.phone_no;
      this.email_id = formData.email;
      this.type = formData.type;
      this.isEmergency = formData.isEmergency;
      console.log(formData, this.countryCodes);

      if (formData.birthDate != undefined && formData.birthDate != "") {
        this.birthDate = moment(formData.birthDate).format(this.dateFormat)
      }
      this.send_text_reminders = formData.send_text_reminders
      if (this.calendar_configurations.hideNewPatient == false || this.calendar_configurations.hideNewPatient == undefined) {
        this.patientType = formData.patient;
      }
      this.street = formData.street;
      this.city = formData.city;
      this.state = formData.state;
      this.message = formData.message;
      this.services = this.appointmentService.getSettings().services;
      if (this.services.enabled === true) {
        this.services_chosen = formData.services;
        if (this.services.multiSelect == false) {
          this.services_chosen = formData.services;

        }
        let selectedServices = formData.services;
        if (this.services.multiSelect == true) {
          let servicesSplit = selectedServices.split(",  ");
          for (let i = 0; i < this.services.provided.length; i++) {
            if (servicesSplit.includes(this.services.provided[i])) {
              this.checkBoxValidation = true
              this.firstTimeReloadPage = false
              this.servicesSelected.push(true);
            } else {
              this.servicesSelected.push(false);
            }
          }
        }
      }
    }

    this.settings = this.appointmentService.getSettings();
    if (
      this.settings.terms_conditions == true ||
      this.settings.payments == true
    ) {
      this.isContinue = true;
    }

    if (this.calendar_configurations.form_fields !== undefined) {
      this.address_status = this.calendar_configurations.form_fields.address;
    }

    if (this.calendar_configurations.form_fields !== undefined) {
      this.message_status = this.calendar_configurations.form_fields.message.show;
      if (
        this.calendar_configurations.form_fields.message.title === undefined ||
        this.calendar_configurations.form_fields.message.title === ""
      ) {
        this.title = "Message";
        // this.message_title_event.emit(this.title);
      } else {
        this.title = this.calendar_configurations.form_fields.message.title;
        // this.message_title_event.emit(this.title);
      }
    }

    this.services = this.appointmentService.getSettings().services;
    if (this.services.enabled === true) {
      for (let i = 0; i < this.services.provided.length; i++) {
        this.servicesSelected.push(false);
      }

      if (this.services.multiSelect == true && this.firstTimeReloadPage == true)
        this.checkBoxValidation = false
    }

    if (
      this.calendar_configurations.terms_conditions == true ||
      this.calendar_configurations.payments == true
    ) {
      this.isContinue = true;
      let country = this.countryCodes.find((x) =>
        x.timezones.includes(moment.tz.guess())
      ).country_code;
      this.states = country == 'IN' ? IN_States : US_States;
    }
    else {
      this.state = "";
    }
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      let slot = this.appointmentService.getSelectedSlot();
      if (slot !== undefined) {
        this.selectedSlot = slot;
      } else {
        this.router.navigateByUrl("/timeslots");
      }
    } else {
      if (this.appointmentService.multipleLocations === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { multipleLocations: true } }
        );
      } else if (this.appointmentService.providerLocations === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { providerLocations: true } }
        );
      } else if (this.appointmentService.multipleProviders === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { multipleProviders: true } }
        );
      } else {
        this.router.navigateByUrl(
          `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
        );
      }
    }
    if (this.rescheduleAppt == 'true') {
      this.isContinue = false
    }
    this.showForm = false;
  }

  confirmAppointment(formData) {
    if (this.settings.otpVerification == true && this.appointmentService.otpVerified == false) {
      this.showOtpDialog = true;
      const dialogRef = this.dialog.open(OTPVerificationComponent, {
        "width": '500px',
        "data": { "phone": formData.value.phone_no, "countryCode": formData.value.countryCode },
        "disableClose": true
      });
      this.matDialogCloseSub$ = dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == true) {
           this.appointmentConfirmLoading=true
          console.log(result);
          // this.checkBoxValidation = false;
          this.showOtpDialog = false;
          this.assigningFormData(formData);
          this.showConfirm = true;
          if (this.rescheduleAppt == undefined) {
            this.appointmentService.confirmAppointment();
          }
        }
        if (result == false) {
          console.log(result);
          this.showLoading = false;
          this.showConfirm = false;
        }

      });
    }
    else {
      this.appointmentConfirmLoading=true
      this.showConfirm = true
      this.assigningFormData(formData);
      if (this.rescheduleAppt == undefined) {
        this.appointmentService.confirmAppointment();
      }
    }

  }

  redirectTo(formData) {
    if (this.settings.otpVerification == true && this.appointmentService.otpVerified == false) {
      this.showOtpDialog = true;
      const dialogRef = this.dialog.open(OTPVerificationComponent, {
        "width": '500px',
        "data": { "phone": formData.value.phone_no, "countryCode": formData.value.countryCode },
        "disableClose": true
      });
      this.matDialogCloseSub$ = dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result == true) {
          console.log(result);
          this.checkBoxValidation = false;
          this.showOtpDialog = false;
          this.assigningFormData(formData);
          if (this.calendar_configurations.terms_conditions == true) {
            console.log(this.appointmentService.getFormData());
            this.router.navigateByUrl("/terms-and-conditions");
          } else if (this.calendar_configurations.payments == true) {
            this.router.navigateByUrl("/payments");
          }
        }
        if (result == false) {
          console.log(result);
          this.showLoading = false;
          this.showConfirm = false;
        }

      });
    }
    else {
      this.assigningFormData(formData);
      if (this.calendar_configurations.terms_conditions == true) {
        console.log(this.appointmentService.getFormData());
        this.router.navigateByUrl("/terms-and-conditions");
      } else if (this.calendar_configurations.payments == true) {
        this.router.navigateByUrl("/payments");
      }
    }
  }

  getCountryCode(value: any) {
    //  console.log(value);
    this.codeCountry = value;
    this.numberStatus = undefined;
    //  console.log(this.phone);
    this.lookupNumber(this.phone, this.codeCountry, this.countryCodes);
    // this.phone = '';
  }

  async lookupNumber(phone: any, countryCode: any, country: any) {
    //this.showLoading = true;
    // console.log(phone, countryCode, country);
    // console.log(typeof (phone), phone.length);
    this.appointmentService.otpVerified = false
    for (let i = 0; i < country.length; i++) {
      if (countryCode === country[i].code && this.phone !== '') {
        let country_code = country[i].country_code;
        if (phone.length === 10) {
          if (this.appointmentService.getSettings().validatePhone === false || this.appointmentService.getSettings().validatePhone === undefined) {
            console.log("not validating phone number");
            this.showConfirm = false;
            this.showLoading = false;
          }
          else {
            this.showLoading = true;
            this.showConfirm = true;
            this.appointmentService.lookupPhoneNumber(phone, countryCode, country_code).subscribe(resp => {
              this.numberStatus = resp;
              console.log(this.numberStatus, this.showLoading);
              if (this.numberStatus !== undefined) {
                if (this.numberStatus.status == 'valid') {
                  this.showLoading = false;
                  this.showConfirm = false;
                  this.showStatus = false;
                }
                else {
                  this.showLoading = true;
                  this.showConfirm = true;
                  if (this.numberStatus.status == 'invalid') {
                    this.showLoading = false;
                    this.showConfirm = false;
                    //   this.showStatus = true;
                    this.showConfirm = true;
                  }

                }
              }

            });
          }
        }
        else if (phone.length < 10) {
          //   console.log("Phone length reduction");
          this.numberStatus = undefined;
          //  console.log(this.numberStatus);
        }
      }
    }

  }

  assigningFormData(formData) {
    if (this.services.multiSelect == true && this.services.enabled)
      formData.value.services = this.services_chosen

    else if (this.services.multiSelect == false && this.services.enabled) {
      formData.value.services = formData.value.service
    }

    else {
      formData.value.services = ""
    }
    formData.value.email = formData.value.email.trim().toLowerCase();
    formData.value.countryCode = this.countryCode;
    formData.value.phone_no = formData.value.phone_no;
    formData.value.type = this.type ? this.type : '';
    formData.value.isEmergency = this.isEmergency ? this.isEmergency : '';
    formData.value.patientType = this.patientType ? this.patientType : '';
    formData.value.birthDate = this.birthDate && moment(this.birthDate, this.dateFormat).isValid() ? moment(this.birthDate, this.dateFormat).toDate() : '';

    formData.value.send_text_reminders = this.send_text_reminders;
    if (this.postalCode !== undefined) {
      formData.value.postalCode = this.postalCode;
    }

    this.appointmentService.setFormData(formData.value);

    if (this.rescheduleAppt == 'true' && this.gobackClick == false)
      this.confirmRescheduleAppointment.emit(formData.value)
  }


  updateAddress(selectedValue) {
    // ?    this.state = this.selectedState.viewValue;
    console.log(selectedValue);
  }


  onDateChange(event) {
    this.loading = true;
    this.showForm = true;
    console.log(typeof (event));
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      //this.appointmentService.getTimeslots()
      this.providersName = this.appointmentService.providersName;
      this.location = this.appointmentService.getSettings().location.name;
      let timeslot_settings = this.appointmentService.getSettings();
      //.then(res => {
      //     this.timeslot_settings = res.data();
      this.day = date.day();
      this.timeslots = timeslot_settings["timeslots"][this.day];
      this.appointmentService.checkSlotsAvailability().then(bookedSlots => {
        //  this.timeslots.slots = this.timeslots.slots.filter(x => !(bookedSlots.includes(`${x.start} - ${x.end}`)));
        this.timeslots.slots = this.timeslots.slots.filter(x => {
          let flag = 0;
          bookedSlots.forEach(y => {
            if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold")) {
              console.log("Does not include" + y.slot + x.start + x.end);
              flag = 1;
            }
          });
          if (flag == 0) {
            return true;
          } else {
            return false;
          }
        });
        this.selectedSlot =
          this.appointmentService.getSelectedSlot() !== undefined
            ? this.appointmentService.getSelectedSlot()
            : "";
        let now = moment();
        console.log(now.format());
        if (date.isSame(now, "day")) {
          let timezone = this.appointmentService.getTimezone();
          let hour_from_now = now.tz(timezone).add(1, "hours");
          this.timeslots.slots = this.timeslots.slots.filter(x =>
            moment
              .tz(
                `${this.selectedDate} ${x.start}`,
                "dddd, MMMM D YYYY h:mm a",
                timezone
              )
              .isAfter(hour_from_now, "hour")
          );
          console.log(this.timeslots.slots);
        }
        this.loading = false;
      });
      // });
    } else {
      this.router.navigateByUrl(
        `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }

  setTimeslot(timeslot: any) {
    this.appointmentService.setSelectedSlot(timeslot);
    this.iniTializeComponent();
  }

  closeWindow() {
    window.parent.postMessage(JSON.stringify({ eventType: 'CLOSE' }), "*");
  }

  chekedServices() {
    this.services_chosen = "";
    let cnt = 0;
    this.checkBoxValidation = false
    for (let i = 0; i < this.servicesSelected.length; i++) {
      if (this.servicesSelected[i] === true) {
        cnt++;
        if (cnt == 1)
          this.services_chosen += this.services.provided[i]
        else
          this.services_chosen += ",  " + this.services.provided[i]
      }
    }
    if (cnt > 0)
      this.checkBoxValidation = true
    this.selectedServicesForSummary = this.services_chosen
  }

  servicesSelectedByRadioButton(index) {
    this.selectedServicesForSummary = this.services.provided[index]
  }

  goBack() {
    this.gobackClick = true
    this.assigningFormData(this.contactInfoForm)
    this.appointmentService.setSelectedDate(undefined);
    this.appointmentService.setSelectedSlot(undefined);
    this.appointmentService.routeback();
  }


  summaryShow(event) {
    console.log(event);
    this.showSummryForMobile = event
  }

  nextButtonOfFooteClick() {
    if (this.isContinue)
      this.redirectTo(this.contactInfoForm)
    else {
      this.formSubmitted = true;
      this.confirmAppointment(this.contactInfoForm)
    }
  }


  convertToLowercase() {
    if (this.email_id) {
      this.email_id = this.email_id.toLowerCase()
    }
  }

  decideInputMask() {
    if (this.appointmentService.getLocalTimezoneAbbr() == 'IST') {
      this.birthdateInputMask = '00/00/0000';
      this.dateFormat = "DD/MM/YYYY";
      this.phoneNumberInputMask = "0000000000"
    }
  }

  isValidDate(form: NgForm) {
    if (!moment(this.birthDate, this.dateFormat).isValid() || moment().diff(moment(this.birthDate, this.dateFormat).toDate(), 'years') > 125 || moment().diff(moment(this.birthDate, this.dateFormat), 'days') < 0) {
      form.controls['dateOfBirth'].setErrors({ invalid: true })
    }
  }

  getCountries() {
    return this.http.get<any>('assets/countries.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => {
        this.countries = data;
        this.searchedCountries = data
      });
  }

  searchCountry(event) {
    let val = event.target.value.toLowerCase();
    this.searchedCountries = this.countries.filter((option) => {
      return option.name.toLowerCase().includes(val) || option.dial_code.toLowerCase().includes(val);
    });
  }

  slectedCountry(countryCode) {
    this.countryCode = countryCode
  }

}
