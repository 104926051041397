<div class="container-fluid" id="locationCard">
  <div *ngIf="isReady; then ready else not_ready">
  </div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div id="deskView" *ngIf="locations.length!==0 && !isProvider" style="text-align: center;">
      <!-- <app-header></app-header> -->
      <br>
      <div class="row" id="subHeader">
        <div class="col-md-4" style="text-align:left">
          <div *ngIf="appointmentService.routerPaths.length>1">
            <div *ngIf="appointmentService.routerPaths[appointmentService.routerPaths.length-2].isAutoRoute!=true">
              <span style="cursor: pointer;margin-top: -10px;" class="material-icons arrow" (click)="goBack()">
                keyboard_arrow_left
              </span>
              <a (click)="goBack()"> Back</a>
            </div>
          </div>
        </div>
        <div class="col-md-4" style="text-align: center;">
          <b>Select a Location</b>
        </div>
        <div class="col-md-4">

        </div>

      </div>
      <br>
    </div>

    <div id="mobileView" *ngIf="locations.length!==0 && !isProvider" style="text-align: center;">
      <!-- <app-header></app-header> -->
      <br>
      <div class="row" id="subHeader">
        <div style="text-align: center;">
          <b>Select a Location</b>
        </div>
      </div>
    </div>

    <div id="deskView" *ngIf="locations.length!==0 && isProvider" style="text-align: center;">
      <br>
      <div class="row" id="subHeader">
        <div class="col-md-4" style="text-align:left">
          <div *ngIf="appointmentService.routerPaths.length>1">
            <div *ngIf="appointmentService.routerPaths[appointmentService.routerPaths.length-2].isAutoRoute!=true">
              <span style="cursor: pointer;margin-top: -10px;" class="material-icons arrow" (click)="goBack()">
                keyboard_arrow_left
              </span>
              <a (click)="goBack()">Selct Another Provider</a>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div style="text-align: center;">
            <b>Please select your preferred location for
              {{providerName | titlecase}}</b>
          </div>
        </div>
        <div class="col-md-4">

        </div>

      </div>
    </div>

    <div id="mobileView" *ngIf="locations.length!==0 && isProvider" style="text-align: center;">
      <br>
      <div class="row" id="subHeader">
        <div style="text-align: center;">
          <b>Please select your preferred location for
            {{providerName | titlecase}}</b>
        </div>
      </div>
    </div>

    <div *ngIf="locations.length==0">No Location</div>
    <div id="card-container" *ngIf="locations.length > 1">
      <div id="deskView" *ngFor="let location of locations">
        <mat-card>

          <a *ngIf="!isProvider && (showProviders === undefined || showProviders === true)"
            [routerLink]="['/providers', acc_slug, location.slug ]">
            <h4>{{ location.name |titlecase }}</h4>
          </a>

          <a *ngIf="!isProvider && showProviders === false" (click)="goToCalendar(location.calendar_link)">
            <h4>{{ location.name |titlecase }}</h4>
          </a>
          <!-- <a *ngIf="isP/rovider" (click)="callService(provider)"><h4>{{ location.name }}</h4></a> -->
          <a *ngIf="isProvider && (showProviders === undefined || showProviders === true)"
            [routerLink]="['/services', acc_slug, provider,location.slug]">
            <h4>{{ location.name |titlecase }}</h4>
          </a>
          <a *ngIf="isProvider && showProviders === false" (click)="goToCalendar(location.calendar_link)">
            <h4>{{ location.name |titlecase }}</h4>
          </a>
          <br>
          <p style="font-size: large;">{{ location.address}}</p>
          <br>
          <a style="font-size: large;" href="tel:{{ location.phone }}">{{location.phone}}</a>
        </mat-card>
      </div>
      <div id="mobileView" *ngFor="let location of locations">
        <mat-card>
          <a *ngIf="!isProvider && (showProviders === undefined || showProviders === true)"
            [routerLink]="['/providers', acc_slug, location.slug ]">
            <h4>{{ location.name |titlecase }}</h4>
          </a>

          <a *ngIf="!isProvider && showProviders === false" (click)="goToCalendar(location.calendar_link)">
            <h4>{{ location.name |titlecase }}</h4>
          </a>
          <!-- <a *ngIf="isP/rovider" (click)="callService(provider)"><h4>{{ location.name }}</h4></a> -->
          <a *ngIf="isProvider && (showProviders === undefined || showProviders === true)"
            [routerLink]="['/services', acc_slug, provider,location.slug]">
            <h4>{{ location.name |titlecase }}</h4>
          </a>
          <a *ngIf="isProvider && showProviders === false" (click)="goToCalendar(location.calendar_link)">
            <h4>{{ location.name |titlecase }}</h4>
          </a>

          <p style=" font-size: inherit;;">{{ location.address}}</p>
          <a style="font-size: inherit;" href="tel:{{ location.phone }}">{{location.phone}}</a>

        </mat-card>
      </div>

    </div>
  </ng-template>

</div>
<div class="footer">
  <app-footer [back]="true" [summary]="false" [next]="false" (goBack)="goBack()">
  </app-footer>
</div>