import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ProvidersService } from "../_services/providers.service";
import { CalendarService } from "../_services/calendar.service";
import { Calendar } from "../_models/interfaces";
import { LocationsService } from "../_services/locations.service";
import { AppointmentService } from "../_services/appointment.service";
@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"],
})
export class ServicesComponent implements OnInit {
  acc_slug: string;
  provider: string;
  location: string;
  acc_id: string;
  isReady: boolean = false;
  calendars: Array<Calendar>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private providerService: ProvidersService,
    private calendarService: CalendarService,
    private locationService: LocationsService,
    private appointmentService: AppointmentService
  ) { }

  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.acc_slug = url[1].path;
      this.provider = url[2].path;
      this.location = url[3].path;
      this.providerService.getAccId(this.acc_slug).then((docDetails) => {
        this.acc_id = docDetails.id;
        this.providerService
          .getProviderRef(this.acc_id, this.provider)
          .then((provSnap) => {
            this.locationService
              .getLocationRef(this.acc_id, this.location)
              .then((locSnap) => {
                this.calendarService
                  .getCalendars(this.acc_id, provSnap.ref, locSnap.ref)
                  .then((calendars) => {
                    this.calendars = calendars;
                    if (calendars.length === 1) {
                      this.appointmentService.setrouterActivity();
                      this.router.navigate([
                        "calendar/",
                        this.acc_slug,
                        this.calendars[0].slug,
                      ]);
                    }
                    this.isReady = true;
                  });
              });
          });
        // this.providerService.getProviderRef(this.acc_id,this.provider);
      });
    });
  }
  goBack() {
    this.appointmentService.routeback()
  }
}
