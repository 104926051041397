import { Component, OnInit } from "@angular/core";
import { AppointmentService } from "../_services/appointment.service";
import { Router } from "@angular/router";
// import { DocumentReference } from "angularfire2/firestore";

declare const dataLayer: any;

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.css"],
})
export class TermsAndConditionsComponent implements OnInit {
  settings: any = {};
  isReady: boolean = false;
  terms: string;
  agree: boolean = false;
  appointmentRef: any;
  appt_id: string;
  form_fieldsRef: any;
  isConfirm: boolean = false;
  providersName: string;
  disableLink: boolean = false;
  location: string;
  selectedDate;
  title: string;
  selectedSlot;
  showSummryForMobile: boolean = false;
  showConfirm: boolean = false;

  constructor(
    public appointmentService: AppointmentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.settings = this.appointmentService.getSettings();
    this.location = this.settings.location.name;
    this.providersName = this.appointmentService.providersName;
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      let slot = this.appointmentService.getSelectedSlot();
      if (slot !== undefined) {
        this.selectedSlot = slot;
      } else {
        this.router.navigateByUrl("/timeslots");
      }
    }
    this.disableLink = false;
    this.terms = this.settings.terms_conditions_info;
    this.isConfirm = this.settings.payments ? false : true;
    this.isReady = true;
  }
  agreeTermsAndConditions(event) {
    this.agree = event;
  }

  redirectTo() {
    this.router.navigateByUrl("/payments");
  }

  confirmAppointment() {
    this.disableLink = true;
    this.showConfirm = true;
    this.appointmentService.confirmAppointment();

  }

  goBack() {
    this.appointmentService.routeback();
  }


  summaryShow(event) {
    console.log(event);
    this.showSummryForMobile = event
  }

  nextButtonOfFooteClick() {
    if (this.isConfirm)
      this.confirmAppointment()
    else
      this.redirectTo()
  }
}
