import { Component, OnInit } from '@angular/core';
import {GooglePaymentsService} from '../_services/google-payments.service';


@Component({
  selector: 'app-google-pay-payments',
  templateUrl: './google-pay-payments.component.html',
  styleUrls: ['./google-pay-payments.component.css']
})
export class GooglePayPaymentsComponent implements OnInit {

  constructor(
    private gPayService : GooglePaymentsService,
  ) { }

  ngOnInit() {
    // this.gPayService.loadGooglePay();
  }
}
