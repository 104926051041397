<!-- <div class="row">
    <div class="col-md-12" style="background-color: #b6d7a8;">
        <h3 style="padding: 1rem;"> Follow Up Visit-Selected Slot</h3>
    </div>
</div> -->
<div class="container-fluid" style="    padding-top: 30px;">
    <form>

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput name="first_name" [(ngModel)]="contactInfo.first_name" size="100"
                            placeholder="First Name" readonly>
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field class="example-full-width">
                        <input matInput name="last_name" [(ngModel)]="contactInfo.last_name" placeholder="Last Name"
                            readonly>
                    </mat-form-field>
                </div>
            </div>

            <!-- Removed phone number pattern check -->
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="example-full-width">

                        <input matInput id="phone_no" #country_code="ngModel" name="country_code"
                            [(ngModel)]="contactInfo.countryCode" pattern="" placeholder="Country Code" readonly />
                        <!-- <mat-select name="countryCode" [(ngModel)]="contactInfo.countryCode" [disabled]="true">
                <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                  {{ country.code }} ({{ country.name }})
                </mat-option>
              </mat-select> -->
                    </mat-form-field>
                </div>
                <div class="col-md-9">
                    <mat-form-field class="example-full-width">
                        <input matInput id="phone_no" #phone_no="ngModel" name="phone_no"
                            [(ngModel)]="contactInfo.phone" pattern="" placeholder="Phone Number" readonly />
                    </mat-form-field>
                </div>
            </div>

            <!-- Removed email pattern check [A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$ -->
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field class="example-full-width">
                        <input matInput id="email" #email="ngModel" name="email" [(ngModel)]="contactInfo.email"
                            placeholder="Email" readonly />
                    </mat-form-field>
                </div>
            </div>
        </div>

    </form>
</div>

<div class="col-md-12" style="padding: 2em;">
    <!-- <div class="col-md-6" style="background: white;margin-top: 1em;overflow-y:auto">
        <div>
            <p class="highlight" style="margin-top: 1em;text-align: center;">Old Slots for follow up visit</p>
        </div>
        <div *ngFor="let content  of  rescheduleRecurringArrary;let j=index">
            <div>
                <div class="container-fluid">
                    <div class="row">
                        <div style="font-size: 40px;text-align: end;" class="col-md-2">
                            {{j+1}}
                        </div>
                        <div class="col-md-7">
                            <div class="appointment_slot">
                                <p style="text-decoration: line-through;">{{ content.date | date}}
                                    {{
                                    content.slot
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6" style="background: white;margin-top: 1em;overflow-y:auto">
        <div>
            <p class="highlight" style="margin-top: 1em;text-align: center;">
                New Selected Slots for follow up visit
            </p>
        </div>
        <div *ngFor="let content  of  recurringSchedule;let j=index">
            <div *ngIf="content.selectedSlot.start">
                <div class="container-fluid">
                    <div class="row">
                        <div style="font-size: 40px;text-align: end;" class="col-md-2">
                            {{j+1}}
                        </div>
                        <div class="col-md-7">
                            <div class="appointment_slot">
                                <p>{{ content.date | date}}
                                    {{
                                    appointmentService.convertToLocalTime(content.selectedSlot.start)}}
                                    -
                                    {{ appointmentService.convertToLocalTime(content.selectedSlot.end)}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="col-md-2">
    </div>
    <div class="col-md-8">
        <table>
            <tr>
                <th style="text-align: center;">Sr. No</th>
                <th>Schedule</th>
            </tr>
            <tr *ngFor="let content of displayReccuringArray;let i=index">
                <td style="text-align: center;">{{i+1}}</td>
                <td>
                    <div
                        *ngIf="rescheduleRecurringArrary[i].date == content.date && rescheduleRecurringArrary[i].slot == content.slot; else diffrent">
                        {{content.date | date}}
                        {{content.slot}}
                    </div>
                    <ng-template #diffrent>
                        <p style="text-decoration: line-through;"> <b>{{ rescheduleRecurringArrary[i].date | date}}
                                {{
                                rescheduleRecurringArrary[i].slot
                                }}</b>
                        </p>
                        {{content.date | date}}
                        {{content.slot}}
                    </ng-template>
                </td>
            </tr>
        </table>
    </div>
    <div class="col-md-2">
    </div>
</div>
<div class="row">
    <div id="bottom" class="col-md-12 text-center">
        <div class="col-md-6">
            <button mat-raised-button (click)="back()" color="primary" type="submit">
                Back
            </button>
        </div>
        <div class="col-md-6">
            <button mat-raised-button (click)="confirm()" color="primary" type="submit">
                Confirm
            </button>
        </div>
    </div>
</div>