import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() back: boolean;
  @Input() summary: boolean;
  @Input() nextBtnDisabled: boolean;
  @Input() next: boolean;
  @Input() confirm: boolean;
  @Input() showConfirm: boolean
  @Input() bookAnotherAppointment: boolean;
  @Output() summaryShow = new EventEmitter();
  @Output() goBack = new EventEmitter();
  @Output() nextButtonClick = new EventEmitter();


  summaryArrow: string = 'up'
  settings: any = {};


  constructor(public appointmentService: AppointmentService) { }

  ngOnInit() {
    this.settings = this.appointmentService.getSettings();
  }

  summaryClick() {
    this.summaryArrow = this.summaryArrow == 'up' ? 'down' : 'up';
    this.summaryArrow == 'up' ? this.summaryShow.emit(false) : this.summaryShow.emit(true);
  }

  backButtonClick() {
    this.goBack.emit()
  }

  nextBtnClick() {
    this.nextButtonClick.emit()
  }

  bookAnotherAppointmentClick() {
    this.appointmentService.bookAnotherAppointment()
  }
}
