import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { AppointmentService } from '../../_services/appointment.service';
import * as moment from "moment-timezone";
@Component({
  selector: 'app-recuuring-timeslots',
  templateUrl: './recuuring-timeslots.component.html',
  styleUrls: ['./recuuring-timeslots.component.css']
})
export class RecuuringTimeslotsComponent implements OnInit, OnChanges {
  @Input() selectedDate;
  @Input() index;
  @Output() onTimeSlotSelected = new EventEmitter<Date>();
  day: number;
  timeslots: any;
  selectedSlot: any = {};
  isReady: boolean = false;
  location: string;
  loading: boolean = false;
  date: any
  constructor(
    public appointmentService: AppointmentService,
  ) { }



  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedDate) {
      this.isReady = false;
      this.checkAvalableSlots();
    }
  }

  ngOnInit() {
    this.checkAvalableSlots()
  }

  checkAvalableSlots() {
    if (this.selectedDate) {
      this.date = this.selectedDate.format("dddd, MMMM D YYYY");
      let timeslot_settings = this.appointmentService.getSettings();

      this.day = this.selectedDate.day();
      this.timeslots = timeslot_settings["timeslots"][this.day];
      console.log(this.timeslots)
      this.appointmentService.checkSlotsAvailabilityOfSelctedDate(this.selectedDate).then(bookedSlots => {
        this.timeslots.slots = this.timeslots.slots.filter(x => {
          let flag = 0;
          bookedSlots.forEach(y => {
            if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold")) {
              console.log("Does not include" + y.slot + x.start + x.end);
              flag = 1;
            }
          });
          if (flag == 0) {
            return true;
          } else {
            return false;
          }
        });
        this.isReady = true;
      });
    }
  }

  setTimeslot(timeslot: any) {
    var slotInfo: any = {};
    this.selectedSlot = timeslot;
    slotInfo.timeslot = timeslot;
    slotInfo.index = this.index;
    this.onTimeSlotSelected.emit(slotInfo);
  }



}
