<div style="overflow-y: hidden">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner" style="height: 1em;">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div *ngIf="timeslots.slots.length > 0">
      <p class="highlight" style="text-align: center;">{{selectedDate | date}}</p>
      <p class="highlight" style="text-align: center;">What time works for you?</p>
      <div class="appointment_slot" [class.selected]="selectedSlot === timeslot"
        *ngFor="let timeslot of timeslots.slots" (click)="setTimeslot(timeslot)">
        <p>
          {{ appointmentService.convertToLocalTime(timeslot.start) }} -
          {{ appointmentService.convertToLocalTime(timeslot.end) }}
        </p>
      </div>
      <small><em>*The above timings are in
          {{ appointmentService.getLocalTimezoneAbbr() }}</em></small>
    </div>
    <div *ngIf="timeslots.slots.length == 0">
      <p class="highlight" style="text-align: center;    margin-top: 8em;">
        Sorry, all slots for date {{selectedDate | date}} have been booked.
      </p>
    </div>
  </ng-template>

</div>