import { Component, OnInit } from '@angular/core';
import {PaytmService} from '../_services/paytm.service';
import { ActivatedRoute,Router } from "@angular/router";
import {AppointmentService} from "../_services/appointment.service";
import * as moment from "moment-timezone";

const num = Math.random()*1000;
declare const dataLayer: any;

@Component({
  selector: 'app-paytm-payment-processing',
  templateUrl: './paytm-payment-processing.component.html',
  styleUrls: ['./paytm-payment-processing.component.css']
})
export class PaytmPaymentProcessingComponent implements OnInit {
  amount:number;
  checksum:string;
  mbNo:number;
  email:string;
  status:string;
  response:string;
  paytmScreen : boolean=false;
  orderId:string;
  acc_slug :string;
  calendar_slug:string;
  isReady:boolean=false;
  constructor(
    private paytmService :PaytmService,
    private appointmentService: AppointmentService,
    private route:ActivatedRoute,
    private router:Router,

  ) { }

  ngOnInit() {
    this.route.params.subscribe(params=>{
      let param = Object.keys(params);
      if(param.includes('acc_slug') && param.includes('calendar_slug')){
      this.acc_slug = params.acc_slug;
      this.calendar_slug = params.calendar_slug;
      this.route.queryParams.subscribe( queryParams =>{
        this.orderId = queryParams['order_id'];
        this.appointmentService.getAppointmentDetails(this.acc_slug,this.orderId ,this.calendar_slug)
        .then(appointmentInfo => {
        if(appointmentInfo){
          this.paytmScreen = true;
          this.paytmService.getTransactionStatus({ORDERID:this.orderId,acc_slug:this.acc_slug}).then(transactionInfo=>{
            let transactionDetails = {
              amount:transactionInfo['TXNAMOUNT'],
              bankName:transactionInfo['BANKNAME'],
              transactionId: transactionInfo['TXNID'],
              bankTransactionId: transactionInfo['BANKTXNID'],
              status: transactionInfo['STATUS'],
              payed_on: moment(transactionInfo['TXNDATE']).toDate(),
              orderId:transactionInfo['ORDERID'],
              method:'paytm',
              transactionType:transactionInfo['TXNTYPE']
            }

            if(transactionInfo['STATUS']=="TXN_SUCCESS"){
              console.log("success");
              this.appointmentService.updatePaymentDetailsInAppointment(appointmentInfo, transactionDetails, dataLayer).then(
                apptSnap=>{
                  this.router.navigate(["/appointment-status"], {
                    queryParams: { status: "confirmed" },
                  });
                });

            }
            else{
              appointmentInfo.apptRef.delete();
              console.log(transactionInfo['STATUS']);
              this.router.navigate(["/appointment-status"], {
                queryParams: { status: "unconfirmed", errorIn: "payment" },
              });
            }
        });

        }
        else{
          this.router.navigate(["/appointment-status"], {
            queryParams: { status: "unconfirmed", errorIn: "timeout" },
          });
        }
      });
    });
  }
  else{
    console.log("invalid request");
    console.log(param);
  }
  });

  }

saveTransactionResponse(formData){
  }
}
