import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";

@Component({
  selector: "app-calendar-groups",
  templateUrl: "./calendar-groups.component.html",
  styleUrls: ["./calendar-groups.component.css"]
})
export class CalendarGroupsComponent implements OnInit {
  acc_slug: string;
  calendars: any = [];
  isReady: boolean = false;
  redirectUrl: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    console.log(this.router.url);
    this.redirectUrl = this.router.url;
    //  this.appointmentService.saveRedirectUrl(this.redirectUrl);
    this.route.queryParams.subscribe(queryParams => {
      console.log(queryParams.source);
      let source = this.appointmentService.getSource();
      let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }
      if (category === undefined) {
        category =
          queryParams.category === undefined ? "" : queryParams.category;
        this.appointmentService.setCategory(category);
      }
    });

    this.route.url.subscribe(url => {
      this.acc_slug = url[1].path;
      let group_slug = url[2].path;
      this.appointmentService
        .getCalendarGroups(this.acc_slug, group_slug)
        .then(calendars => {
          this.calendars = calendars;
          console.log(this.calendars);
          this.isReady = true;
        });
    });
  }
}
