import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-cancel-recurring-appointment',
  templateUrl: './cancel-recurring-appointment.component.html',
  styleUrls: ['./cancel-recurring-appointment.component.css']
})
export class CancelRecurringAppointmentComponent implements OnInit {
  recurringAppoinmentArrary: any = []
  recurringAppoinmentReferenceArrary: any = []
  contactInfo: any;
  loading: boolean = true
  appointmentResult: any;
  cancel: boolean = false;
  appointmentSnapshot: any;
  appointmentStatus: string
  constructor(private route: ActivatedRoute, private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.route.url.subscribe(async url => {
      console.log(url)
      let acc_slug = url[0].path
      let appointmentReferenceId = url[2].path;
      this.appointmentService.setAccReferenceByAccSlug(acc_slug).then(async res => {
        this.appointmentSnapshot = await this.appointmentService.getReccuringAppitmentsInfoByReferenceId(appointmentReferenceId)
        this.appointmentResult = this.appointmentSnapshot.data();
        if (this.appointmentResult.status == 'cancelled' || this.appointmentResult.status == 'rescheduled') {
          this.appointmentStatus = this.appointmentResult.status
          this.loading = false
        }
        else {
          let contactInfoData = await this.appointmentResult.contactRef.get()
          this.contactInfo = contactInfoData.data()
          await Promise.all(this.appointmentResult.appointmentDetails.map(async element => {
            let dataSnapshot = await element.get();
            let data = dataSnapshot.data()
            this.recurringAppoinmentArrary.push(data)
          })
          )
          let calenderSettingSnapShot = await this.recurringAppoinmentArrary[0].calendarReference.get()
          let calenderSetting = calenderSettingSnapShot.data()
          this.appointmentService.setSettings(calenderSetting);
          this.appointmentService.acc_name = this.recurringAppoinmentArrary[0].location;
          if (calenderSetting.providerReference)
            this.appointmentService.getProvidersName(calenderSetting.providerReference)
          this.loading = false
        }
      })

    })
  }
  async cancelAppointments() {
    this.loading = true
    await Promise.all(this.appointmentResult.appointmentDetails.map(async element => {
      await element.update({ status: 'cancelled' });
    }))
    await this.appointmentSnapshot.ref.update({ status: 'cancelled' })
    this.appointmentService.cancelFollowUpAppointmentEmailToOwner(this.contactInfo, this.recurringAppoinmentArrary)
    this.appointmentService.cancelFollowUpAppointmentEmailToUser(this.contactInfo, this.recurringAppoinmentArrary)
    this.loading = false
    this.cancel = true
  }
}
