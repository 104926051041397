<div class="container-fluid">
  <div *ngIf="isReady; then ready else not_ready">
  </div>
  <ng-template #not_ready>
    <div *ngIf="!showMsg" class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>

  </ng-template>
  <ng-template #ready>
    <p class="navigation" *ngIf="appointmentService.multipleLocations">
      <a (click)="goBack()"><i class="material-icons icon-btn">keyboard_backspace</i> Pick another location</a>
    </p>
    <mat-form-field class="center">
      <input matInput
             [min]="minDate"
             [max]="maxDate"
             [matDatepickerFilter]="myFilter"
             [matDatepicker]="picker"
             [value]="selectedDate"
             (dateInput)="setDate($event)"
             placeholder="Pick a date"
             style="width: 100%;"
             disabled>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker disabled="false" [opened]=true></mat-datepicker>
    </mat-form-field>
  </ng-template>
</div>
<div *ngIf="showMsg">
  <div class="msg">
    <div class="border">
      <i *ngIf="!pastEvent" class="material-icons">notification_important</i>
      <i *ngIf="pastEvent" class="material-icons">warning</i>
    <p class="notify">{{message}}</p>
    </div>
  </div>
</div>
