import { Component, OnInit } from "@angular/core";
import { AppointmentService } from "../_services/appointment.service";
import { Router } from "@angular/router";
import { PaypalService } from "../_services/paypal.service";
import { PaytmService } from "../_services/paytm.service";
// import { link } from "fs";


@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.css"],
})
export class PaymentsComponent implements OnInit {
  paymentInfo: string;
  appointmentRef: any;
  appt_id: string;
  settings: any;
  form_fieldsRef: any;
  title: string;
  isReady: boolean = false;
  providersName: string;
  location: string;
  selectedSlot;
  selectedDate;
  acc_slug: string;
  formFields: any;
  currentAppointmentDetails: any;
  showSummryForMobile: boolean = false;
  constructor(
    public appointmentService: AppointmentService,
    public router: Router,
    private paypalService: PaypalService,
    private paytmService: PaytmService,
  ) { }

  ngOnInit() {
    this.settings = this.appointmentService.getSettings();
    this.acc_slug = this.appointmentService.acc_slug;
    this.form_fieldsRef = this.settings;
    this.formFields = this.appointmentService.getFormData();
    if (this.form_fieldsRef.form_fields !== undefined) {
      if (
        this.form_fieldsRef.form_fields.message.title === "" ||
        this.form_fieldsRef.form_fields.message.title === undefined
      ) {
        this.title = "Message";
      } else {
        this.title = this.form_fieldsRef.form_fields.message.title;
      }
    }
    this.providersName = this.appointmentService.providersName;
    this.location = this.settings.location.name;
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      let slot = this.appointmentService.getSelectedSlot();
      if (slot !== undefined) {
        this.selectedSlot = slot;
      } else {
        this.router.navigateByUrl("/timeslots");
      }
    }
    else {
      if (this.appointmentService.multipleLocations === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { multipleLocations: true } }
        );
      } else if (this.appointmentService.providerLocations === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { providerLocations: true } }
        );
      } else if (this.appointmentService.multipleProviders === true) {
        this.router.navigate(
          [
            "/calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { multipleProviders: true } }
        );
      } else {
        this.router.navigateByUrl(
          `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
        );
      }
    }
    if (this.settings.payments == true) {
      this.paymentInfo = this.settings.payment_info;
      this.isReady = true;
      console.log(this.settings);
    }
  }

  //confirm appointment if payment details are not added for the calendar
  confirmAppointment() {
    this.appointmentService.confirmAppointment();
  }

  //create transaction and then save appointment with status:booked , paymentId:paymetnId
  generatePaymentAndSaveAppointment() {
    this.isReady = false;
    this.paypalService.createPaymentRequest(this.settings, this.formFields, this.selectedSlot, this.acc_slug).then(resp => {
      console.log("payment request generated successfully");
      let redirectUrl = resp["data"].links.filter(link => link.rel == "approve")[0].href;
      let transactionId = resp["data"].id;
      this.appointmentService.saveAppointmentForPayment({ paymentMethod: 'paypal', paymentId: transactionId }).then((apptDetails: any) => {
        let taskObject = {
          orderId: transactionId,
          appointmentId: apptDetails.apptRef.id,
          accountId: this.appointmentService.acc_ref.id,
          calenderId: this.appointmentService.calendarRef.id
        }
        console.log(taskObject)
        console.log("appointment added ");
        if (apptDetails["status"] !== "error") {
          this.appointmentService.createAppoitntmentProcessingTask(taskObject).then(resp => {
            window.parent.location.href = redirectUrl;
          })
        } else {
          console.log("error");
        }
      });
    },
      err => {
        console.log(err);
      });
  }

  paymentRequestForPaytm() {
    this.isReady = false;
    console.log("paytm payment request");
    this.appointmentService.saveAppointmentForPayment({ paymentMethod: 'paytm' }).then(
      (apptData: any) => {
        this.paytmService.getPaytmAPIKeys().then(keys => {
          this.paytmService.initiateTransaction(keys, this.settings.paymentDetails, apptData).then(
            transactionInfo => {
              console.log(transactionInfo);
              let taskObject = {
                orderId: apptData.apptRef.id,
                appointmentId: apptData.apptRef.id,
                accountId: this.appointmentService.acc_ref.id,
                calenderId: this.appointmentService.calendarRef.id
              };
              this.appointmentService.createAppoitntmentProcessingTask(taskObject).then(resp => {
                console.log(resp);
                this.paytmService.submitTransaction(transactionInfo);
              });
            });
        });
      });
  }

  // checkPaymentStatus(paymentInfo){
  //   if(paymentInfo.status=="error"){
  //     console.log(paymentInfo.data);
  //     this.currentAppointmentDetails.apptRef.delete();
  //     this.router.navigate(["/appointment-status"], {
  //       queryParams: { status: "unconfirmed", errorIn: "payment" },
  //     });
  //   }
  //   else if(paymentInfo.status=="successful"){
  //     console.log(status,"success");
  //     this.appointmentService.updatePaymentDetailsInAppointment(this.currentAppointmentDetails,paymentInfo.data,dataLayer);
  //     this.router.navigate(["/appointment-status"], {
  //       queryParams: { status: "confirmed" },
  //     });
  //     console.log(paymentInfo.data);
  //   }
  //   else if(paymentInfo.status=="initiated"){
  //     if( this.currentAppointmentDetails==undefined || Object.keys(this.currentAppointmentDetails).length==0){
  //       this.appointmentService.saveAppointmentForPayment(dataLayer).then(resp=>{
  //         console.log(resp);
  //         this.currentAppointmentDetails = resp;
  //       });
  //       console.log("appointment not exist");
  //     }
  //     else{
  //       console.log("appointment already booked ");
  //     }
  //   }
  // }

  goBack() {
    this.appointmentService.routeback();
  }


  summaryShow(event) {
    console.log(event);
    this.showSummryForMobile = event
  }


}
