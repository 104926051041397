<div class="row">
  <div class="row" id="subHeader">
    <div class="col-xs-4">
      <div *ngIf="appointmentService.routerPaths.length>1">
        <span style="cursor: pointer;margin-top: -15px;" id="backButton" class="material-icons arrow"
          (click)="goBack()">
          keyboard_arrow_left
        </span>
        <a (click)="goBack()">{{appointmentService.routerPaths[appointmentService.routerPaths.length
          -2].url.includes('/locations')? 'Select Another Location'
          :appointmentService.routerPaths[appointmentService.routerPaths.length
          -2].url.includes('/services')? 'Select Another Service' : 'Select Another Provider'}}</a>
      </div>
    </div>
    <div style="text-align: center;" class="col-xs-4">
      <b>Select Date and Time</b>
    </div>
    <div class="col-xs-4">

    </div>
  </div>
</div>
<div *ngIf="loading" class="spinner">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  <p>Loading...</p>
</div>
<div *ngIf="!loading" style="background-color:#f1f4f6;height: 84vh;">
  <div>
    <div class="row">
     
      <div class="col-md-9">
        <div class="column">
          <div class="row" style="background-color: white;height:  38em;">
            <div style="text-align: center;">
              <h4>
                {{monthTitle}}
              </h4>
            </div>

            <div class="flex-container">
              <div class="arrow-padding " [style.color]="starting==0 ? '#cbc5bc' : 'black'">
                <span (click)="weekDeccrement()" style="cursor: pointer;" class="material-icons arrow">
                  keyboard_arrow_left
                </span>
              </div>

              <ng-container *ngFor="let index of weekArrayIndexing">
                <div class="column">
                  <div>
                    <div id="dayDisplay">
                      {{availableSlotsArray[index+starting].date.substring(0,3) | uppercase }}
                    </div>
                    <div id="dateDisply" *ngIf="timeslot_settings.dateFormat">
                      {{availableSlotsArray[index+starting].date|date:timeslot_settings.dateFormat}}
                    </div>
                    <div id="dateDisply" *ngIf="!timeslot_settings.dateFormat">
                      {{availableSlotsArray[index+starting].date|date:'dd/MM/yyyy'}}
                    </div>
                  </div>
                  <div  class="column" id="timeSlotDiv">

                    <div *ngIf="availableSlotsArray[index+starting].isBlock==false;else off" style="padding-top: 2px;">
                      <div *ngIf="availableSlotsArray[index+starting].slots.length>0;else slotUnavailable">
                        <ng-container *ngFor="let slot of availableSlotsArray[index+starting].slots;let j=index">
                          <div style="cursor: pointer"
                            *ngIf="j>=availableSlotsArray[index+starting].start && j < availableSlotsArray[index+starting].end"
                            (click)="onSlotSelect(index,j)" id="slot"
                            >
                            <p >
                              {{slot.localTime}}
                            </p>
                          </div>
                        </ng-container>
                      </div>
                      <ng-container>
                        <div id="moreButton"
                          *ngIf="availableSlotsArray[index+starting].isBlock==false &&availableSlotsArray[index+starting].slots.length>5"
                          (click)="timeSlotShow(index+starting)">
                          <span style="cursor: pointer;" style="font-size:5em;" class="material-icons">
                            keyboard_arrow_down
                          </span>
                        </div>

                      </ng-container>
                    </div>
                    <ng-template #off>
                      <div style="margin-bottom: -9em;">
                        Booking
                      </div>
                      <br>
                      <div>
                        Unavailable
                      </div>
                    </ng-template>
                    <ng-template #slotUnavailable>
                      <div style="margin-bottom: -9em;">Slots</div>
                      <br>
                      <div>Unavailable</div>

                    </ng-template>
                  </div>
                </div>
              </ng-container>

              <div class="arrow-padding" [style.color]="ending==rollingWeeksArray.length ? '#cbc5bc' : 'black'">
                <span (click)="weekIncrement()" style="cursor: pointer;" class="material-icons arrow">
                  keyboard_arrow_right
                </span>
              </div>
            </div>
            <div style="text-align: center;">
              <small>* Timings are in
                {{ timeZone }}</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3" style="background-color: white;height:  38em;">
        <app-appointment-details></app-appointment-details>
      </div>
    </div>
  </div>


</div>
