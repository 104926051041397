import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { AppointmentService } from "../_services/appointment.service";

@Component({
  selector: "app-timeslot",
  templateUrl: "./timeslot.component.html",
  styleUrls: ["./timeslot.component.css"]
})
export class TimeslotComponent implements OnInit {

  @Input() rescheduleAppt: boolean;
  @Output() rescheduleDateSlected = new EventEmitter();
  selectedDate: any;
  day: number;
  timeslots: any;
  selectedSlot: any;
  isReady: boolean = true;
  providersName: string;
  location: string;
  loading: boolean = true;
  availableSlots: any = []
  clinicNumberOfRooms = 1;
  localTimezone: string
  constructor(
    public appointmentService: AppointmentService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.availableSlots = []
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      this.providersName = this.appointmentService.providersName;
      this.location = this.appointmentService.getSettings().location.name;
      let timeslot_settings = this.appointmentService.getSettings();
      this.clinicNumberOfRooms = timeslot_settings.clinicNumberOfRooms && this.appointmentService.accDetails.requestedAppointment ? timeslot_settings.clinicNumberOfRooms : this.clinicNumberOfRooms;

      this.day = date.day();
      this.timeslots = timeslot_settings["timeslots"][this.day];
      this.appointmentService.checkSlotsAvailability().then(bookedSlots => {
        this.timeslots.slots.forEach(x => {
          let flag = 0;
          if (this.appointmentService.accDetails.requestedAppointment)
            bookedSlots.forEach(y => {
              let apptStart = this.formatAMPM(y.appt_start.toDate())
              let apptEnd = this.formatAMPM(y.appt_end.toDate())
              let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
              if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
                flag = flag + 1;
              }
            });
          else
            bookedSlots.forEach(y => {
              if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
                flag = flag + 1;
              }
            });

          x.localTimezoneStart = this.appointmentService.convertToLocalTime(x.start)
          x.localTimezoneEnd = this.appointmentService.convertToLocalTime(x.end)
          if (flag < this.clinicNumberOfRooms) {
            this.availableSlots.push(x);
          }
        });
        this.selectedSlot =
          this.appointmentService.getSelectedSlot() !== undefined
            ? this.appointmentService.getSelectedSlot()
            : "";
        let now = moment();
        if (date.isSame(now, "day")) {
          let timezone = this.appointmentService.getTimezone();
          let hour_from_now = now.tz(timezone).add(1, "hours");
          
        }
      });
      // });
    } else {
      // this.router.navigateByUrl(
      //   `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      // );
    }
    this.localTimezone = this.appointmentService.getLocalTimezoneAbbr()
  }

  setTimeslot(timeslot: any) {
    this.appointmentService.setSelectedSlot(timeslot);
    if (this.rescheduleAppt == true) {
      this.rescheduleDateSlected.emit({ date: this.appointmentService.getSelectedDate(), slot: timeslot })
    }
    else
      this.router.navigateByUrl("/confirm-appointment");
  }

  formatAMPM(date) {
    // return moment(date).format("hh:mm a")
    return moment(date).tz(this.appointmentService.timezone).format("hh:mm a")
  }

  goBack() {
    this.appointmentService.setSelectedSlot({});
    if (this.appointmentService.multipleLocations === true) {
      this.router.navigate(
        [
          "/calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { multipleLocations: true } }
      );
    } else if (this.appointmentService.providerLocations === true) {
      this.router.navigate(
        [
          "/calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { providerLocations: true } }
      );
    } else if (this.appointmentService.multipleProviders === true) {
      this.router.navigate(
        [
          "/calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { multipleProviders: true } }
      );
    } else {
      this.router.navigateByUrl(
        `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }
  onDateChange(event) {
    this.availableSlots = [];
    this.loading = true;
    
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      this.providersName = this.appointmentService.providersName;
      this.location = this.appointmentService.getSettings().location.name;
      let timeslot_settings = this.appointmentService.getSettings();
      this.clinicNumberOfRooms = timeslot_settings.clinicNumberOfRooms && this.appointmentService.accDetails.requestedAppointment ? timeslot_settings.clinicNumberOfRooms : this.clinicNumberOfRooms;
      this.day = date.day();
      this.timeslots = timeslot_settings["timeslots"][this.day];
      this.appointmentService.checkSlotsAvailability().then(bookedSlots => {
        this.availableSlots = [];
        this.timeslots.slots.forEach(x => {
          let flag = 0;
          if (this.appointmentService.accDetails.requestedAppointment)
            bookedSlots.forEach(y => {
              let apptStart = this.formatAMPM(y.appt_start.toDate())
              let apptEnd = this.formatAMPM(y.appt_end.toDate())
              let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
              if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
                flag = flag + 1;
              }
            });
          else
            bookedSlots.forEach(y => {
              if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
                flag = flag + 1;
              }
            });

          x.localTimezoneStart = this.appointmentService.convertToLocalTime(x.start)
          x.localTimezoneEnd = this.appointmentService.convertToLocalTime(x.end)
          if (flag < this.clinicNumberOfRooms) {
            this.availableSlots.push(x);
          }
        });
        var currentTime = this.formatAMPM(new Date)
        if (this.selectedDate == moment().format("dddd, MMMM D YYYY"))
          this.availableSlots = this.availableSlots.filter(function (el) {
            return !moment(el.start, 'h:mma').isBefore(moment(currentTime, 'h:mma'))
          });

        this.selectedSlot =
          this.appointmentService.getSelectedSlot() !== undefined
            ? this.appointmentService.getSelectedSlot()
            : "";
        let now = moment();
        if (date.isSame(now, "day")) {
          let timezone = this.appointmentService.getTimezone();
          let hour_from_now = now.tz(timezone).add(1, "hours");
        }
        this.loading = false;
      });
    } else {
      this.router.navigateByUrl(
        `/calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }

  dayMonthFormatDate() {
    return this.appointmentService.getSelectedDate().format("MMMM D");
  }

}
