import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProvidersService } from "../_services/providers.service";
import { LocationsService } from "../_services/locations.service";
import { Location } from "../_models/interfaces";
import { AppointmentService } from "../_services/appointment.service";

@Component({
  selector: "app-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.css"],
})
export class LocationsComponent implements OnInit {
  acc_slug: string;
  provider: string;
  locations: Array<Location>;
  isReady: boolean = false;
  isProvider: Boolean = false;
  accId: string;
  providerName: string;
  showProviders: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private providersService: ProvidersService,
    private locationsService: LocationsService,
    public appointmentService: AppointmentService
  ) { }

  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.acc_slug = url[1].path;
      this.providersService.getAccId(this.acc_slug).then((docDetails) => {
        this.accId = docDetails.id;
        this.showProviders = docDetails.showProviders;

        if (url.length > 2) {
          this.isProvider = true;
          this.provider = url[2].path;
          this.providersService
            .getProviderRef(this.accId, this.provider)
            .then((providerSnap) => {
              this.providerName = providerSnap.data().name;
              let providerReference = providerSnap.ref;
              this.locationsService
                .getLocationForProvider(this.accId, providerReference)
                .then((locations) => {
                  this.locations = locations;
                  if (this.locations.length == 1) {
                    this.appointmentService.setrouterActivity();
                    this.router.navigate([
                      "services",
                      this.acc_slug,
                      this.provider,
                      this.locations[0].slug,
                    ]);
                  }
                  this.isReady = true;
                });
            });
        } else {
          this.locationsService.getLocations(this.accId).then((locations) => {
            this.locations = locations;

            if (
              this.showProviders === undefined ||
              this.showProviders === true
            ) {
              if (this.locations.length == 1) {
                this.appointmentService.setrouterActivity();
                this.router.navigate([
                  "providers",
                  this.acc_slug,
                  this.locations[0].slug,
                ]);
              }
              this.isReady = true;
            } else {
              if (this.locations.length == 1) {
                this.goToCalendar(this.locations[0].calendar_link);
                this.appointmentService.setrouterActivity()
              } else {
                this.isReady = true;
              }
            }
          });
        }
      });
    });
  }

  goToCalendar(calendarLinks: any) {
    this.locationsService
      .getCalendarForLocation(calendarLinks[0])
      .then((calendarSlug) => {
        this.router.navigate(["/calendar", this.acc_slug, calendarSlug]);
      });
  }


  goBack() {
    this.appointmentService.routeback()
  }
}
