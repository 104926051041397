import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-recurring-selected-timeslot',
  templateUrl: './show-recurring-selected-timeslot.component.html',
  styleUrls: ['./show-recurring-selected-timeslot.component.css']
})
export class ShowRecurringSelectedTimeslotComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
