<div class="container-fluid top-space">
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div>
      <p>
        <a (click)="goBack()"
          ><i class="material-icons icon-btn">keyboard_backspace</i> Pick
          another provider
        </a>
      </p>
    </div>
    <p class="center">
      Please select your preferred Location to book an appointment with
      {{ providersName }}
    </p>
    <div id="card-container">
      <div *ngFor="let calendar of calendars" class="card-block plan">
        <mat-card>
          <a
            (click)="setCalendarRef(calendar.ref)"
            [routerLink]="['/reschedule-calendar', acc_slug, calendar.slug]"
            [queryParams]="{ providerLocations: true }"
            ><h4>{{ calendar.location.name }}</h4></a
          >
          <p>{{ calendar.location.address }}</p>
          <a href="tel:{{ calendar.location.phone }}">{{
            calendar.location.phone
          }}</a>
        </mat-card>
      </div>
    </div>
  </ng-template>
</div>
