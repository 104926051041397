import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-recuuring-select-calender',
  templateUrl: './recuuring-select-calender.component.html',
  styleUrls: ['./recuuring-select-calender.component.css']
})
export class RecuuringSelectCalenderComponent implements OnInit {
  acc_slug: string;
  contactId: string;
  calenderArray: any = [];
  loading: boolean = true
  constructor(private route: ActivatedRoute, public appointmentService: AppointmentService, public router: Router) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      this.acc_slug = url[0].path;
      this.contactId = url[1].path;
    })
    this.appointmentService.setAccReferenceByAccSlug(this.acc_slug).then(async res => {
      this.appointmentService.getRecuuringCalenders().then(async resp => {
        if (resp.docs.length == 1) {
          var calenderData = resp.docs[0].data();
          this.appointmentService.setrouterActivity();
          this.router.navigate([
            "recurring",
            this.acc_slug,
            'book',
            calenderData.slug, this.contactId
          ]);
        }
        if (resp.docs.length > 1) {
          await Promise.all(resp.docs.map(async element => {
            this.calenderArray.push(element.data())
          }))
        }
        console.log(this.calenderArray)
        this.loading = false
      })
    })
  }

}


