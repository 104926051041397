<div mat-dialog-title style="color: red">{{data.header}}</div>

<div *ngIf="data.type=='change'" mat-dialog-actions>
  <button mat-raised-button (click)="close(false)">No</button>
  <button mat-raised-button color="primary" (click)="close(true)">Yes</button>
</div>

<div *ngIf="data.type=='reboook'" mat-dialog-actions>
  <div class="col-md-12 text-center">
    <button mat-raised-button color="primary" (click)="close(true)">Ok</button>
  </div>
</div>