import { HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppointmentService } from './_services/appointment.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'app';

  constructor(
    public router: Router,
    private appointmentService: AppointmentService
  ) {

  }
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    try {
      let data = JSON.parse(event.data)
      const newUrl = data.path;
      this.appointmentService.pageUrl = data.pageUrl;
      this.appointmentService.gclid = data.gclid;
      this.appointmentService.referer = data.referer;
      if (data.formResponseId) {
        console.log(data.formResponseId);
        this.appointmentService.appointmentStutus(data.formResponseId);
      }
      if(data.leadPipelineId){
        this.appointmentService.leadPipelineId = data.leadPipelineId;
      }
      if (data.source)
        this.appointmentService.setSource(data.source);
      if (data.category)
        this.appointmentService.setCategory(data.category);
      if (newUrl && this.router.url != newUrl) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.appointmentService.routerPaths = [];
          this.router.navigate([newUrl]);
        });
      }
      // responseId
    } catch (e) {

    }

  }

  ngOnInit() {
    window.parent.postMessage(JSON.stringify({ eventType:'BOOK_APPOINTMENT_READY'}), '*');
  }
}
