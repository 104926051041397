import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import * as moment from "moment";
//import {default as _rollupMoment} from 'moment';

import { AppointmentService } from "../_services/appointment.service";
import { ProvidersService } from "../_services/providers.service";

import { MatCalendar } from '@angular/material/datepicker';
import { LocationsService } from "../_services/locations.service";
// import { time } from "console";
//const moment = _rollupMoment || _moment;


@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"],
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class CalendarComponent implements OnInit {
  @Input() rescheduleAppt: boolean;
  @Output() rescheduleDateSlected = new EventEmitter();
  minDate: any;
  maxDate: any;
  myFilter: any;
  selectedDate: any;
  isReady: boolean = false;
  calendar_settings: any;
  calendar_slug: string;
  acc_slug: string;
  appt_id: string;
  accId: string;
  groupType: string;
  providersName: string;
  location: string;
  providerId: string;
  providerLocData: any = [];
  calendars: any = [];
  providerName: string;
  showSummryForMobile: boolean = false
  contactId: any;
  isDebuging: boolean = false;

  device: string = "DESKTOP";
  constructor(
    public appointmentService: AppointmentService,
    public providerService: ProvidersService,
    private route: ActivatedRoute,
    private router: Router,
    public locationService: LocationsService

  ) { }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.device = 'MOBILE'
    }
    //this.appointmentService.getOldAppointmentDate();

    this.route.queryParams.subscribe(queryParams => {
      this.appt_id = queryParams.appt_id;

      let source = this.appointmentService.getSource();
      let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }
      if (category === undefined) {
        category =
          queryParams.category === undefined ? "" : queryParams.category;
        this.appointmentService.setCategory(category);
      }
      if (queryParams.multipleProviders !== undefined) {
        this.appointmentService.multipleProviders =
          queryParams.multipleProviders !== undefined ? true : false;
      }
      if ("contact" in queryParams) {
        console.log(queryParams);
        this.contactId = queryParams["contact"];
      }
      if ("debug" in queryParams) {
        this.isDebuging = (queryParams["debug"] === 'true')
      }

      this.appointmentService.multipleLocations =
        queryParams.multipleLocations !== undefined ? true : false;
      this.appointmentService.providerLocations =
        queryParams.providerLocations !== undefined ? true : false;
    });

    this.route.url.subscribe(url => {
      this.groupType = url[0].path;
      this.acc_slug = url[1].path;
      this.providerService.getAccId(this.acc_slug).then((docDetails) => {
        this.accId = docDetails.id;
        if (this.contactId != undefined) {
          this.appointmentService.setContactDetails(this.accId, this.contactId);
        }
      })
      this.appointmentService
        .getCalendarSettings(this.acc_slug, this.calendar_slug)
        .then(settings => {
          this.appointmentService.calendarRef = settings.docs[0].ref;
          this.appointmentService.setSettings(settings.docs[0].data());
          this.calendar_settings = settings.docs[0].data();
          console.log(this.calendar_settings)
          this.appointmentService.providerRef = this.calendar_settings.providerReference;
          this.appointmentService.setTimezone(
            this.calendar_settings["timezone"]
          );
          this.minDate = moment().add(
            this.calendar_settings["minDate"]["key"],
            this.calendar_settings["minDate"]["amount"]
          );
          this.maxDate = moment().add(
            this.calendar_settings["maxDate"]["key"],
            this.calendar_settings["maxDate"]["amount"]
          );
          this.myFilter = (d: Date): boolean => {
            const day = moment(d).day();
            // Filter out certain days.
            return (
              !this.calendar_settings["filterDays"].includes(day) &&
              !this.calendar_settings["blocked_dates"].includes(
                moment(d).format("Y-MM-DD")
              )
            );
          };
          // initialized date to '' everytime to fix the bug
          // where calendar does not navigate to timeslots if the date is unchanged.
          //let date = this.appointmentService.getSelectedDate();
          let date = "";
          if (date !== undefined) {
            this.selectedDate = date;
          } else {
            this.selectedDate = "";
          }
          this.appointmentService.providersName = undefined;
          if (this.appointmentService.providersName !== undefined) {
            this.providersName = this.appointmentService.providersName;
          } else {
            if (this.calendar_settings.providerReference !== undefined) {

              this.appointmentService.getProvidersName(this.calendar_settings.providerReference).then(provider => {
                this.providersName = provider;
              });
            }

          }
          this.isReady = true;
        });


    });

    this.route.queryParams.subscribe(queryParams => {
      //this.appt_id = queryParams['appt_id'];
      this.appt_id = queryParams.appt_id;
      let source = this.appointmentService.getSource();
      let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
        console.log(source, queryParams);
      }
      if (category === undefined) {
        category =
          queryParams.category === undefined ? "" : queryParams.category;
        this.appointmentService.setCategory(category);
      }

      if (queryParams.multipleProviders !== undefined) {
        this.appointmentService.multipleProviders =
          queryParams.multipleProviders !== undefined ? true : false;
      }


      this.appointmentService.multipleLocations =
        queryParams.multipleLocations !== undefined ? true : false;

      this.appointmentService.providerLocations =
        queryParams.providerLocations !== undefined ? true : false;
    });

    this.route.url.subscribe(url => {
      this.groupType = url[0].path;
      this.acc_slug = url[1].path;
      this.calendar_slug = url[2].path;

      //  this.appointmentService.fetchAppointmentDetails(this.acc_slug, this.appt_id);
      //this.acc_slug = 'dental-designer';
      //this.calendar_slug = 'dental-calendar';

      this.appointmentService
        .getCalendarSettings(this.acc_slug, this.calendar_slug)
        .then(settings => {
          this.appointmentService.calendarRef = settings.docs[0].ref;
          this.appointmentService.setSettings(settings.docs[0].data());

          this.location = this.appointmentService.getSettings().location.name;

          this.calendar_settings = settings.docs[0].data();
          this.appointmentService.setTimezone(
            this.calendar_settings["timezone"]
          );

          //to enable dates of calendar between specific date range
          if (this.calendar_settings['timeLimit'] !== undefined && this.calendar_settings['timeLimit']['enabled'] == true) {
            let timeInfo = this.calendar_settings['timeLimit'];
            let maxBookingDate = moment().add(
              this.calendar_settings["maxDate"]["key"],
              this.calendar_settings["maxDate"]["amount"]
            );
            let minBookingDate = moment().add(
              this.calendar_settings["minDate"]["key"],
              this.calendar_settings["minDate"]["amount"]
            );

            this.maxDate = moment(timeInfo['endDate'].toDate()).isAfter(maxBookingDate) ? maxBookingDate : moment(timeInfo['endDate'].toDate())
            this.minDate = moment(timeInfo['startDate'].toDate()).isBefore(minBookingDate) ? minBookingDate : moment(timeInfo['startDate'].toDate());
          }
          else {
            this.minDate = moment().add(
              this.calendar_settings["minDate"]["key"],
              this.calendar_settings["minDate"]["amount"]
            );

            this.maxDate = moment().add(
              this.calendar_settings["maxDate"]["key"],
              this.calendar_settings["maxDate"]["amount"]
            );
          }

          this.myFilter = (d: Date): boolean => {
            const day = moment(d).day();
            // Filter out certain days.
            return (
              !this.calendar_settings["filterDays"].includes(day) &&
              !this.calendar_settings["blocked_dates"].includes(
                moment(d).format("Y-MM-DD"))
            );
          };
          // initialized date to '' everytime to fix the bug
          // where calendar does not navigate to timeslots if the date is unchanged.
          //let date = this.appointmentService.getSelectedDate();
          let date = "";
          if (date !== undefined) {
            this.selectedDate = date;
          } else {
            this.selectedDate = "";
          }
          this.isReady = true;
        });
    });

    //this.appointmentService.getCalendar()
    /*    this.appointmentService.fetchSettings()
                           .then(
                             calendar => {

                                this.appointmentService.setSettings(calendar.data());
                                this.calendar_settings = calendar.data();
                                //console.log(this.calendar_settings);
                               this.appointmentService.setTimezone(this.calendar_settings['timezone']);
                               this.minDate = moment().add(this.calendar_settings['minDate']['key'], this.calendar_settings['minDate']['amount']);
                               this.maxDate = moment().add(this.calendar_settings['maxDate']['key'], this.calendar_settings['maxDate']['amount']);
                               this.myFilter = (d: Date): boolean => {
                                 const day = moment(d).day();
                                 // Filter out certain days.
                                 return !(this.calendar_settings['filterDays'].includes(day)) && !(this.calendar_settings['blocked_dates'].includes(moment(d).format('Y-MM-DD')));
                               };
                               // initialized date to '' everytime to fix the bug
                               // where calendar does not navigate to timeslots if the date is unchanged.
                               //let date = this.appointmentService.getSelectedDate();
                               let date = '';
                               if(date !== undefined) {
                                 this.selectedDate = date;
                               }
                               else {
                                 this.selectedDate = '';
                               }
                               this.isReady = true;
                             }
                         );*/
  }
  /*fetchAppointmentDetails()
  {
    this.appointmentService.fetchAppointmentDetails(this.acc_slug, this.appt_id)
    .then(details =>{
      console.log(details);
    });
  }*/

  setDate(event) {
    if (this.rescheduleAppt == true) {
      this.rescheduleDateSlected.emit({ date: event.date, slot: event.slot })
    }
  }



  goBack() {
    this.appointmentService.setSelectedDate({});
    // let navigateTO = setPathForBacktracking(this.acc_slug,this.groupType,this.group_slug);
    // this.router.navigateByUrl(navigateTO);
    if (this.appointmentService.multipleProviders) {
      this.router.navigateByUrl(
        `/${this.appointmentService.group_type}/${this.acc_slug}/${this.appointmentService.provider_slug}`
      );
    }
    else if (this.appointmentService.providerLocations) {
      this.router.navigateByUrl(
        `/provider-locations/${this.acc_slug}/${this.appointmentService.providerId}`
      );
    }
    else if (this.appointmentService.multipleLocations) {
      this.router.navigateByUrl(
        `/${this.appointmentService.group_type}/${this.acc_slug}/${this.appointmentService.group_slug}`
      );
    }
  }

  goBackTopreviousUrl() {
    this.appointmentService.routeback();
    this.appointmentService.rollingPeriodEnding = -1;
    this.appointmentService.rollingPeriodStarting = -1;
    this.appointmentService.stripCalenderEnding = -1;
    this.appointmentService.stripCalenderStarting = -1;
  }

  summaryShow(event) {
    console.log(event);
    this.showSummryForMobile = event
  }

}
