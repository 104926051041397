import { Injectable } from '@angular/core';
// declare var  onGooglePayLoaded:Function ;


@Injectable({
  providedIn: 'root'
})
export class GooglePaymentsService {

  constructor() { }
  loadGooglePay(){
    // let src = "https://pay.google.com/gp/p/js/pay.js"
    // console.log("preparing to load googlepay...");
    // let node = document.createElement("script");
    // node.id = "gidgetScript";
    // node.src = src;
    // node.onload=onGooglePayLoaded();
    // node.type = "text/javascript";
    // node.async = true;
    // node.charset = "utf-8";
    // document.getElementsByTagName("head")[0].appendChild(node);

  }
}
