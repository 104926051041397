<div class="row" id="header">
  <div class="col-xs-4">
    <div *ngIf="isItDev==false" class="devBadge">Demo</div>
  </div>
  <div class="col-xs-4">
    <div>
      <h3 class="messege">
        <span>
          {{appointmetService.accDetails.requestedAppointment?'Request Appointment':message?message:'Book Appointment'}}
        </span>
      </h3>
    </div>
  </div>
  <div class="col-xs-2">
  </div>
  <div class="col-xs-2" style="text-align: -webkit-right;display: contents;" *ngIf="!hideCloseIcon">
    <mat-icon class="crossIcon" (click)="closeWindow()">close</mat-icon>
  </div>

</div>