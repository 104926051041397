import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment-timezone";
import { AppointmentService } from '../_services/appointment.service';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-desktop-calendar',
  templateUrl: './desktop-calendar.component.html',
  styleUrls: ['./desktop-calendar.component.css']
})
export class DesktopCalendarComponent implements OnInit {

  @Input() rescheduleAppt: boolean;
  @Output() rescheduleDateSlected = new EventEmitter();
  @Input() isDebuging: boolean;
  selectedDate = -1;
  loading: boolean = true
  monthTitle: string = '';
  rollingWeeksArray: any = [];
  start = 0;
  end = 5;
  today = 0;
  maxDate: number;
  minDate: number;
  starting = 0;
  ending = 7;
  dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  blocked_dates: any = [];
  filterDays: any = [];
  setting: any = {};
  timeslots: any = [];
  timeslot_settings: any = {};
  bookedSlots: any;
  availableSlotsArray = [];
  apoointment_theme_setting: {};
  clinicNumberOfRooms = 1;
  clinicInterval = 1;
  weekArrayIndexing = [0, 1, 2, 3, 4, 5, 6]
  timeZone: string;
  slotLoading: boolean = true;
  device: string = "DESKTOP";
  slotAvailableFound: boolean = false
  constructor(
    public appointmentService: AppointmentService,
    public datepipe: DatePipe,
    private router: Router,
    private _location: Location
  ) { }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.device = 'MOBILE'
    }
    if (this.appointmentService.rollingPeriodEnding > 0) {
      this.ending = this.appointmentService.rollingPeriodEnding;
      this.starting = this.appointmentService.rollingPeriodStarting;
      this.setMonthTitle()
    }

    this.appointmentService
      .getCalendarSettings(this.appointmentService.acc_slug, this.appointmentService.calendar_slug)
      .then(settings => {
        this.appointmentService.setSettings(settings.docs[0].data());
        this.appointmentService.calendarRef = settings.docs[0].ref;
        this.timeslot_settings = settings.docs[0].data();
        this.appointmentService.providerRef = this.timeslot_settings.providerReference;
        this.setting = this.timeslot_settings
        this.appointmentService.setTimezone(this.timeslot_settings["timezone"])
        this.clinicNumberOfRooms = this.setting.clinicNumberOfRooms && this.appointmentService.accDetails.requestedAppointment ? this.setting.clinicNumberOfRooms : this.clinicNumberOfRooms;
        this.createRollingPeriodCalender();
      })
    this.timeZone = this.appointmentService.getLocalTimezoneAbbr()
  }

  createRollingPeriodCalender() {
    this.blocked_dates = this.setting.blocked_dates;
    this.filterDays = this.setting.filterDays;
    this.maxDate = this.setting.maxDate.key;
    this.minDate = this.setting.minDate.key;

    var day = moment().format('ddd, MMMM DD YYYY, H:MM')
    this.rollingWeeksArray.push(day);
    for (let i = 1; i <= (7 * this.maxDate) + 6; i++) {
      var newDay = moment(this.rollingWeeksArray[this.rollingWeeksArray.length - 1]).add(1, 'day').format('ddd, MMMM DD YYYY, H:MM')
      this.rollingWeeksArray.push(newDay);
    }
    this.setMonthTitle();
    this.loading = false;
    this.checkBookedSlot();


  }
  checkBookedSlot() {

    this.appointmentService.checkSlotsAvailabilityBetweenDateRange(this.rollingWeeksArray[this.starting == 0 ? 0 : this.starting - 1], this.rollingWeeksArray[this.ending == this.rollingWeeksArray.length ? this.ending - 1 : this.ending + 1]).then(async bookedSlots => {


      this.availableSlotsArray = [];

      for (let i = this.starting; i < this.ending; i++) {
        let oneDayTimeSlot: any = {};
        if (!this.isBlock(i)) {
          if (i == 0)
            oneDayTimeSlot = await this.findAvailabelSlotForToday(this.rollingWeeksArray[i], bookedSlots)
          else {
            oneDayTimeSlot = await this.findAvailabelSlot(this.rollingWeeksArray[i], bookedSlots)
          }
          this.availableSlotsArray.push({
            date: this.rollingWeeksArray[i],
            slots: oneDayTimeSlot.availableSlots,
            start: this.start,
            end: this.end,
            morebuttonTopMargin: "1em",
            bookedSlots: oneDayTimeSlot.bookedSlots,
            isBlock: false
          })
          if (oneDayTimeSlot.availableSlots.length > 0) {
            this.slotAvailableFound = true
          }

          if (this.device == 'MOBILE' && this.appointmentService.rollingPeriodEnding == -1 && oneDayTimeSlot.availableSlots.length > 0) {
            this.appointmentService.setSlotFoundIndex(i)
            break
          }
        } else {
          this.availableSlotsArray.push({
            date: this.rollingWeeksArray[i],
            slots: [],
            start: this.start,
            end: this.end,
            morebuttonTopMargin: "1em",
            bookedSlots: [],
            isBlock: true
          })
        }
      }
      // if (this.appointmentService.rollingPeriodEnding == -1) {
      //    this.setCalenderWeekWhenSlotNotAvalableInStartingDate()
      // }
      this.slotLoading = false
      if (this.slotAvailableFound == false && !(this.ending == this.rollingWeeksArray.length)) {
        this.weekIncrement()
      } else if (this.device == 'MOBILE' && this.appointmentService.rollingPeriodEnding == -1 && this.ending == this.rollingWeeksArray.length) {
        this.appointmentService.setSlotFoundIndex(this.starting)
      }

      if(this.ending == this.rollingWeeksArray.length && this.slotAvailableFound == false){
        this.slotAvailableFound = true
      }

    });
  }

  findAvailabelSlot(date, bookedSlots) {
    let bookedAppointmentToolTipArray: any = []
    let oneDayAvailableTimeSlot = [];
    let oneDayBookedTimeSlot = [];
    bookedSlots.forEach(slots => {
      if (moment(date, 'ddd, MMMM DD YYYY, H:MM').format('MMMM D, YYYY') == slots.date) {
        oneDayBookedTimeSlot.push(slots)
      }
    })
    date = moment(date);
    let day = date.day();

    this.timeslots = this.timeslot_settings["timeslots"][day];
    this.timeslots.slots.forEach(x => {
      let bookedAppointmentToolTip = ''
      let flag = 0;
      if (this.appointmentService.accDetails.requestedAppointment)
        oneDayBookedTimeSlot.forEach(y => {
          let apptStart = this.formatAMPM(y.appt_start.toDate())
          let apptEnd = this.formatAMPM(y.appt_end.toDate())
          let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
          if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
            flag = flag + 1;

            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      else
        oneDayBookedTimeSlot.forEach(y => {
          if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      let color = bookedAppointmentToolTip == '' ? '#8bc091' : flag < this.clinicNumberOfRooms ? '#e0c255' : '#dc7070';
      bookedAppointmentToolTip = bookedAppointmentToolTip == '' ? 'Available Slot' : bookedAppointmentToolTip
      bookedAppointmentToolTipArray.push({ tooltip: bookedAppointmentToolTip, color: color })
      x.localTime = this.appointmentService.convertToLocalTime(x.start)

      if (flag < this.clinicNumberOfRooms) {
        oneDayAvailableTimeSlot.push(x);
      } else {
        return false;
      }

    });
    if (!this.isDebuging)
      return { availableSlots: oneDayAvailableTimeSlot, bookedSlots: bookedAppointmentToolTipArray }
    else
      return { availableSlots: this.timeslots.slots, bookedSlots: bookedAppointmentToolTipArray }
  }

  formatAMPM(date) {
    // return moment(date).format("hh:mm a")
    return moment(date).tz(this.appointmentService.timezone).format("hh:mm a")
  }

  findAvailabelSlotForToday(date, bookedSlots) {
    let bookedAppointmentToolTipArray: any = []
    let oneDayAvailableTimeSlot = [];
    let oneDayBookedTimeSlot = [];
    bookedSlots.forEach(slots => {
      if (moment(date, 'ddd, MMMM DD YYYY, H:MM').format('MMMM D, YYYY') == slots.date) {
        oneDayBookedTimeSlot.push(slots)
      }
    })
    date = moment(date);
    let day = date.day();

    this.timeslots = this.timeslot_settings["timeslots"][day];

    this.timeslots.slots.forEach(x => {
      let flag = 0;
      let bookedAppointmentToolTip = ''
      if (this.appointmentService.accDetails.requestedAppointment)
        oneDayBookedTimeSlot.forEach(y => {
          let apptStart = this.formatAMPM(y.appt_start.toDate())
          let apptEnd = this.formatAMPM(y.appt_end.toDate())
          let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
          if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      else
        oneDayBookedTimeSlot.forEach(y => {
          if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      let color = bookedAppointmentToolTip == '' ? '#8bc091' : flag < this.clinicNumberOfRooms ? '#e0c255' : '#dc7070';
      bookedAppointmentToolTip = bookedAppointmentToolTip == '' ? 'Available Slot' : bookedAppointmentToolTip
      bookedAppointmentToolTipArray.push({ tooltip: bookedAppointmentToolTip, color: color })
      x.localTime = this.appointmentService.convertToLocalTime(x.start)
      if (flag < this.clinicNumberOfRooms) {
        oneDayAvailableTimeSlot.push(x);
      } else {
        return false;
      }

    });

    var currentTime = this.formatAMPM(new Date)
    let todaysAvailableSlot = [];
    oneDayAvailableTimeSlot.forEach(element => {

      var slotTime = moment(element.start, 'h:mma');
      var curTime = moment(currentTime, 'h:mma');
      if (!slotTime.isBefore(curTime)) {
        todaysAvailableSlot.push(element)
      }

    });
    if (!this.isDebuging)
      return { availableSlots: todaysAvailableSlot, bookedSlots: bookedAppointmentToolTipArray }
    else
      return { availableSlots: this.timeslots.slots, bookedSlots: bookedAppointmentToolTipArray }
  }


  weekIncrement() {
    if (!(this.ending == this.rollingWeeksArray.length) && !this.slotLoading) {
      this.starting = this.starting + 7;
      this.ending = this.ending + 7;
      this.setMonthTitle();
      this.slotLoading = true;
      this.checkBookedSlot()
    }

  }

  weekDeccrement() {
    if (!this.slotLoading) {
      if (this.starting != 0) {
        this.starting = this.starting - 7;
        this.ending = this.ending - 7;
        this.slotLoading = true;
        this.checkBookedSlot()
      }
      this.setMonthTitle();

    }
  }

  setMonthTitle() {
    var firstDayofWeek = new Date(this.rollingWeeksArray[this.starting]);
    var lastDayofWeek = new Date(this.rollingWeeksArray[this.ending - 1]);

    if (firstDayofWeek.toLocaleString('default', { month: 'long' }) == lastDayofWeek.toLocaleString('default', { month: 'long' }))
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    else
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    if (lastDayofWeek.getUTCFullYear() != firstDayofWeek.getUTCFullYear())
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + firstDayofWeek.getUTCFullYear() + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()
  }

  isShow(index) {
    if (index >= this.starting && index < this.ending)
      return true

    else
      return false
  }



  isBlock(index) {

    if (index > 7 * this.maxDate)
      return true

    if (index < this.minDate)
      return true

    for (let i = 0; i < this.filterDays.length; i++) {
      if (this.rollingWeeksArray[index].substring(0, 3) == this.dayOfWeek[this.filterDays[i]])
        return true
    }

    for (let i = 0; i < this.blocked_dates.length; i++) {

      if (moment(this.rollingWeeksArray[index], 'ddd, MMMM DD YYYY, H:MM').format('YYYY-MM-DD') == this.blocked_dates[i])
        return true
    }

    return false
  }


  onSlotSelect(i, j) {
    let selectedDateOnStrip = moment(this.availableSlotsArray[i].date);
    this.appointmentService.setSelectedDate(selectedDateOnStrip);
    this.appointmentService.setSelectedSlot(this.availableSlotsArray[i].slots[j]);
    this.appointmentService.rollingPeriodEnding = this.ending;
    this.appointmentService.rollingPeriodStarting = this.starting
    if (this.rescheduleAppt == true) {
      this.rescheduleDateSlected.emit({ date: selectedDateOnStrip, slot: this.availableSlotsArray[i].slots[j] })
    }
    else
      this.router.navigateByUrl("/confirm-appointment");
  }

  timeSlotShow(i) {
    let a = 5 - this.availableSlotsArray[i].slots.length % 5;
    if (this.availableSlotsArray[i].end == this.availableSlotsArray[i].slots.length) {
      this.availableSlotsArray[i].end = 5;
      this.availableSlotsArray[i].start = 0;
      a = 0
    }

    else if (this.availableSlotsArray[i].end + 5 < this.availableSlotsArray[i].slots.length) {
      this.availableSlotsArray[i].end = this.availableSlotsArray[i].end + 5;
      this.availableSlotsArray[i].start = this.availableSlotsArray[i].start + 5;
      a = 0
    }
    else {
      this.availableSlotsArray[i].start = this.availableSlotsArray[i].start + 5;
      this.availableSlotsArray[i].end = this.availableSlotsArray[i].slots.length;
    }


    switch (a) {
      case 0: this.availableSlotsArray[i].morebuttonTopMargin = "1em";
        break
      case 1: this.availableSlotsArray[i].morebuttonTopMargin = "3.95em";
        break
      case 2: this.availableSlotsArray[i].morebuttonTopMargin = "6.9em";
        break
      case 3: this.availableSlotsArray[i].morebuttonTopMargin = "9.85em";
        break
      case 4: this.availableSlotsArray[i].morebuttonTopMargin = "12.8em";
        break
    }

  }

  closeWindow() {
    window.parent.postMessage(JSON.stringify({ eventType: 'CLOSE' }), "*");
  }

  setCalenderWeekWhenSlotNotAvalableInStartingDate() {
    let slotFound: boolean = false;
    let firtNonBlockDate = -1
    for (let i = 0; i < this.availableSlotsArray.length; i++) {
      if (this.isBlock(i) == false) {
        firtNonBlockDate = firtNonBlockDate == -1 ? i : firtNonBlockDate
        if (this.availableSlotsArray[i].slots.length > 0) {
          slotFound = true
          let weekIndex = ~~(i / 7);
          this.appointmentService.setSlotFoundIndex(i)
          this.ending = (weekIndex + 1) * 7;
          this.starting = this.ending - 7;
          this.setMonthTitle()
        }
      }
      if (slotFound == true) {
        break
      }
    }
    if (slotFound == false) {
      this.appointmentService.setSlotFoundIndex(firtNonBlockDate)
    }
  }

  goBack() {
    this.appointmentService.routeback()
    this.appointmentService.rollingPeriodEnding = -1;
    this.appointmentService.rollingPeriodStarting = -1;
    this.appointmentService.stripCalenderEnding = -1;
    this.appointmentService.stripCalenderStarting = -1;
  }

  //below function is only for debugging purpose
  createDebugingModeCalendar() {

  }


  checkBookedSlot2() {
    this.appointmentService.checkSlotsAvailabilityBetweenDateRange(this.rollingWeeksArray[this.starting], this.rollingWeeksArray[this.ending]).then(async bookedSlots => {

      this.bookedSlots = bookedSlots;

      let bookSlotIdex = 0
      for (let i = this.starting; i < this.ending; i++) {
        let oneDayTimeSlot = [];
        for (let j = bookSlotIdex; j < this.bookedSlots; j++) {
          bookSlotIdex = j
          if (moment(this.rollingWeeksArray[i]).format('yyyy-MM-dd') != moment(this.bookedSlots[j].date).format('yyyy-MM-dd')) {
            let day = moment(this.rollingWeeksArray[i]).day();
            let availableSlots = this.timeslot_settings["timeslots"][day].slots
            for (let k = 0; k < availableSlots.length; k++) {
              let x = availableSlots[k]
              let y = this.bookedSlots[j]
              let apptStart = moment(this.bookedSlots[bookSlotIdex].appt_start.toDate()).tz(this.appointmentService.timezone).format("hh:mm a")
              let apptEnd = moment(this.bookedSlots[bookSlotIdex].appt_end.toDate()).tz(this.appointmentService.timezone).format("hh:mm a")

              let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
              if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
                // flag = flag + 1;

              }
            }
          } else {
            break
          }
        }

        this.availableSlotsArray.push({
          date: this.rollingWeeksArray[i],
          slots: oneDayTimeSlot,
          start: this.start,
          end: this.end,
          morebuttonTopMargin: "1em",
          isBlock: this.isBlock(i)
        })
      }
      if (this.appointmentService.rollingPeriodEnding == -1) {
        this.setCalenderWeekWhenSlotNotAvalableInStartingDate()
      }
      this.slotLoading = false
    });
  }
}


