import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";
import { ProvidersService } from "../_services/providers.service";
import { LocationsService } from "../_services/locations.service";
import { Provider, Location } from "../_models/interfaces";
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  acc_slug: string;
  accId: string;
  providers: Array<Provider> = [];
  locations: Array<Location> = [];
  isReady: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private locationService: LocationsService,
    private providerService: ProvidersService
  ) { }

  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.acc_slug = url[1].path;
      this.providerService.getAccId(this.acc_slug).then((docDetails) => {
        this.accId = docDetails.id;

        this.route.queryParams.subscribe((queryParams) => {
          if ("contact" in queryParams) {
            let contactId = queryParams["contact"];
            this.appointmentService.setContactDetails(this.accId, contactId);
          }
        });

        this.providerService.getProviders(this.accId).then((providers) => {
          this.providers = providers;
          this.locationService.getLocations(this.accId).then((locations) => {
            this.locations = locations;
            if (docDetails.showProviders === false) {
              this.appointmentService.setrouterActivity();
              this.router.navigate(["/locations", this.acc_slug]);
            } else if (
              this.providers.length == 1 &&
              this.locations.length == 1
            ) {
              this.appointmentService.setrouterActivity();
              this.router.navigate([
                "services",
                this.acc_slug,
                this.providers[0].slug,
                this.locations[0].slug,
              ]);
            } else if (
              this.locations.length > 1 &&
              this.providers.length == 1
            ) {
              this.appointmentService.setrouterActivity();
              this.router.navigate([
                "locations",
                this.acc_slug,
                this.providers[0].slug,
              ]);
            } else if (
              this.locations.length == 1 &&
              this.providers.length > 1
            ) {
              this.appointmentService.setrouterActivity();
              this.router.navigate([
                "providers",
                this.acc_slug,
                this.locations[0].slug,
              ]);
            } else {
              this.isReady = true;
            }
          });
        });
      });
    });
  }

}
