import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../_services/appointment.service';
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';

@Component({
  selector: 'app-accept-and-reject-appointment-request',
  templateUrl: './accept-and-reject-appointment-request.component.html',
  styleUrls: ['./accept-and-reject-appointment-request.component.css']
})
export class AcceptAndRejectAppointmentRequestComponent implements OnInit {
  isReady: boolean = false;
  reschedule_doc: string;
  redirectUrl: string;
  appointment_details: any;
  appt_status: string;
  showMsg: boolean = false;
  message: string;
  pastEvent: boolean = false;
  oldDate: any;
  acceptRejetct: boolean = false
  timeslot: any;
  updating: boolean = false
  status: string
  contactIfo: any = {}
  constructor(
    public rescheduleAppointmentService: RescheduleAppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      this.reschedule_doc = url[1].path;
      this.rescheduleAppointmentService.fetchAppointmentData(this.reschedule_doc)
        .then(appointment_details => {
          this.appointment_details = appointment_details;
          console.log(appointment_details)
          this.appt_status = this.appointment_details.status;
          this.contactIfo = this.appointment_details.contact_info
          this.oldDate = this.rescheduleAppointmentService.getOldAppointmentDate();
          this.timeslot = this.rescheduleAppointmentService.getOldAppointmentTime();
          let now = new Date();
          if ((this.appt_status == 'requested' && (this.oldDate >= now))) {
            this.acceptRejetct = true
          }
          else {
            this.showMsg = true;
            if (this.appt_status == 'accepted' && this.oldDate >= now) {
              this.message = "This appointment requested  has been already  accepted!";
            }
            else if (this.appt_status == 'rejected' && this.oldDate >= now) {
              this.message = "This appointment request has been already rejected!";
            }
            else {
              this.pastEvent = true;
              this.message = "You are trying to Accept/Reject a past event!";
            }
          }
          this.isReady = true
        });
    });
  }

  updateStatus(status) {
    this.updating = true
    this.rescheduleAppointmentService.appt_ref.update({ status: status }).then(resp => {
      this.status = status
      this.acceptRejetct = false
      console.log("Status updated", resp)
    })
  }


}
