import { Component, OnInit } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment-timezone";
import { AppointmentService } from '../../_services/appointment.service';
import { LocationsService } from '../../_services/locations.service';
import { ProvidersService } from '../../_services/providers.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import firebase from 'firebase/app';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-recurring-calendar',
  templateUrl: './recurring-calendar.component.html',
  styleUrls: ['./recurring-calendar.component.css']
})
export class RecurringCalendarComponent implements OnInit {
  calendar_settings: any;
  calendar_slug: string;
  acc_slug: string;
  groupType: string;
  location: string;
  recurringSetting: any = {};
  recurringSchedule: any = [];
  allSlotAreSelected: boolean = false;
  loading: boolean = true;
  contactId: any;
  contactInfo: any = {};
  hideCalendar: boolean = true
  showClaendar = 0;
  numberApend = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th'];
  myFilter: any

  constructor(
    public appointmentService: AppointmentService,
    public providerService: ProvidersService,
    private route: ActivatedRoute,
    private router: Router,
    public locationService: LocationsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar

  ) { }

  ngOnInit() {

    this.route.url.subscribe(url => {
      this.acc_slug = url[0].path;
      this.calendar_slug = url[2].path;
      this.contactId = url[3].path;


      this.appointmentService.getCalendarSettings(this.acc_slug, this.calendar_slug)
        .then(settings => {
          this.loading = false;
          this.appointmentService.calendarRef = settings.docs[0].ref;
          this.appointmentService.setSettings(settings.docs[0].data());
          this.location = this.appointmentService.getSettings().location.name;
          this.calendar_settings = settings.docs[0].data();
          this.appointmentService.setTimezone(this.calendar_settings["timezone"]);
          if (this.calendar_settings.providerReference)
            this.appointmentService.getProvidersName(this.calendar_settings.providerReference)
          this.recurringSetting = this.calendar_settings.recuringCalndarSetting;
          this.myFilter = (d: Date): boolean => {
            const day = moment(d).day();
            // Filter out certain days.
            return (
              !this.calendar_settings["filterDays"].includes(day) &&
              !this.calendar_settings["blocked_dates"].includes(
                moment(d).format("Y-MM-DD")
              )
            );
          };
          console.log(this.calendar_settings, this.recurringSetting)
          let oldSchedule = this.appointmentService.getRecurringSchedule();
          console.log(oldSchedule)
          if (oldSchedule.index >= 0) {
            this.recurringSchedule = oldSchedule.recurringSchedule;
            this.showClaendar = oldSchedule.index
          }
          else {
            this.createRecurringArray()
          }


          this.appointmentService.getContactDetails(this.contactId).
            then(contactInfo => {
              this.contactInfo = contactInfo.data()
              console.log(contactInfo.data())
            })

        });
    });
  }

  createRecurringArray() {
    this.recurringSchedule[0] = {
      minDate: new Date(),
      maxDate: new Date(this.createDate(new Date(), Number(this.recurringSetting.reccuringRollingPeriod))),
      dateSelected: false,
      date: {},
      selectedSlot: {},
      slotConfirm: false,
      selectedDate: {}
    }
    for (let i = 1; i < this.recurringSetting.numberOfVisit; i++) {
      this.recurringSchedule.push(
        {
          date: {},
          maxDate: {},
          minDate: {},
          dateSelected: false,
          selectedSlot: {},
          slotConfirm: false,
          selectedDate: {}
        }
      )
    }

  }

  onDateSeleted(event, i) {
    this.craeateRecuringSchedule(event, i)
    this.recurringSchedule[i].dateSelected = false;
    this.recurringSchedule[i].selectedDate = new Date(event);
    this.recurringSchedule[i].date = moment(event);
    this.recurringSchedule[i].selectedSlot = {};
    this.allSlotAreSelected = false;
    console.log(moment(event));
    console.log(i, this.recurringSchedule);
    this.recurringSchedule[i].dateSelected = true;
  }

  craeateRecuringSchedule(date, i) {
    if (i < this.recurringSetting.numberOfVisit - 1) {
      this.recurringSchedule[i + 1].minDate = new Date(this.createDate(date, Number(this.recurringSetting.recurringInterval.minDay)));
      this.recurringSchedule[i + 1].maxDate = new Date(this.createDate(date, Number(this.recurringSetting.recurringInterval.maxDay)));
      console.log(this.recurringSchedule[i + 1].minDate, this.recurringSchedule[i + 1].maxDate)
    }
  }

  createDate(date, interval) {
    let curr = new Date(date);
    var first = curr.getDate();// today date
    var day = new Date(curr.setDate(first)).toUTCString();
    var lastDay = new Date(day);
    var increseDay = lastDay.getDate();
    var newDate = new Date(lastDay.setDate(increseDay + interval)).toUTCString();
    return newDate;
  }

  onTimeSlotChange(slectedSlot) {
    this.recurringSchedule[slectedSlot.index].selectedSlot = slectedSlot.timeslot
    this.recurringSchedule[slectedSlot.index].slotConfirm = false
    console.log(this.recurringSchedule);
    this.checkAllTimeSlotAreSlected()
  }

  checkAllTimeSlotAreSlected() {
    this.allSlotAreSelected = false;
    if (this.recurringSchedule.length == this.recurringSetting.numberOfVisit) {
      let cnt = 0;
      for (let i = 0; i < this.recurringSetting.numberOfVisit; i++) {
        if (this.recurringSchedule[i].slotConfirm == true)
          cnt++;
      }
      if (cnt == this.recurringSetting.numberOfVisit)
        this.allSlotAreSelected = true;
    }
    console.log(this.allSlotAreSelected)
  }

  confirmSlot(index) {
    this.recurringSchedule[index].slotConfirm = true
    if (index < this.recurringSetting.numberOfVisit - 1) {
      this.showClaendar = index + 1
    }
    this.checkAllTimeSlotAreSlected()
  }

  changeSlot(index) {
    if (index < this.recurringSetting.numberOfVisit - 1) {
      if (index < this.recurringSetting.numberOfVisit && this.recurringSchedule[index + 1].selectedSlot.start) {
        let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            header: "Next all selected slot will be deleted. Are you sure?",
            type: 'change'
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result == true) {
            this.recurringSchedule[index].slotConfirm = false;
            this.recurringSchedule[index].selectedSlot = {};
            this.onDateSeleted(new Date(this.recurringSchedule[index].selectedDate), index)
            this.showClaendar = index;
            for (let j = index + 1; j < this.recurringSetting.numberOfVisit; j++) {
              this.recurringSchedule[j].slotConfirm = false;
              this.recurringSchedule[j].selectedSlot = {};
              this.recurringSchedule[j].date = {};
              this.recurringSchedule[j].dateSelected = false
              this.recurringSchedule[j].selectedDate = {};
            }
            this.checkAllTimeSlotAreSlected()
          }

        });
      }
      else {
        this.recurringSchedule[index].slotConfirm = false;
        this.recurringSchedule[index].selectedSlot = {};
        this.showClaendar = index;
      }
    }
    else {
      this.recurringSchedule[index].slotConfirm = false;
      this.recurringSchedule[index].selectedSlot = {};
      this.showClaendar = index;
    }
    this.checkAllTimeSlotAreSlected()
    console.log(this.showClaendar, index)
  }

  async confirmAppointment() {
    this.hideCalendar = true;
    this.loading = true
    let acc_ref = this.appointmentService.acc_ref
    let transactionIDArray = []
    for (let j = 0; j < this.recurringSchedule.length; j++) {
      transactionIDArray.push(this.appointmentService.acc_ref.collection("appointments").doc())
    }
    console.log(transactionIDArray)

    let slotNotAvailable = 0
    let contactRef = acc_ref.collection("contacts").doc(this.contactId);
    try {
      const res = await firebase.firestore().runTransaction(async transaction => {

        for (let i = 0; i < this.recurringSchedule.length; i++) {
          let element = this.recurringSchedule[i]

          await this.appointmentService.confirmAppointmentAvailable(element.date, element.selectedSlot).then(async (available) => {
            if (available) {
              await transaction.set(transactionIDArray[i], {
                booked_on: moment().toDate(),
                date: element.date.format("MMMM D, YYYY"),
                slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`,
                appt_start: moment
                  .tz(
                    `${element.date.format("YYYY-MM-DD")} ${element.selectedSlot.start
                    }`,
                    "YYYY-MM-DD h:mm a",
                    this.appointmentService.getTimezone()
                  )
                  .toDate(),
                appt_end: moment
                  .tz(
                    `${element.date.format("YYYY-MM-DD")} ${element.selectedSlot.end
                    }`,
                    "YYYY-MM-DD h:mm a",
                    this.appointmentService.getTimezone()
                  )
                  .toDate(),
                contact: contactRef,
                location: this.location,
                notes: '',
                calendarReference: this.appointmentService.calendarRef,
                status: 'booked',
                contact_info: {
                  email: this.contactInfo.email,
                  first_name: this.contactInfo.first_name,
                  last_name: this.contactInfo.last_name,
                  phone: this.contactInfo.phone,
                },
                source: 'website'
              });
            }


            else {
              let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                  header: "Your " + this.numberApend[i] + "  Follow Up visit slot is alredy booked.You should have to Book all appointments from " + this.numberApend[i] + " Follow Up visit",
                  type: 'reboook'
                }
              });
              slotNotAvailable++;

              dialogRef.afterClosed().subscribe(result => {
                this.recurringSchedule[i].slotConfirm = false;
                this.recurringSchedule[i].selectedSlot = {};

                this.showClaendar = i;
                this.loading = false
                this.checkAllTimeSlotAreSlected()
                for (let j = i + 1; j < this.recurringSchedule.length; j++) {
                  this.recurringSchedule[j].slotConfirm = false;
                  this.recurringSchedule[j].selectedSlot = {};
                  this.recurringSchedule[j].date = {};
                  this.recurringSchedule[j].dateSelected = false
                  this.recurringSchedule[j].selectedDate = {};
                }
              });
            }

          });
          if (slotNotAvailable > 0)
            break
        }
        if (slotNotAvailable > 0)
          return Promise.reject('Appointment Slot not available"');
        else
          return Promise.resolve("transction Succesful")
      }).then(async resp => {
        await this.appointmentService.createrecuringAppointmentsReferenceDtails(contactRef, transactionIDArray)
        this.appointmentService.sendFollowUpAppointmentEmailToOwner(this.contactInfo, this.recurringSchedule)
        this.appointmentService.sendFollowUpAppointmentEmailToUser(this.contactInfo, this.recurringSchedule)
        console.log(resp)
        this._snackBar.open(" All Saved Appointmet Succesfully", "ok", { verticalPosition: 'top' });
        this.router.navigate(['recurring/success'])
      })

    } catch (e) {
      console.log('Transaction failure:', e);
    }

  }


  back() {
    this.hideCalendar = true;
  }

  goBack() {
    this.appointmentService.routeback()
  }



}
