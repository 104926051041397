import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from "../../environments/environment";
import { AppointmentService } from '../_services/appointment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  message: string;
  hideCloseIcon: boolean
  isItIframe: boolean;
  isItDev: boolean

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public appointmetService: AppointmentService
  ) {
    if (window !== window.parent) {
      console.log(" The page is in an iframe ")
      this.isItIframe = true;
    }
    else {
      console.log(" The page is not  in an iframe ")
      this.isItIframe = false;
    }
  }

  ngOnInit() {
    if (!this.isItIframe)
      this.hideCloseIcon = true;
    if (this.router.url.includes('/reschedule-calendar') ||
      this.router.url.includes('/reschedule-timeslots') ||
      this.router.url.includes('/reschedule-confirm-appointment') ||
      this.router.url.includes('/reschedule')) {
      this.message = "Reschedule Appointment";
    }

    if (this.router.url.includes('/appointment-request')) {
      this.message = "Appointment Request";
    }

    if (this.router.url.includes('/cancel')) {
      this.message = "Cancel Appointment";
    }
    this.isItDev = environment.production
    this.route.queryParams.subscribe((routes) => {
      console.log(routes);
      if ('hidecloseicon' in routes) {
        if (routes.hidecloseicon.toLowerCase() == "true") {
          this.hideCloseIcon = true;
        }
        else {
          this.hideCloseIcon = false;
        }
      }
    });
  }

  closeWindow() {
    window.parent.postMessage(JSON.stringify({ eventType: 'CLOSE' }), "*");
  }
}
