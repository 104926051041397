<div class="container-fluid top-space">
  <div *ngIf="isReady; then ready else notReady"></div>
  <ng-template #ready>

    <div *ngIf="locations.length!==0" class="row" id="subHeader">
      <div class="col-md-4">
      </div>
      <div class="col-md-4" style="text-align: center;">
        <b>Start with a Provider or
          Location</b>
      </div>
      <div class="col-md-4">
      </div>

    </div>
    <!-- <p  class="center" style=" background-color: #f1f4f6;"></p> -->
    <div id="card-container-desk" *ngIf="locations.length>1 && providers.length>1">
      <div class="card-block plan">
        <mat-card>
          <div>
            <a [routerLink]="['/providers', acc_slug]" title="Click to show providers">
              <h4>Providers </h4>
            </a>
          </div>
        </mat-card>
      </div>
      <div class="card-block plan">
        <mat-card>
          <div class="center">
            <a [routerLink]="['/locations', acc_slug]" title="Click to show locations">
              <h4> Locations </h4>
            </a>
          </div>
        </mat-card>
      </div>
    </div>

    <div id="card-container-mobile" *ngIf="locations.length>1 && providers.length>1">
      <div class="column">
        <div class="card-block plan">
          <mat-card>
            <div>
              <a [routerLink]="['/providers', acc_slug]" title="Click to show providers">
                <h4>Providers </h4>
              </a>
            </div>
          </mat-card>
        </div>
        <div class="card-block plan">
          <mat-card>
            <div class="center">
              <a [routerLink]="['/locations', acc_slug]" title="Click to show locations">
                <h4> Locations </h4>
              </a>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="footer">
    <app-footer [back]="false" [summary]="false" [next]="false">
    </app-footer>
  </div>
  <ng-template #notReady>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
</div>