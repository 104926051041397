export const environment = {
  production: false,
  isDebugMode: true,
  mktg_firebase: {
    apiKey: "AIzaSyDESCcJfBOKIPXA0Sxt3VEV1GXyTcVSzQU",
    authDomain: "mktgbot-181017.firebaseapp.com",
    databaseURL: "https://mktgbot-181017.firebaseio.com",
    projectId: "mktgbot-181017",
    storageBucket: "mktgbot-181017.appspot.com",
    messagingSenderId: "797002870076"
  },
  appt_firebase: {
    apiKey: "AIzaSyDESCcJfBOKIPXA0Sxt3VEV1GXyTcVSzQU",
    authDomain: "mktgbot-181017.firebaseapp.com",
    databaseURL: "https://mktgbot-181017.firebaseio.com",
    projectId: "mktgbot-181017",
    storageBucket: "mktgbot-181017.appspot.com",
    messagingSenderId: "797002870076",
    cloudFunctionDomain: "https://us-central1-mktgbot-181017.cloudfunctions.net"
  },
  calendar_settings_id: "m1ckFp5jUlPgJIkYNfCo",
  clientURL: "https://appointmentdev.herokuapp.com",
  paytmServerDomain: "https://securegw-stage.paytm.in",
  patientFormUrl: "https://patient-forms-prod.herokuapp.com",
  // paytmServerDomain:"https://securegw.paytm.in", //for checking live payments
  // 
  serverURL: "https://mktg-bot-dev-server.herokuapp.com",
  cloudFunctionDomain: "https://us-central1-mktgbot-181017.cloudfunctions.net"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.  