<div *ngIf="isReady">
  <div *ngIf="oldDate != undefined;else pleasSelectDate">
    <p class="highlight">Please select Date and Time to Reschedule <br> {{oldDate | date}} {{oldTimeSlot}}</p>
  </div>
  <ng-template #pleasSelectDate>
    <p class="highlight">Please Select Date and Time</p>
  </ng-template>
  <div class="toolbar">
    <div class="tootlbarRow1">
      <h4>
        {{monthTitle}}
      </h4>
    </div>
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <div class="flex-container">
          <div class="arrow-padding">
            <button (click)="weekDeccrement()" [disableRipple]="true" mat-icon-button [disabled]="starting==0"
              aria-label="Example icon-button with menu icon">
              <span class="material-icons arrow" [style.color]="starting==0 ? '#cbc5bc' : 'black'">
                keyboard_arrow_left
              </span>
            </button>
          </div>
          <ng-container *ngFor="let day of rollingWeeksArray;let i = index;">
            <div *ngIf="isShow(i)" (click)="onDateChange(i)" style="cursor: pointer"
              [ngStyle]="isBlock(i)==true ?{'pointer-events': 'none','opacity': '0.7;','color':'#d5cbcb'}:{'color':'black'}">
              <div id="dayDisplay">
                {{day.substring(0,3) | uppercase }}
              </div>
              <div style="padding-top: 0.5em;" [ngClass]="{'highlight-date': selectedDate === i}">
                {{day| date:'dd'}}
              </div>
            </div>
          </ng-container>
          <div class="arrow-padding">
            <button mat-icon-button (click)="weekIncrement()" [disableRipple]="true"
              aria-label="Example icon-button with menu icon">
              <span class="material-icons arrow"
                [style.color]="(rollingWeeksArray.length-ending)>=5 ? 'black':'#cbc5bc'">
                keyboard_arrow_right
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</div>