<div style="background-color: white;">


  <ng-container *ngIf="bookAnotherAppointment && settings.hideBookAnotherButton!=true">
    <div class="container text-center" id="bookAnotherAptButton">
      <button type="button" style="margin-bottom: -4em;" (click)="bookAnotherAppointmentClick()" class="btn">Book
        Another
        Appointment</button>
    </div>
  </ng-container>


  <div class="grid-container1" *ngIf="appointmentService.routerPaths.length<2;else goFooter">
    <div class="col-xs-12" style="text-align: center;">
      <button *ngIf="summary" type="button" class="btn" (click)="summaryClick()">
        <span> Summary</span>

        <span *ngIf="summaryArrow =='up'" style="vertical-align: bottom;
    font-size: 20px;" class="material-icons">
          keyboard_arrow_up
        </span>

        <span *ngIf="summaryArrow == 'down'" style="vertical-align: bottom;
    font-size: 20px;" class="material-icons">
          keyboard_arrow_down
        </span>
      </button>
    </div>
  </div>
  <ng-template #goFooter>
    <div class="grid-container">
      <span style="text-align: left;">
        <button *ngIf="back" type="button" class="btn" (click)="backButtonClick()">Back</button>
      </span>

      <span *ngIf="confirm || next;else nextButtonNotAvailable" style="text-align: center;">
        <button *ngIf="summary" type="button" class="btn" (click)="summaryClick()">
          <span> Summary</span>

          <span *ngIf="summaryArrow =='up'" style="cursor: pointer;vertical-align: bottom;
      font-size: 20px;" class="material-icons">
            keyboard_arrow_up
          </span>

          <span *ngIf="summaryArrow == 'down'" style="cursor: pointer;vertical-align: bottom;
      font-size: 20px;" class="material-icons">
            keyboard_arrow_down
          </span>
        </button>
      </span>
      <ng-template #nextButtonNotAvailable>
        <span style="text-align: inherit;">
          <button *ngIf="summary" type="button" class="btn" (click)="summaryClick()">
            <span> Summary</span>

            <span *ngIf="summaryArrow =='up'" style="cursor: pointer;vertical-align: bottom;
      font-size: 20px;" class="material-icons">
              keyboard_arrow_up
            </span>

            <span *ngIf="summaryArrow == 'down'" style="cursor: pointer;vertical-align: bottom;
      font-size: 20px;" class="material-icons">
              keyboard_arrow_down
            </span>
          </button>
        </span>
      </ng-template>
      <span style="text-align: right;">
        <button *ngIf="next" type="button" [disabled]="nextBtnDisabled" (click)="nextBtnClick()"
          class="btn">Next</button>
        <button *ngIf="confirm" type="button" [disabled]="nextBtnDisabled" (click)="nextBtnClick()" class="btn">Confirm
          <mat-icon *ngIf="!summary">
            <mat-spinner diameter="18"></mat-spinner>
          </mat-icon>
        </button>
      </span>

    </div>
  </ng-template>
</div>