<div *ngIf="showSummryForMobile==true; " class="mobile-view-summary">
  <app-appointment-details></app-appointment-details>
</div>
<div id="term-condtion-page" class="center">

  <div id="deskView">

    <div class="row">
      <div class="row" id="subHeader">
        <div class="col-xs-4">
          <span style="cursor: pointer;margin-top: -15px;" class="material-icons arrow"
            [routerLink]="['/confirm-appointment']">
            keyboard_arrow_left
          </span>
          <a [routerLink]="['/confirm-appointment']">Edit Contact Details</a>
        </div>
        <div style="text-align: center;" class="col-xs-4">
          <b> Term and Conditions</b>
        </div>
        <div class="col-xs-4">

        </div>
      </div>
    </div>

  </div>
  <div id="mobileView">
    <div class="col-xs-12" id="subHeader">
      <div class="col-xs-12" style="font-size:large;text-align: center;">
        <b>Term and Conditions</b>
      </div>
    </div>
  </div>
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-9 " id="term_condtion">
          <div style="margin-top: 2em;padding-left: 1em;font-size: large; overflow-y:auto;">
            <div [innerHTML]="terms">
            </div>
            <div id="mobileView-geement">
              <span>
                <mat-checkbox [(ngModel)]="agree" class="highlight">I Agree</mat-checkbox>
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-3" id="summary">
          <app-appointment-details></app-appointment-details>
        </div>
      </div>






      <div id="footerOfDeskview" class="row" style="width:96%;">
        <div class="col-md-12 text-center">
          <div class="col-md-9">
            <span>
              <mat-checkbox [(ngModel)]="agree" class="highlight">I Agree</mat-checkbox>
            </span>
          </div>
          <div class="col-md-3">
            <div id="confirmButton">
              <button *ngIf="isConfirm" mat-raised-button class="btn" type="button" [disabled]="!agree"
                (click)="confirmAppointment(); apptCnf.disabled=true; " #apptCnf>
                Confirm
                <mat-icon *ngIf="disableLink">
                  <mat-spinner diameter="20"></mat-spinner>
                </mat-icon>
              </button>
              <button *ngIf="!isConfirm" mat-raised-button class="btn" type="button" (click)="redirectTo()"
                [disabled]="!agree">
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="footer">
  <app-footer [back]="!disableLink" [summary]="!disableLink" [next]="!isConfirm" [confirm]="isConfirm"
    [showConfirm]="showConfirm" [nextBtnDisabled]="!agree || disableLink" (summaryShow)="summaryShow($event)"
    (goBack)="goBack()" (nextButtonClick)="nextButtonOfFooteClick()">
  </app-footer>
</div>