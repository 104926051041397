import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { MatRadioModule } from '@angular/material/radio';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../environments/environment';
import { CalendarComponent } from './calendar/calendar.component';
import { TimeslotComponent } from './timeslot/timeslot.component';
import { AppointmentService } from './_services/appointment.service';
import { RescheduleAppointmentService } from './_services/reschedule-appointment.service';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { AppointmentStatusComponent } from './appointment-status/appointment-status.component';
import { CalendarGroupsComponent } from './calendar-groups/calendar-groups.component';

import firebase from 'firebase/app';
import { RescheduleAppointmentComponent } from './reschedule-appointment/reschedule-appointment.component';
import { RescheduleCalendarComponent } from './reschedule-calendar/reschedule-calendar.component';
import { RescheduleCalendarGroupsComponent } from './reschedule-calendar-groups/reschedule-calendar-groups.component';
import { RescheduleTimeslotComponent } from './reschedule-timeslot/reschedule-timeslot.component';
import { RescheduleAppointmentConfirmationComponent } from './reschedule-appointment-confirmation/reschedule-appointment-confirmation.component';
import { RescheduleAppointmentStatusComponent } from './reschedule-appointment-status/reschedule-appointment-status.component';
import { CancelAppointmentComponent } from './cancel-appointment/cancel-appointment.component';
import { CandeactivateGuard } from './guards/candeactivate/candeactivate.guard';
import { CalendarProvidersGroupComponent } from './calendar-providers-group/calendar-providers-group.component';
import { ProviderLocationsGroupComponent } from './provider-locations-group/provider-locations-group.component';
import { RescheduleProviderGroupComponent } from './reschedule-provider-group/reschedule-provider-group.component';
import { RescheduleProviderLocationgroupComponent } from './reschedule-provider-locationgroup/reschedule-provider-locationgroup.component';
import { ProvidersComponent } from './providers/providers.component';
import { LocationsComponent } from './locations/locations.component';
import { ServicesComponent } from './services/services.component';
import { AccountComponent } from './account/account.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaytmPaymentProcessingComponent } from './paytm-payment-processing/paytm-payment-processing.component';
import { PaypalPaymentComponent } from './paypal-payment/paypal-payment.component';
import { PaymentsProcessingComponent } from './payments-processing/payments-processing.component';
import { GooglePayPaymentsComponent } from './google-pay-payments/google-pay-payments.component';
import { StripClenderComponent } from './strip-clender/strip-clender.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DatePipe } from '@angular/common';
import { TrialComponent } from './trial/trial.component';
import { RollingPeriodTimeSlotCalenderComponent } from './rolling-period-time-slot-calender/rolling-period-time-slot-calender.component';
import { RecurringCalendarComponent } from './recurring-appointment/recurring-calendar/recurring-calendar.component';
import { RecuuringTimeslotsComponent } from './recurring-appointment/recuuring-timeslots/recuuring-timeslots.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { RecurringConactDetailsComponent } from './recurring-appointment/recurring-conact-details/recurring-conact-details.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './recurring-appointment/confirmation-dialog/confirmation-dialog.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { HeaderComponent } from './header/header.component';
import { ShowRecurringSelectedTimeslotComponent } from './recurring-appointment/show-recurring-selected-timeslot/show-recurring-selected-timeslot.component';
import { FooterComponent } from './footer/footer.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OTPVerificationComponent } from "./otp-verification/otp-verification.component";
import { RebookRecuuringAppointmentComponent } from './recurring-appointment/rebook-recuuring-appointment/rebook-recuuring-appointment.component';
import { ReccuringRescheduleComponentComponent } from './reccuring-reschedule-component/reccuring-reschedule-component.component';
import { ReccuringRescheduleDifferenceComponent } from './reccuring-reschedule-difference/reccuring-reschedule-difference.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SucessComponent } from './sucess/sucess.component';
import { NgOtpInputModule } from 'ng-otp-input';

import { CancelRecurringAppointmentComponent } from './cancel-recurring-appointment/cancel-recurring-appointment.component';
import { RecuuringSelectCalenderComponent } from './recuuring-select-calender/recuuring-select-calender.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AcceptAndRejectAppointmentRequestComponent } from './accept-and-reject-appointment-request/accept-and-reject-appointment-request.component';
const appt_firebase = firebase.initializeApp(environment.appt_firebase, 'appt_firebase');
import { MatMenuModule } from "@angular/material/menu";
import * as Sentry from "@sentry/angular";
import { DesktopCalendarComponent } from './desktop-calendar/desktop-calendar.component';

const appt_firestore = appt_firebase.firestore();
const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

const appRoutes: Routes = [
  { path: '', component: CalendarComponent },
  { path: 'calendar-groups/:acc_slug/:group_slug', component: CalendarGroupsComponent },
  { path: 'providers-groups/:acc_slug/:provider_slug', component: CalendarProvidersGroupComponent },
  { path: 'provider-locations/:acc_slug/:provider_slug', component: ProviderLocationsGroupComponent },
  { path: 'calendar/:acc_slug/:calendar_slug', component: CalendarComponent },
  { path: 'timeslots', component: TimeslotComponent },
  { path: 'confirm-appointment', component: AppointmentConfirmationComponent },
  { path: 'appointment-status', component: AppointmentStatusComponent },
  { path: 'reschedule/:id', component: RescheduleAppointmentComponent },
  { path: 'reschedule-calendar/:acc_slug/:calendar_slug', component: RescheduleCalendarComponent },
  { path: 'reschedule-calendar-groups/:acc_slug/:group_slug', component: RescheduleCalendarGroupsComponent },
  { path: 'reschedule-provider-locations/:acc_slug/:group_slug', component: RescheduleProviderLocationgroupComponent },
  { path: 'reschedule-provider-groups/:acc_slug/:group_slug', component: RescheduleProviderGroupComponent },
  { path: 'reschedule-timeslots', component: RescheduleTimeslotComponent },
  { path: 'reschedule-confirm-appointment', component: RescheduleAppointmentConfirmationComponent },
  { path: 'reschedule-appointment-status', component: RescheduleAppointmentStatusComponent },
  { path: 'cancel/:id', component: CancelAppointmentComponent },
  { path: 'providers/:acc_slug', component: ProvidersComponent },
  { path: 'providers/:acc_slug/:location', component: ProvidersComponent },
  { path: 'locations/:acc_slug/:provider', component: LocationsComponent },
  { path: 'locations/:acc_slug', component: LocationsComponent },
  { path: 'services/:acc_slug/:provider_slug/:location_slug', component: ServicesComponent },
  { path: 'account/:acc_slug', component: AccountComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'payments/:acc_slug/:calendar_slug', component: PaymentsProcessingComponent },
  { path: 'payments', component: PaymentsComponent },
  { path: 'paytm-payments/:acc_slug/:calendar_slug', component: PaytmPaymentProcessingComponent },
  { path: 'gpay-payments', component: GooglePayPaymentsComponent },
  { path: 'strip-calender', component: StripClenderComponent },
  { path: 'trial', component: TrialComponent },
  { path: 'appointment-request/:id', component: AcceptAndRejectAppointmentRequestComponent },
  { path: 'recurring', loadChildren: () => import('./recurring-appointment/recurring-appointment.module').then(m => m.RecurringAppointmentModule) },
];
@NgModule({
  declarations: [
    AppComponent,
    CalendarComponent,
    TimeslotComponent,
    AppointmentConfirmationComponent,
    AppointmentStatusComponent,
    CalendarGroupsComponent,
    RescheduleAppointmentComponent,
    RescheduleCalendarComponent,
    RescheduleCalendarGroupsComponent,
    RescheduleTimeslotComponent,
    RescheduleAppointmentConfirmationComponent,
    RescheduleAppointmentStatusComponent,
    CancelAppointmentComponent,
    CalendarProvidersGroupComponent,
    ProviderLocationsGroupComponent,
    RescheduleProviderGroupComponent,
    RescheduleProviderLocationgroupComponent,
    ProvidersComponent,
    LocationsComponent,
    ServicesComponent,
    AccountComponent,
    TermsAndConditionsComponent,
    PaymentsComponent,
    PaytmPaymentProcessingComponent,
    PaypalPaymentComponent,
    PaymentsProcessingComponent,
    GooglePayPaymentsComponent,
    StripClenderComponent,
    TrialComponent,
    RollingPeriodTimeSlotCalenderComponent,
    RecurringCalendarComponent,
    OTPVerificationComponent,
    RecuuringTimeslotsComponent,
    RecurringConactDetailsComponent,
    ConfirmationDialogComponent,
    AppointmentDetailsComponent,
    HeaderComponent,
    ShowRecurringSelectedTimeslotComponent,
    FooterComponent,
    RebookRecuuringAppointmentComponent,
    ReccuringRescheduleComponentComponent,
    ReccuringRescheduleDifferenceComponent,
    SucessComponent,
    CancelRecurringAppointmentComponent,
    RecuuringSelectCalenderComponent,
    AcceptAndRejectAppointmentRequestComponent,
    DesktopCalendarComponent,

    // FontAwesomeModule
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: "reload" }),
    NgxMaskModule.forRoot(maskConfigFunction),
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    HttpClientModule,
    // AngularFireModule.initializeApp(environment.mktg_firebase),
    // AngularFireDatabaseModule,
    // AngularFireAuthModule,
    // AngularFirestoreModule,
    MatToolbarModule,
    MatTableModule,
    MatDialogModule,
    FontAwesomeModule,
    MatSnackBarModule,
    MatTooltipModule,
    NgOtpInputModule,
    MatMenuModule
  ],
  providers: [
    AppointmentService,
    RescheduleAppointmentService,
    CandeactivateGuard,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    //CalendarService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  // entryComponents: [ConfirmationDialogComponent]
})
export class AppModule { }
