import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from "moment-timezone";
import { CalendarComponent } from '../calendar/calendar.component';
import { AppointmentService } from '../_services/appointment.service';
import { DatePipe } from '@angular/common'
import { Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-rolling-period-time-slot-calender',
  templateUrl: './rolling-period-time-slot-calender.component.html',
  styleUrls: ['./rolling-period-time-slot-calender.component.css']
})
export class RollingPeriodTimeSlotCalenderComponent implements OnInit {
  @Input() rescheduleAppt: boolean;
  @Output() rescheduleDateSlected = new EventEmitter();
  @Input() isDebuging: boolean;
  selectedDate = -1;
  loading: boolean = true
  monthTitle: string = '';
  rollingWeeksArray: any = [];
  start = 0;
  end = 5;
  today = 0;
  maxDate: number;
  minDate: number;
  starting = 0;
  ending = 7;
  dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  blocked_dates: any = [];
  filterDays: any = [];
  setting: any = {};
  timeslots: any = [];
  timeslot_settings: any = {};
  bookedSlots: any;
  availableSlotsArray = [];
  apoointment_theme_setting: {};
  clinicNumberOfRooms = 1;
  clinicInterval = 1;
  weekArrayIndexing = [0, 1, 2, 3, 4, 5, 6]
  timeZone: string;
  constructor(
    public appointmentService: AppointmentService,
    public datepipe: DatePipe,
    private router: Router,
    private _location: Location
  ) { }

  ngOnInit() {

    if (this.appointmentService.rollingPeriodEnding > 0) {
      this.ending = this.appointmentService.rollingPeriodEnding;
      this.starting = this.appointmentService.rollingPeriodStarting;
      this.setMonthTitle()
    }

    this.appointmentService
      .getCalendarSettings(this.appointmentService.acc_slug, this.appointmentService.calendar_slug)
      .then(settings => {
        this.appointmentService.setSettings(settings.docs[0].data());
        this.appointmentService.calendarRef = settings.docs[0].ref;
        this.timeslot_settings = settings.docs[0].data();
        this.appointmentService.providerRef = this.timeslot_settings.providerReference;
        this.setting = this.timeslot_settings
        this.appointmentService.setTimezone(
          this.timeslot_settings["timezone"])
        this.createRollingPeriodCalender();
        this.clinicNumberOfRooms = this.setting.clinicNumberOfRooms && this.appointmentService.accDetails.requestedAppointment ? this.setting.clinicNumberOfRooms : this.clinicNumberOfRooms;
      })
    this.timeZone = this.appointmentService.getLocalTimezoneAbbr()
  }

  createRollingPeriodCalender() {
    this.blocked_dates = this.setting.blocked_dates;
    this.filterDays = this.setting.filterDays;
    this.maxDate = this.setting.maxDate.key;
    this.minDate = this.setting.minDate.key;

    var day = moment().format('ddd, MMMM DD YYYY, H:MM')
    this.rollingWeeksArray.push(day);
    for (let i = 1; i <= (0 * this.maxDate) + 6; i++) {
      var newDay = moment(this.rollingWeeksArray[this.rollingWeeksArray.length - 1]).add(1, 'day').format('ddd, MMMM DD YYYY, H:MM')
      this.rollingWeeksArray.push(newDay);
    }
    this.setMonthTitle();
    this.checkBookedSlot();


  }
  checkBookedSlot() {
    this.appointmentService.checkSlotsAvailabilityBetweenDateRange(this.rollingWeeksArray[0], this.rollingWeeksArray[this.rollingWeeksArray.length - 1]).then(async bookedSlots => {

      this.bookedSlots = bookedSlots;


      for (let i = 0; i < this.rollingWeeksArray.length; i++) {
        let oneDayTimeSlot: any = {};
        if (i == 0)
          oneDayTimeSlot = await this.findAvailabelSlotForToday(this.rollingWeeksArray[i])
        else {
          debugger
          oneDayTimeSlot = await this.findAvailabelSlot(this.rollingWeeksArray[i])
        }
        this.availableSlotsArray.push({
          date: this.rollingWeeksArray[i],
          slots: oneDayTimeSlot.availableSlots,
          start: this.start,
          end: this.end,
          morebuttonTopMargin: "1em",
          bookedSlots: oneDayTimeSlot.bookedSlots,
          isBlock: this.isBlock(i)
        })
      }
      if (this.appointmentService.rollingPeriodEnding == -1) {
        // this.setCalenderWeekWhenSlotNotAvalableInStartingDate()
      }
      this.loading = false
    });
  }

  findAvailabelSlot(date) {
    let bookedAppointmentToolTipArray: any = []
    let oneDayAvailableTimeSlot = [];
    let oneDayBookedTimeSlot = [];
    this.bookedSlots.forEach(slots => {
      if (this.datepipe.transform(date, 'yyyy-MM-dd') == this.datepipe.transform(slots.date, 'yyyy-MM-dd')) {
        oneDayBookedTimeSlot.push(slots)
      }
    })
    date = moment(date);
    let day = date.day();

    this.timeslots = this.timeslot_settings["timeslots"][day];
    this.timeslots.slots.forEach(x => {
      let bookedAppointmentToolTip = ''
      let flag = 0;
      if (this.appointmentService.accDetails.requestedAppointment)
        oneDayBookedTimeSlot.forEach(y => {
          let apptStart = this.formatAMPM(y.appt_start.toDate())
          let apptEnd = this.formatAMPM(y.appt_end.toDate())
          let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
          console.log(x.start, '---', x.end, '---', apptStart, '---', isBetween)
          console.log(this.formatAMPM(y.appt_start.toDate()))
          if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
            flag = flag + 1;

            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      else
        oneDayBookedTimeSlot.forEach(y => {
          if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      let color = bookedAppointmentToolTip == '' ? '#8bc091' : flag < this.clinicNumberOfRooms ? '#e0c255' : '#dc7070';
      bookedAppointmentToolTip = bookedAppointmentToolTip == '' ? 'Available Slot' : bookedAppointmentToolTip
      bookedAppointmentToolTipArray.push({ tooltip: bookedAppointmentToolTip, color: color })
      if (flag < this.clinicNumberOfRooms) {
        x.localTime = this.appointmentService.convertToLocalTime(x.start)
        oneDayAvailableTimeSlot.push(x);
      } else {
        return false;
      }

    });
    if (!this.isDebuging)
      return { availableSlots: oneDayAvailableTimeSlot, bookedSlots: bookedAppointmentToolTipArray }
    else
      return { availableSlots: this.timeslots.slots, bookedSlots: bookedAppointmentToolTipArray }
  }

  formatAMPM(date) {
    // return moment(date).format("hh:mm a")
    return moment(date).tz(this.appointmentService.timezone).format("hh:mm a")
  }

  findAvailabelSlotForToday(date) {
    let bookedAppointmentToolTipArray: any = []
    let oneDayAvailableTimeSlot = [];
    let oneDayBookedTimeSlot = [];
    this.bookedSlots.forEach(slots => {
      if (this.datepipe.transform(date, 'yyyy-MM-dd') == this.datepipe.transform(slots.date, 'yyyy-MM-dd')) {
        oneDayBookedTimeSlot.push(slots)
      }
    })
    date = moment(date);
    let day = date.day();

    this.timeslots = this.timeslot_settings["timeslots"][day];

    this.timeslots.slots.forEach(x => {
      let flag = 0;
      let bookedAppointmentToolTip = ''
      if (this.appointmentService.accDetails.requestedAppointment)
        oneDayBookedTimeSlot.forEach(y => {
          let apptStart = this.formatAMPM(y.appt_start.toDate())
          let apptEnd = this.formatAMPM(y.appt_end.toDate())
          let isBetween = moment(apptStart, 'h:mma').isBetween(moment(x.start, 'h:mma'), moment(x.end, 'h:mma')) || moment(apptStart, 'h:mma').isSame(moment(x.start, 'h:mma')) || (moment(apptStart, 'h:mma').isBefore(moment(x.start, 'h:mma')) && moment(apptEnd, 'h:mma').isAfter(moment(x.start, 'h:mma')))
          console.log(x.start, '---', x.end, '---', apptStart, '---', isBetween)
          console.log(this.formatAMPM(y.appt_start.toDate()))
          if (isBetween && (y.status === "booked" || y.status === "on-hold" || y.status === "requested" || y.status === "unconfirmed")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      else
        oneDayBookedTimeSlot.forEach(y => {
          if (`${x.start} - ${x.end}` === y.slot && (y.status === "booked" || y.status === "on-hold" || y.status === "requested")) {
            flag = flag + 1;
            bookedAppointmentToolTip += `${y.contact_info ? y.contact_info.first_name : 'Blocked slot'}  ${y.contact_info ? y.contact_info.last_name : ''} ~ ${y.slot} - ${y.status} \n   `;
          }
        });
      let color = bookedAppointmentToolTip == '' ? '#8bc091' : flag < this.clinicNumberOfRooms ? '#e0c255' : '#dc7070';
      bookedAppointmentToolTip = bookedAppointmentToolTip == '' ? 'Available Slot' : bookedAppointmentToolTip
      bookedAppointmentToolTipArray.push({ tooltip: bookedAppointmentToolTip, color: color })
      if (flag < this.clinicNumberOfRooms) {
        x.localTime = this.appointmentService.convertToLocalTime(x.start)
        oneDayAvailableTimeSlot.push(x);
      } else {
        return false;
      }

    });

    var currentTime = this.formatAMPM(new Date)
    let todaysAvailableSlot = [];
    oneDayAvailableTimeSlot.forEach(element => {

      console.log(currentTime)
      var slotTime = moment(element.start, 'h:mma');
      var curTime = moment(currentTime, 'h:mma');
      if (!slotTime.isBefore(curTime)) {
        todaysAvailableSlot.push(element)
      }

    });
    if (!this.isDebuging)
      return { availableSlots: todaysAvailableSlot, bookedSlots: bookedAppointmentToolTipArray }
    else
      return { availableSlots: this.timeslots.slots, bookedSlots: bookedAppointmentToolTipArray }
  }


  weekIncrement() {
    if (!(this.ending == this.rollingWeeksArray.length)) {
      this.starting = this.starting + 7;
      this.ending = this.ending + 7;
      this.setMonthTitle();
    }

  }

  weekDeccrement() {
    if (this.starting != 0) {
      this.starting = this.starting - 7;
      this.ending = this.ending - 7;
    }
    this.setMonthTitle();
  }

  setMonthTitle() {
    var firstDayofWeek = new Date(this.rollingWeeksArray[this.starting]);
    var lastDayofWeek = new Date(this.rollingWeeksArray[this.ending - 1]);

    if (firstDayofWeek.toLocaleString('default', { month: 'long' }) == lastDayofWeek.toLocaleString('default', { month: 'long' }))
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    else
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()

    if (lastDayofWeek.getUTCFullYear() != firstDayofWeek.getUTCFullYear())
      this.monthTitle = firstDayofWeek.toLocaleString('default', { month: 'long' }) + " " + firstDayofWeek.getUTCFullYear() + "/" + lastDayofWeek.toLocaleString('default', { month: 'long' }) + " " + lastDayofWeek.getUTCFullYear()
  }

  isShow(index) {
    if (index >= this.starting && index < this.ending)
      return true

    else
      return false
  }

  onDateChange(index) {
    this.selectedDate = index
    let selectedDateOnStrip = moment(this.rollingWeeksArray[index]);
    this.appointmentService.setSelectedDate(selectedDateOnStrip);
    // this.onDateSelect.emit(selectedDateOnStrip);
  }

  isBlock(index) {

    if (index > 7 * this.maxDate)
      return true

    if (index < this.minDate)
      return true

    for (let i = 0; i < this.filterDays.length; i++) {
      if (this.rollingWeeksArray[index].substring(0, 3) == this.dayOfWeek[this.filterDays[i]])
        return true
    }

    for (let i = 0; i < this.blocked_dates.length; i++) {
      var date = new Date(this.rollingWeeksArray[index]);
      if (this.datepipe.transform(date, 'yyyy-MM-dd') == this.blocked_dates[i])
        return true
    }

    return false
  }

  setStripCalenderSelectedDate() {
    this.selectedDate = this.appointmentService.getSelectedDate();
    for (let i = 0; i <= (7 * (this.maxDate + 1)); i++) {
      if (this.datepipe.transform(this.selectedDate, 'yyyy-MM-dd') == this.datepipe.transform(this.rollingWeeksArray[i], 'yyyy-MM-dd')) {
        this.selectedDate = i;
        for (let j = 1; j < this.maxDate + 1; j++) {
          if (this.selectedDate >= (7 * j) && this.selectedDate < (7 * (j + 1))) {
            this.starting = 7 * j;
            this.ending = 7 * (j + 1);
          }
        }
        break;
      }
    }
  }

  onSlotSelect(i, j) {
    let selectedDateOnStrip = moment(this.availableSlotsArray[i].date);
    this.appointmentService.setSelectedDate(selectedDateOnStrip);
    this.appointmentService.setSelectedSlot(this.availableSlotsArray[i].slots[j]);
    this.appointmentService.rollingPeriodEnding = this.ending;
    this.appointmentService.rollingPeriodStarting = this.starting
    if (this.rescheduleAppt == true) {
      this.rescheduleDateSlected.emit({ date: selectedDateOnStrip, slot: this.availableSlotsArray[i].slots[j] })
    }
    else
      this.router.navigateByUrl("/confirm-appointment");
  }

  timeSlotShow(i) {
    let a = 5 - this.availableSlotsArray[i].slots.length % 5;
    if (this.availableSlotsArray[i].end == this.availableSlotsArray[i].slots.length) {
      this.availableSlotsArray[i].end = 5;
      this.availableSlotsArray[i].start = 0;
      a = 0
    }

    else if (this.availableSlotsArray[i].end + 5 < this.availableSlotsArray[i].slots.length) {
      this.availableSlotsArray[i].end = this.availableSlotsArray[i].end + 5;
      this.availableSlotsArray[i].start = this.availableSlotsArray[i].start + 5;
      a = 0
    }
    else {
      this.availableSlotsArray[i].start = this.availableSlotsArray[i].start + 5;
      this.availableSlotsArray[i].end = this.availableSlotsArray[i].slots.length;
    }


    switch (a) {
      case 0: this.availableSlotsArray[i].morebuttonTopMargin = "1em";
        break
      case 1: this.availableSlotsArray[i].morebuttonTopMargin = "3.95em";
        break
      case 2: this.availableSlotsArray[i].morebuttonTopMargin = "6.9em";
        break
      case 3: this.availableSlotsArray[i].morebuttonTopMargin = "9.85em";
        break
      case 4: this.availableSlotsArray[i].morebuttonTopMargin = "12.8em";
        break
    }

  }

  closeWindow() {
    window.parent.postMessage(JSON.stringify({ eventType: 'CLOSE' }), "*");
  }

  setCalenderWeekWhenSlotNotAvalableInStartingDate() {
    let slotFound: boolean = false;
    for (let i = 0; i < this.availableSlotsArray.length; i++) {
      if (this.isBlock(i) == false) {
        if (this.availableSlotsArray[i].slots.length > 0) {
          slotFound = true
          let weekIndex = ~~(i / 7);
          this.appointmentService.setSlotFoundIndex(i)
          this.ending = (weekIndex + 1) * 7;
          this.starting = this.ending - 7;
          this.setMonthTitle()
        }
      }
      if (slotFound == true) {
        break
      }
    }
  }

  goBack() {
    this.appointmentService.routeback()
    this.appointmentService.rollingPeriodEnding = -1;
    this.appointmentService.rollingPeriodStarting = -1;
    this.appointmentService.stripCalenderEnding = -1;
    this.appointmentService.stripCalenderStarting = -1;
  }

  //below function is only for debugging purpose
  createDebugingModeCalendar() {

  }
}

