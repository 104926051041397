<!-- <div class="container-fluid">
  <div class="center">
    <p class="navigation">
      <a [routerLink]="['/reschedule-timeslots']"><i class="material-icons icon-btn">keyboard_backspace</i> Pick another
        time slot</a>
    </p>
    <p class="highlight" *ngIf="appointmentService.providersName !== undefined">
      {{ providersName }} at {{ location }}
    </p>
    <p class="highlight"
      *ngIf="appointmentService.multipleLocations === true && appointmentService.providersName === undefined">
      {{ location }}
    </p>
    <p class="highlight">
      {{ selectedDate }},
      {{ appointmentService.convertToLocalTime(selectedSlot.start) }} -
      {{ appointmentService.convertToLocalTime(selectedSlot.end) }}
      {{ appointmentService.getLocalTimezoneAbbr() }}
    </p>
    <form #formData="ngForm" (ngSubmit)="confirmAppointment(formData)" name="myForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name" required />
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name" required />
          </mat-form-field>
        </div>
      </div> -->

<!-- Removed phone number pattern check -->
<!-- <div class="row">
        <div class="col-xs-4 col-md-2">
          <mat-form-field>
            <mat-select name="countryCode" [(ngModel)]="countryCode">
              <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                {{ country.code }} ({{ country.name }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-xs-8 col-md-10">
          <mat-form-field class="example-full-width">
            <input matInput id="phone_no" #phone_no="ngModel" name="phone_no" [(ngModel)]="phone" pattern=""
              placeholder="Phone Number" required />
          </mat-form-field>
          <div *ngIf="
              phone_no.invalid &&
              (phone_no.dirty || phone_no.touched) &&
              phone_no.errors.pattern
            " class="alert alert-danger">
            Please enter a valid mobile number.
          </div>
        </div>
      </div> -->

<!-- Removed email pattern check [A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$ -->
<!-- <div class="row">
        <div class="col-md-12">
          <mat-form-field class="example-full-width">
            <input matInput id="email" #email="ngModel" name="email" [(ngModel)]="email_id" placeholder="Email"
              pattern="" required />
          </mat-form-field>
          <div *ngIf="
              email.invalid &&
              (email.dirty || email.touched) &&
              email.errors.pattern
            " class="alert alert-danger">
            Email format should be
            <small><b>joe@abc.com</b></small>
          </div>
        </div>
      </div>

      <div *ngIf="settings_info.form_fields!==undefined">
        <div *ngIf="settings_info.form_fields.address===true">
          <div>
            <div class=" row">
              <div class="col-md-12">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="Street" name="street" [(ngModel)]="street" required />
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="City" name="city" [(ngModel)]="city" required />
                </mat-form-field>
              </div>

              <div class="col-md-6">
                <mat-form-field class="example-full-width">
                  <input matInput placeholder="State" name="state" [(ngModel)]="state" required />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div> -->

<!-- <div class="row">
        <div class="col-xs-6 col-md-6">
          <label id="example-radio-group-label">
            <h4>Are you a new patient?</h4>
          </label>
          <mat-radio-group [class.has-error]="patient.invalid && patient.touched"
            aria-labelledby="example-radio-group-label" class="example-radio-group" #patient="ngModel" name="patient"
            [(ngModel)]="patientType" required>
            <mat-radio-button class="example-radio-button" *ngFor="let selectedOption of patientOptions"
              [value]="selectedOption" checked="selectedOption=== patientType">
              {{ selectedOption }}
            </mat-radio-button>
          </mat-radio-group>
          <div *ngIf="patient.invalid && patient.touched" class="alert alert-danger">
            Patient field is required
          </div> -->
<!-- <div>Your favorite season is: {{patientType}}</div> -->
<!--       </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div *ngIf="services.enabled == true">
            <h4>Please choose the treatments you are looking for:</h4>
            <section class="example-section" *ngFor="let service of services.provided; let i = index">
              <mat-checkbox class="example-margin" name="{{ servicesSelected[i] }}" [(ngModel)]="servicesSelected[i]">
                {{ service }}</mat-checkbox>
            </section>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <button mat-raised-button color="primary" type="submit" [disabled]="!formData.valid">
            Confirm Appointment
          </button>
        </div>
      </div>
    </form>
  </div>
</div> -->

<app-appointment-confirmation [rescheduleAppt]="'true'" [showLoadingRes]="false"
  (confirmRescheduleAppointment)="confirmAppointment($event)">
</app-appointment-confirmation>