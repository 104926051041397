import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";
import { COUNTRY_CODES } from "../_constants/country_phone_codes";
import * as moment from "moment-timezone";
import { AppointmentService } from "../_services/appointment.service";
// import { settings } from "cluster";
//import {CanComponentDeactivate } from '../guards/candeactivate/candeactivate.guard';
//declare const fbq: any;
declare const dataLayer: any;

@Component({
  selector: "app-reschedule-appointment-confirmation",
  templateUrl: "./reschedule-appointment-confirmation.component.html",
  styleUrls: ["./reschedule-appointment-confirmation.component.css"],
})
export class RescheduleAppointmentConfirmationComponent implements OnInit {
  first_name: string = "";
  last_name: string = "";
  phone: string = "";
  email_id: string = "";
  street: string = "";
  city: string = "";
  state: string = "";
  title: string = "";
  message: string = "";
  selectedDate: any;
  selectedSlot: any;
  appointment_info: any;
  settings_info: any;
  services: any = [];
  servicesSelected: any = [];
  //services_choose:any = [];
  patientType: string;
  patientOptions: string[] = ["Yes", "No"];
  providersName: string;
  location: string;

  services_chosen: string;
  appt_id: string;
  appointmentRef: any;
  contact: any;

  
  countryCodes: any = COUNTRY_CODES;
  countryFound = this.countryCodes.find((x) =>
    x.timezones.includes(moment.tz.guess())
  )
  countryCode: any = this.countryFound ? this.countryFound.code : '+1';
 
 


  full_phone: string;
  constructor(
    private router: Router,
    public appointmentService: RescheduleAppointmentService,
    public apptService: AppointmentService
  ) {
    // this.patientType = this.appointmentService.appointment_details.new_patient;
  }

  /*confirm(){
    return confirm("Do you want to leave this page?");
  }*/

  ngOnInit() {
    if (this.appointmentService.apptConfirmed)
      this.appointmentService.bookAnotherAppointment()
    this.providersName = this.appointmentService.providersName;
    // console.log(this.providersName);
    this.location = this.appointmentService.getSettings().location.name;
    this.settings_info = this.appointmentService.getSettings();
    // console.log(this.settings_info);
    if (this.settings_info.form_fields !== undefined) {
      this.title = this.settings_info.form_fields.message.title;
    }
    // console.log(this.title);
    if (this.title === undefined || this.title === "") {
      this.title = "Message";
    }
    let user_timezone = this.appointmentService.appointment_details
      .user_timezone;
    console.log(this.appointmentService.appointment_details);
    this.patientType = this.appointmentService.appointment_details.new_patient;
    this.appointment_info = this.appointmentService.appointment_details;
    console.log(this.countryCodes);
    this.countryCode = this.countryCodes.find((x) => x.timezones.includes(user_timezone)).code;
    //let country_code = this.countryCodes.find((x) => x.timezones.includes(user_timezone)).country_code;
    this.contact = this.appointmentService.getContactDetails();
    if (this.contact !== undefined) {
      this.email_id = this.appointment_info.contact_info.email;
      this.phone = this.appointment_info.contact_info.phone;
      this.first_name = this.appointment_info.contact_info.first_name;
      this.last_name = this.appointment_info.contact_info.last_name;
      this.full_phone = this.countryCode + this.phone;
      console.log(this.full_phone);
      // if (this.appointment_info.contact_info.address != undefined) {
      //   this.street = this.appointment_info.contact_info.street;
      //   this.city = this.appointment_info.contact_info.city;
      //   this.state = this.appointment_info.contact_info.state;
      // }
      // if (this.appointment_info.contact_info.message != undefined) {
      //   this.message = this.appointment_info.contact_info.message;
      // }
      // this.email_id = this.contact.email;
      // this.phone = this.contact.phone;
      // this.first_name = this.contact.first_name;
      // this.last_name = this.contact.last_name;
      /*
       let name = this.contact.name;
        let splitNames = name.split(" ");
       this.first_name = splitNames[0];
       this.last_name = splitNames[1];
       */
    } else {
      this.first_name = "";
      this.last_name = "";
      this.phone = "";
      this.email_id = "";
      this.street = "";
      this.city = "";
      this.state = "";
      this.message = "";
    }
    this.services = this.appointmentService.getSettings().services;
    let selectedServices = this.appointmentService.appointment_details.services;
    if (this.services.enabled === true) {
      let servicesSplit = selectedServices.split(", ");
      for (let i = 0; i < this.services.provided.length; i++) {
        if (servicesSplit.includes(this.services.provided[i])) {
          this.servicesSelected.push(true);
        } else {
          this.servicesSelected.push(false);
        }
      }
    }

    /*
    for(let i=0; i<this.servicesSelected.length; i++){
        if(this.servicesSelected[i] === true){
            this.services_choose.push(this.services.provided[i]);
        }
    }*/

    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      let slot = this.appointmentService.getSelectedSlot();
      if (slot !== undefined) {
        this.selectedSlot = slot;
      } else {
        this.router.navigateByUrl("/reschedule-timeslots");
      }
    } else {
      if (this.appointmentService.multipleLocations === true) {
        this.router.navigate(
          [
            "/reschedule-calendar",
            this.appointmentService.acc_slug,
            this.appointmentService.calendar_slug,
          ],
          { queryParams: { multipleLocations: true } }
        );
      } else {
        this.router.navigateByUrl(
          `/reschedule-calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
        );
      }
    }
  }

  confirmAppointment(formData) {
    // this.services_chosen = "";
    // for (let i = 0; i < this.servicesSelected.length; i++) {
    //   if (this.servicesSelected[i] === true) {
    //     this.services_chosen += this.services.provided[i] + ", ";
    //   }
    // }
    // //console.log(this.services_chosen);
    // formData.value.services = this.services_chosen;
    // formData.value.email = formData.value.email.trim();
    // formData.value.countryCode = this.countryCode;
    // // if (this.appointment_info.street !== undefined) {
    // //   formData.value.street = this.street;
    // //   formData.value.city = this.city;
    // //   formData.value.state = this.state;
    // // }
    // // if (this.appointment_info.contact_info.message !== undefined) {
    // //   formData.value.message = this.message;
    // // }
    // let stripPhone = formData.value.phone_no.replace(/[^\d]/g, "");
    // // console.log(stripPhone);
    // formData.value.phone_no = stripPhone;
    // formData.value.patientType = this.patientType;
    this.appointmentService.setFormData(formData);
    this.appointmentService.confirmAppointmentValidity().then((valid) => {
      if (valid) {
        this.appointmentService.saveAppointment().then(
          (docRef) => {
            this.appointmentRef = docRef;
            this.appointmentService.updateApptStatus();
            this.appointmentService
              .saveAppointmentReference(this.appointmentRef)
              .then((doc) => {
                console.log("reference added to reschedules collection");

                this.appointmentService.saveRescheduleDoc(this.appointmentRef);
                this.appointmentService.setSelectedServices(
                  formData.services
                );

                this.appointmentService.rescheduleEmailToOwner();
                this.appointmentService.appointmentRescheduleSmsToUser('rescheduled')
                this.appointmentService.rescheduleEmailToUser();
                // this.appointmentService.appointmentRescheduleSmsToUser("rescheduled");
                this.router.navigate(["/reschedule-appointment-status"], {
                  queryParams: { status: "rescheduled" },
                });
                console.log("Appointment added!");
              });
          },
          (error) => {
            this.router.navigate(["/reschedule-appointment-status"], {
              queryParams: { status: "unconfirmed", errorIn: "saving" },
            });
            console.error(error);
          }
        );
      } else {
        this.router.navigate(["/reschedule-appointment-status"], {
          queryParams: { status: "unconfirmed", errorIn: "confirming" },
        });
        console.error(
          "The selected slot for selected date has now become unavailable."
        );
      }
    });
  }
}
