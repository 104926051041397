<div *ngIf="!isReady;else ready ">
    <div class="spinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        <p>Loading...</p>
    </div>
</div>
<ng-template #ready>
    <div class="msg">
        <div class="box">
            <P> <b> Name:</b> {{contactIfo.first_name}} {{contactIfo.last_name}}</P>
            <p> <b> Email:</b> {{contactIfo.email}}</p>
            <p> <b>Phone:</b> {{contactIfo.phone}}</p>
            <!-- <i class="material-icons">query_builder</i> -->
            <p> <b>Appointment Date:</b> {{oldDate | date: "MMM d, y"}}</p>
            <p> <b>Slot:</b> {{timeslot}}</p>
            <p *ngIf="appointment_details.isEmergency"> <b>Emergency:</b> {{appointment_details.isEmergency}}</p>
            <p> <b>Booked On:</b> {{appointment_details.booked_on.toDate() | date: "MMM d, y"}}</p>
            <p *ngIf="appointment_details.type"> <b>Visit Type:</b> {{appointment_details.type}}</p>
            <p *ngIf="appointment_details.services"> <b>Services opted for:</b> {{appointment_details.services}}</p>
            <p *ngIf="appointment_details.source"> <b>Booked from:</b> {{appointment_details.source}}</p>
            <p *ngIf="appointment_details.provider_name"> <b>Provider Name :</b> {{appointment_details.provider_name}}
            </p>
            <p><b>*Note: You have to add this appointment to PMS.</b></p>
            <div *ngIf="acceptRejetct">
                <div class="row">
                    <button mat-raised-button class="btn" style="margin-left: 1em;" (click)="updateStatus('accepted')"
                        [disabled]="updating">Accept</button>
                    <button mat-raised-button style="margin-left:2em ;" (click)="updateStatus('rejected')"
                        [disabled]="updating">Reject</button>
                </div>
            </div>
            <div *ngIf="showMsg">
                <div>
                    <p class="notify">{{message}}</p>
                </div>
            </div>
            <div *ngIf="status">
                <p class="notify">This appointment request has
                    been {{status}}!</p>
            </div>
        </div>
    </div>
</ng-template>