import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentService } from '../_services/appointment.service';
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';
@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.css']
})
export class RescheduleAppointmentComponent implements OnInit {
  isReady: boolean = false;
  reschedule_doc: string;
  redirectUrl: string;
  appointment_details: any;
  appt_status: string;
  showMsg: boolean = false;
  oldDate: any;
  message: string;
  pastEvent: boolean = false;
  constructor(
    public rescheduleAppointmentService: RescheduleAppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService
  ) { }


  ngOnInit() {
    this.appointmentService.otpVerified = true
    this.route.url.subscribe(url => {
      this.reschedule_doc = url[1].path;
      //   console.log(this.reschedule_doc);

      this.rescheduleAppointmentService.fetchAppointmentData(this.reschedule_doc)
        .then(appointment_details => {
          this.appointment_details = appointment_details;
          console.log(appointment_details)
          let formdata = {
            email: appointment_details.contact_info.email,
            first_name: appointment_details.contact_info.first_name,
            last_name: appointment_details.contact_info.last_name,
            phone_no: appointment_details.contact_info.phone,
            send_text_reminders: appointment_details.contact_info.send_text_reminders,
            isEmergency: appointment_details.isEmergency,
            patient: appointment_details.new_patient,
            services: appointment_details.services ? appointment_details.services : '',
            type: appointment_details.type,
            birthDate: appointment_details.birthDate ? appointment_details.birthDate.toDate() : ''
          }
          this.appointmentService.setCategory(appointment_details.category);
          this.appointmentService.setSource(appointment_details.source);
          let accountId = appointment_details.contact.path.split('/')[1];
          let contactId = appointment_details.contact.id;
          this.appointmentService.setFormData(formdata)
          this.appt_status = this.appointment_details.status;
          this.oldDate = this.rescheduleAppointmentService.getOldAppointmentDate();
          let now = new Date();
          if ((this.appt_status == 'booked' || this.appt_status == 'test' || this.appt_status == 'no-show') && (this.oldDate >= now)) {
            this.redirectToCalendar();
            //  this.appointmentService.updateApptStatus();
          }
          else if (this.appt_status == 'no-show') {
            this.redirectToCalendar();
          }
          else {
            this.showMsg = true;
            if (this.appt_status == 'rescheduled' && this.oldDate >= now) {
              this.message = "This appointment has already been rescheduled!";
            }
            else if (this.appt_status == 'cancelled' && this.oldDate >= now) {
              this.message = "This appointment has been cancelled!";
            }
            else {
              this.pastEvent = true;
              this.message = "You are trying to reschedule a past event!";
            }
          }

          /*let now = new Date();
          console.log(now);
          this.oldDate = this.appointmentService.getOldAppointmentDate();
          if(this.oldDate < now){
            console.log('You are trying to reschedule a past event!');
          }*/

        });

    }
    );

  }



  redirectToCalendar() {
    this.redirectUrl = this.rescheduleAppointmentService.getRedirectUrl();
    this.appointmentService.setrouterActivity();
    this.router.navigateByUrl(this.redirectUrl);

  }



}
