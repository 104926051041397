<div>
  <div *ngIf="isReady; then ready; else not_ready"></div>
  <ng-template #not_ready>
    <div class="spinner">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </ng-template>
  <ng-template #ready>
    <div>
      <app-calendar [rescheduleAppt]="true" (rescheduleDateSlected)="setDate($event)"></app-calendar>
    </div>
  </ng-template>
</div>