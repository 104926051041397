import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProvidersService } from "../_services/providers.service";
import { LocationsService } from "../_services/locations.service";
import { Provider } from "../_models/interfaces"; //interface for providers
import { AppointmentService } from "../_services/appointment.service";

@Component({
  selector: "app-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.css"],
})
export class ProvidersComponent implements OnInit {
  acc_slug: string;
  location: string;
  providers: Array<Provider> = [];
  accId: string;
  isReady: Boolean = false;
  islocation: Boolean = false;
  locationName: string;
  constructor(
    private route: ActivatedRoute,
    private providersService: ProvidersService,
    private locationService: LocationsService,
    public appointmentService: AppointmentService,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      let source = this.appointmentService.getSource();
      let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }
      if (category === undefined) {
        category =
          queryParams.category === undefined ? "" : queryParams.category;
        this.appointmentService.setCategory(category);
      }
    });
    this.route.url.subscribe((url) => {
      this.acc_slug = url[1].path;
      this.providersService.getAccId(this.acc_slug).then((docDetails) => {
        this.accId = docDetails.id;
        if (url.length > 2) {
          this.isReady = false;
          this.location = url[2].path;
          this.islocation = true;
          this.locationService
            .getLocationRef(this.accId, this.location)
            .then((locSnap) => {
              this.locationName = locSnap.data().name;
              let locRef = locSnap.ref;
              this.providersService
                .getProvidersForLocation(this.accId, locRef)
                .then((providers) => {
                  this.providers = providers;
                  console.log(this.providers);
                  if (this.providers.length == 1) {
                    this.appointmentService.setrouterActivity();
                    this.router.navigate([
                      "services",
                      this.acc_slug,
                      this.providers[0].slug,
                      this.location,
                    ]);
                  }
                  this.isReady = true;
                });
            });
        }
        else {
          this.providersService.getProviders(this.accId).then((providers) => {
            this.providers = providers;
            console.log(this.providers)
            if (this.providers.length == 1) {
              this.appointmentService.setrouterActivity();
              this.router.navigate([
                "locations",
                this.acc_slug,
                this.providers[0].slug,

              ]);

              // this.providersService.getProviderRef(this.accId,this.providers);
            }
            this.isReady = true;
            // this.route.url.subscribe((url) => {
            //   this.location = url[1].path;
            //   this.islocation = true;
            //   this.isReady = true;
            // });
          });
        }
      });
    });
  }

  goBack() {
    this.appointmentService.routeback()
  }
}
