import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { FormControl } from "@angular/forms";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";
import * as moment from "moment";
//import {default as _rollupMoment} from 'moment';
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";
//const moment = _rollupMoment || _moment;
import { MatCalendar } from '@angular/material/datepicker';
import { AppointmentService } from "../_services/appointment.service";

@Component({
  selector: "app-reschedule-calendar",
  templateUrl: "./reschedule-calendar.component.html",
  styleUrls: ["./reschedule-calendar.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
  //schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class RescheduleCalendarComponent implements OnInit {
  minDate: any;
  maxDate: any;
  myFilter: any;
  selectedDate: any;
  isReady: boolean = false;
  calendar_settings: any;
  calendar_slug: string;
  acc_slug: string;
  appt_id: string;
  startUrl: string;
  oldDate: any;
  providersName: string;
  location: string;
  //dateClass : any;
  flag: number = 0;
  temp: String;
  TempArray: any = [];
  config: any;
  block_enable: boolean;
  Blocked_Date = [];
  block_date: any;
  changeCalenderConstarint: any;

  constructor(
    public rescheduleAppointmentService: RescheduleAppointmentService,
    public appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    //this.appointmentService.getOldAppointmentDate();
    this.route.queryParams.subscribe(queryParams => {
      console.log(queryParams.source);
      //this.appt_id = queryParams['appt_id'];
      //console.log(this.appt_id);
      console.log(queryParams.appt_id);
      // this.appt_id = queryParams.appt_id;

      console.log(this.rescheduleAppointmentService.providersName);

      console.log(this.appt_id);
      let source = this.rescheduleAppointmentService.getSource();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.rescheduleAppointmentService.setSource(source);
      }
      // this.appointmentService.setrouterActivity();

      if (queryParams.multipleProviders !== undefined) {
        this.rescheduleAppointmentService.multipleProviders =
          queryParams.multipleProviders !== undefined ? true : false;
      }

      this.rescheduleAppointmentService.multipleLocations =
        queryParams.multipleLocations !== undefined ? true : false;

      this.rescheduleAppointmentService.providerLocations =
        queryParams.providerLocations !== undefined ? true : false;
    });

    this.route.url.subscribe(url => {
      this.startUrl = url[0].path;
      this.acc_slug = url[1].path;
      this.calendar_slug = url[2].path;
      console.log(this.acc_slug, this.calendar_slug);
      //  this.appointmentService.fetchAppointmentDetails(this.acc_slug, this.appt_id);
      //this.acc_slug = 'dental-designer';
      //this.calendar_slug = 'dental-calendar';
      this.rescheduleAppointmentService
        .getCalendarSettings(this.acc_slug, this.calendar_slug)
        .then(settings => {

          this.appointmentService.acc_slug = this.acc_slug
          this.appointmentService.calendar_slug = this.calendar_slug
          console.log(settings.docs[0].data());
          this.rescheduleAppointmentService.setSettings(settings.docs[0].data());


          this.location = this.rescheduleAppointmentService.getSettings().location.name;
          this.calendar_settings = settings.docs[0].data();
          this.rescheduleAppointmentService.providerRef = this.calendar_settings.providerReference;
          if (this.rescheduleAppointmentService.providerRef !== undefined) {
            this.rescheduleAppointmentService.getProvidersDetails(this.rescheduleAppointmentService.providerRef).then(
              providerName => {
                this.providersName = providerName;
              }

            );
          }

          this.providersName = this.rescheduleAppointmentService.providersName;
          console.log(this.providersName);

          //console.log(this.calendar_settings);
          if (this.rescheduleAppointmentService.calendarRef === undefined) {
            this.rescheduleAppointmentService.calendarRef = settings.docs[0].ref;
          }

          this.rescheduleAppointmentService.setTimezone(
            this.calendar_settings["timezone"]
          );
          if (this.calendar_settings['timeLimit'] !== undefined && this.calendar_settings['timeLimit']['enabled'] == true) {
            let timeInfo = this.calendar_settings['timeLimit'];
            let maxBookingDate = moment().add(
              this.calendar_settings["maxDate"]["key"],
              this.calendar_settings["maxDate"]["amount"]
            );
            let minBookingDate = moment().add(
              this.calendar_settings["minDate"]["key"],
              this.calendar_settings["minDate"]["amount"]
            );

            this.maxDate = moment(timeInfo['endDate'].toDate()).isAfter(maxBookingDate) ? maxBookingDate : moment(timeInfo['endDate'].toDate())
            this.minDate = moment(timeInfo['startDate'].toDate()).isBefore(minBookingDate) ? minBookingDate : moment(timeInfo['startDate'].toDate());
          }
          else {
            this.minDate = moment().add(
              this.calendar_settings["minDate"]["key"],
              this.calendar_settings["minDate"]["amount"]
            );
            this.maxDate = moment().add(
              this.calendar_settings["maxDate"]["key"],
              this.calendar_settings["maxDate"]["amount"]
            );
          }
          this.myFilter = (d: Date): boolean => {
            const day = moment(d).day();
            // Filter out certain days.
            return (
              !this.calendar_settings["filterDays"].includes(day) &&
              !this.calendar_settings["blocked_dates"].includes(
                moment(d).format("Y-MM-DD")
              )
            );
          };

          // initialized date to '' everytime to fix the bug
          // where calendar does not navigate to timeslots if the date is unchanged.
          //let date = this.appointmentService.getSelectedDate();
          let date = "";
          if (date !== undefined) {
            this.selectedDate = date;
            console.log(typeof (date));
            console.log(this.selectedDate);
          } else {
            this.selectedDate = "";
          }
          // if (this.appointmentService.providersName !== undefined) {
          //   this.providersName = this.appointmentService.providersName;
          // } else {
          //   this.appointmentService.getProvidersDetails(this.calendar_settings.providerReference).then(provider => {
          //     this.providersName = provider;
          //   });
          // }
          this.isReady = true;
        });
    });

    if ((this.startUrl = "reschedule-calendar")) {
      this.oldDate = this.rescheduleAppointmentService.getOldAppointmentDate();
      console.log(this.oldDate.getDate());
      /*  this.dateClass = (d: Date) => {
              const mydate = d.getDate();
              console.log(mydate);
            // Highlight the 1st and 20th day of each month.
            return (mydate === this.oldDate) ? 'cal-date-class' : undefined;
          }
          console.log(this.dateClass.mydate);*/
    }
  }

  setDate(event) {
    //console.log(event);
    this.rescheduleAppointmentService.setSelectedDate(event.date);
    this.rescheduleAppointmentService.setSelectedSlot(event.slot);
    this.router.navigateByUrl("/reschedule-confirm-appointment");
    // this.router.navigateByUrl("/reschedule-timeslots");
  }

  Blocked_Dates_Function() {

    this.TempArray = this.Blocked_Date;

    this.flag = 0;
    //this.temp=this.block_date.toLocaleDateString();
    this.temp = moment(new Date(this.block_date)).format('Y-MM-DD');

    for (var i = 0; i < this.TempArray.length; i++) {
      if (this.TempArray[i] == this.temp) {
        this.flag = 1;
        break;
      }
    }
    if (this.flag == 0 && this.temp != undefined && this.temp != undefined) {
      //this.Blocked_Date.push(this.temp);
      this.config.blocked_dates.push(this.temp);
    }

    if (this.temp == undefined)
      window.alert("Blocked date should not be blank")
    if (this.flag == 1)
      window.alert("Date " + this.temp + " already present");
    if (this.Blocked_Date.length >= 2 || this.config.blocked_dates.length >= 2)
      this.block_enable = false;
    this.changeCalenderConstarint("myFilter");
  }


  goBack() {
    this.rescheduleAppointmentService.setSelectedDate({});
    if (this.rescheduleAppointmentService.multipleLocations) {
      this.router.navigateByUrl(
        `/reschedule-calendar-groups/${this.acc_slug}/${this.rescheduleAppointmentService.group_slug}`
      );
    } else if (this.rescheduleAppointmentService.multipleProviders) {
      this.router.navigateByUrl(
        `/${this.rescheduleAppointmentService.group_type}/${this.acc_slug}/${this.rescheduleAppointmentService.provider_slug}`
      );
    } else if (this.rescheduleAppointmentService.providerLocations) {
      this.router.navigateByUrl(
        `/reschedule-provider-locations/${this.acc_slug}/${this.rescheduleAppointmentService.providerId}`
      );
    }
  }
}

/*@Component({
  selector: 'app-reschedule-calendar',
  templateUrl: './reschedule-calendar.component.html',
  styleUrls: ['./reschedule-calendar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DatepickerDateClassExample {
  dateClass = (d: Date) => {
    const date = d.getDate();

    // Highlight the 1st and 20th day of each month.
    return (date === 1 || date === 20) ? 'example-custom-date-class' : undefined;
  }
}*/
