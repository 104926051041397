import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";

@Component({
  selector: "app-reschedule-provider-group",
  templateUrl: "./reschedule-provider-group.component.html",
  styleUrls: ["./reschedule-provider-group.component.css"]
})
export class RescheduleProviderGroupComponent implements OnInit {
  constructor(
    private appointmentService: RescheduleAppointmentService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  acc_slug: string;
  redirectUrl: string;
  providers: any = [];
  isReady: boolean = false;

  ngOnInit() {
    console.log(this.router.url);

    this.route.queryParams.subscribe(queryParams => {
      console.log(queryParams.source);
      let source = this.appointmentService.getSource();
      // let category = this.appointmentService.getCategory();
      if (source === undefined) {
        source =
          queryParams.source === undefined ? "website" : queryParams.source;
        this.appointmentService.setSource(source);
      }
      // if(category === undefined) {
      // category = queryParams.category === undefined ? '' : queryParams.category;
      // this.appointmentService.setCategory(category);
      // }
    });

    this.route.url.subscribe(url => {
      this.acc_slug = url[1].path;
      let group_slug = url[2].path;
      this.appointmentService
        .getProvidersCalendar(this.acc_slug, group_slug)
        .then(providers => {
          this.providers = providers;
          console.log(this.providers);
          this.isReady = true;
        });
    });
  }
  setCalendarRef(ref, docId) {
    this.appointmentService.calendarRef = ref;
    this.appointmentService.setProviderDetails(docId);
  }
  setproviderName(docId) {
    this.appointmentService.setProviderDetails(docId);
  }
}
