import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from "firebase/app";
// import { AngularFireDatabase } from "angularfire2/database";
import { AppointmentService } from './appointment.service';
import "firebase/functions";
import "firebase/firestore";
// import "firebase/database";
import { environment } from 'src/environments/environment';
import { resolve } from 'url';

declare var require: any;
@Injectable({
  providedIn: 'root'
})
export class PaytmService {
  generateChecksumUrl = "https://us-central1-mktgbot-181017.cloudfunctions.net/generate_checksum";
  pingBack = "https://us-central1-mktgbot-181017.cloudfunctions.net/verify_checksum?acc_slug=";
  transactionStatusUrl = "https://us-central1-mktgbot-181017.cloudfunctions.net/checkTransactionStatus";
  paytm = {};
  apiKeys = {};
  constructor(
    private http: HttpClient,
    private appointmentService: AppointmentService,
  ) { }



  async getPaytmAPIKeys() {
    let accRef = this.appointmentService.getAccountRef();
    let apiKeys = await accRef.collection('linked_accounts').doc('paytm').get();
    this.apiKeys = apiKeys.data();
    return apiKeys.data();
  }

  getChecksum(orderInfo) {
    let formData = this.appointmentService.formData;
    let slug = this.appointmentService.acc_slug;
    let cal_slug = this.appointmentService.calendar_slug;
    console.log(cal_slug);
    this.getPaytmAPIKeys().then(
      apiKeysDoc => {
        let apiKeys = apiKeysDoc;
        this.paytm["MID"] = apiKeys['MID'];
        this.paytm["ORDER_ID"] = orderInfo.apptId;
        this.paytm["CUST_ID"] = orderInfo.contactId;
        this.paytm["INDUSTRY_TYPE_ID"] = apiKeys['INDUSTRY_TYPE_ID'];
        this.paytm["CHANNEL_ID"] = apiKeys['CHANNEL_ID'];
        this.paytm["TXN_AMOUNT"] = orderInfo.amount;
        this.paytm["WEBSITE"] = apiKeys['WEBSITE'];
        this.paytm["CALLBACK_URL"] = `${environment.cloudFunctionDomain}/verify_checksum?acc_slug=${slug}&calendar_slug=${cal_slug}`;
        this.paytm["EMAIL"] = formData.email;
        this.paytm["MOBILE_NO"] = formData.phone_no;
        this.paytm['MERCHANT_KEY'] = apiKeys['MERCHANT_KEY'];

        let data = JSON.stringify(this.paytm);
        this.http.post<any>(`${environment.cloudFunctionDomain}/generate_checksum`, data).subscribe(
          response => {
            this.paytm['CHECKSUMHASH'] = response.checksum;
            // delete this.paytm['MERCHANT_KEY'];
            this.createPaytmForm(this.paytm);
          },
          err => {
            console.log(err);
            return err;
          });
      });

  }


  createPaytmForm(paytm) {
    /*
    * Create a form from payment details.
    * After submitting the form it will redirect to paytm payment page.
    * After complete or fail transaction it will redirect to CALLBACK URL
    * */
    const my_form: any = document.createElement('form');
    my_form.name = 'paytm';
    my_form.method = 'post';
    my_form.action = 'https://securegw-stage.paytm.in/order/process';

    console.log(paytm);
    const paytmParams = Object.keys(paytm);
    for (let i = 0; i < paytmParams.length; i++) {
      const key = paytmParams[i];
      let my_tb: any = document.createElement('input');
      my_tb.type = 'hidden';
      my_tb.name = key;
      my_tb.value = paytm[key];
      my_form.appendChild(my_tb);
    };
    document.body.appendChild(my_form);
    my_form.submit();
  }

  getTransactionStatus(paytmResponse) {
    return new Promise((resolve, reject) => {
      this.getPaytmAPIKeys().then(paytmKeys => {
        // paytmResponse["acc_slug"]=paytmKeys.MID;
        // paytmResponse["MERCHANT_KEY"]=paytmKeys.MERCHANT_KEY;
        firebase.functions().httpsCallable("checkTransactionStatus")(paytmResponse).then(
          resp => {
            let transactionStatus = JSON.parse(resp.data);
            console.log(transactionStatus);
            return resolve(transactionStatus);
          },
          err => {
            return reject(err);
          });
        // this.http.post<any>(`${environment.cloudFunctionDomain}/checkTransactionStatus`,JSON.stringify(paytmResponse)).subscribe(
        // response => {
        //   return resolve( JSON.parse(response));
        // },
        // err => {
        //   console.log(err);
        //   reject(err);
        // });
      });
    });

  }

  initiateTransaction(transactionInfo, paymentDetails, apptDetails) {
    let slug = this.appointmentService.acc_slug;
    let cal_slug = this.appointmentService.calendar_slug;
    console.log(paymentDetails);
    let body = {
      requestType: "Payment",
      acc_slug: slug,
      orderId: apptDetails.apptRef.id,
      callbackUrl: `${environment.cloudFunctionDomain}/verify_checksum?acc_slug=${slug}&calendar_slug=${cal_slug}`,
      txnAmount: { "value": paymentDetails.amount, "currency": paymentDetails.currency_code },
      userInfo: { custId: apptDetails.contactRef.id },
      enablePaymentMode: [
        { "mode": "UPI", "channels": ["UPIPUSH"] },
        { "mode": "CREDIT_CARD", "channels": ["VISA"] }]
    }
    let data = body;
    console.log(data);
    /**
      Initiate transaction through firebase function
    */
    return new Promise((resolve, reject) => {
      firebase.functions().httpsCallable("initiateTransactionForPaytmPayment")(data).then(
        resp => {
          console.log(JSON.parse(resp.data));
          let txtDetails = JSON.parse(resp.data);
          let paytmToken = {
            txnToken: txtDetails.body.txnToken,
            orderId: body.orderId,
            mid: transactionInfo['MID']
          }
          return resolve(paytmToken);
        });
    });
  }

  submitTransaction(paytm) {
    console.log("submitting paytm form");
    const my_form: any = document.createElement('form');
    my_form.name = 'paytm';
    my_form.method = 'post';
    my_form.action = `${environment.paytmServerDomain}/theia/api/v1/showPaymentPage?mid=${paytm.mid}&orderId=${paytm.orderId}`;
    const paytmParams = Object.keys(paytm);
    for (let i = 0; i < paytmParams.length; i++) {
      const key = paytmParams[i];
      let my_tb: any = document.createElement('input');
      my_tb.type = 'hidden';
      my_tb.name = key;
      my_tb.value = paytm[key];
      my_form.appendChild(my_tb);
    };
    document.body.appendChild(my_form);
    my_form.submit();
  }
}
