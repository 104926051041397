import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges, Inject } from '@angular/core';
import * as moment from 'moment';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { AppointmentService } from "../_services/appointment.service";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { verify } from 'crypto';


@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css']
})
export class OTPVerificationComponent implements OnInit, OnChanges {

  twilio_from: string;
  account_sid: string;
  auth_token: string;
  twilio_to: any = [];
  messageObj: any;
  displayMsg: string;
  randomOTP: any;
  service_sid: any;
  contact_ids: any = [];
  otp: any;
  phone_no: any;
  full_phone: string;
  screen1: boolean = true;
  invalidOtp: boolean = false;
  dialogData: any = {
    show: false,
    header: '',
    value: ''
  }
  twilioCreds: any;
  verificationStatus: boolean;


  constructor(
    private appointmentService: AppointmentService,
    @Inject(MAT_DIALOG_DATA) public formData,private dialogRef: MatDialogRef<OTPVerificationComponent>
  ) {

  }

  phoneValidation = new FormControl('', Validators.required);
  otpValidation = new FormControl('', Validators.required);
  countryCodeValidation = new FormControl('', Validators.required);

  @ViewChild('ngOtpInput') ngOtpInputRef: any;



  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '30px',
      'height': '30px'
    }
  };

  ngOnChanges() {
    this.screen1 = false;
  }

  ngOnInit() {
    this.full_phone = this.formData.countryCode + this.formData.phone;
    this.appointmentService.getTwilioCredentials().then(resp => {
      this.twilioCreds = resp;
      console.log(this.twilioCreds);
      this.sendOtp();
    });
    console.log(this.formData);
  }


  sendOtp() {
    this.screen1 = true;
    this.randomOTP = this.appointmentService.generateOtp();
    console.log("OTP=>", this.randomOTP)
    this.full_phone = this.formData.countryCode + this.formData.phone;
    let acc_name = this.appointmentService.acc_name;
    this.messageObj = {
      from: this.twilioCreds.twilio_from,
      to: [this.full_phone],
      acc_sid: this.twilioCreds.account_sid,
      auth_token: this.twilioCreds.auth_token,
      service_sid: this.twilioCreds.notify_service_sid,
      msg: this.randomOTP + ' is One time password (OTP) for your ' + acc_name + ' appointment. Please do not share this. If not requested, please inform us.',
      contact_ids: [],
    };
    console.log(this.messageObj);
    this.appointmentService.sendSms(this.messageObj);
    this.screen1 = false;
  }

  onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length == 6) {
      if (this.otp == this.randomOTP) {
        this.verificationStatus = true;
        this.verifyOTP()
      } else {
        this.verificationStatus = false;
      }
    }
  }

  verifyOTP() {
    if (this.otp == this.randomOTP) {
      this.appointmentService.otpVerified = true
      this.dialogRef.close(true);
    }
    else {
      this.otp = "";
      this.dialogData = {
        show: true,
        header: 'Invalid OTP',
        value: 'invalidOtp'
      }
    }
  }

  resendOtp() {
    this.otp = '';
    this.ngOtpInputRef.setValue(this.otp);
    this.sendOtp();
    this.invalidOtp = true;
    this.dialogData = {
      show: true,
      header: 'Resend OTP',
      value: ''
    }
  }


  changeEmail() {
    this.otp = "";
    this.ngOtpInputRef.setValue(this.otp);
  }

}
