import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";

@Component({
  selector: "app-reschedule-timeslot",
  templateUrl: "./reschedule-timeslot.component.html",
  styleUrls: ["./reschedule-timeslot.component.css"]
})
export class RescheduleTimeslotComponent implements OnInit {
  selectedDate: any;
  day: number;
  timeslots: any;
  selectedSlot: any;
  isReady: boolean = false;
  oldDate: any;
  newDate: any;
  oldTimeslot: any;
  newLocation: string;
  oldLocation: string;
  showSlot: boolean = false;
  //  timeslot_settings: any;
  providersName: string;
  location: string;

  constructor(
    public appointmentService: RescheduleAppointmentService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getOldTimeDisabled();
    let date = this.appointmentService.getSelectedDate();
    if (date !== undefined) {
      this.selectedDate = date.format("dddd, MMMM D YYYY");
      //this.appointmentService.getTimeslots()
      this.providersName = this.appointmentService.providersName;
      console.log(this.providersName);
      this.location = this.appointmentService.getSettings().location.name;
      console.log(this.location);
      let timeslot_settings = this.appointmentService.getSettings();
      //.then(res => {
      //     this.timeslot_settings = res.data();
      console.log(timeslot_settings);
      this.day = date.day();
      this.timeslots = timeslot_settings["timeslots"][this.day];
      this.appointmentService.checkSlotsAvailability().then(bookedSlots => {
        //  this.timeslots.slots = this.timeslots.slots.filter(x => !(bookedSlots.includes(`${x.start} - ${x.end}`)));
        this.timeslots.slots = this.timeslots.slots.filter(x => {
          let flag = 0;
          bookedSlots.forEach(y => {
            if (`${x.start} - ${x.end}` === y.slot && y.status === "booked") {
              console.log("Does not include" + y.slot + x.start + x.end);
              flag = 1;
            }
          });
          if (flag == 0) {
            return true;
          } else {
            return false;
          }
        });
        this.selectedSlot =
          this.appointmentService.getSelectedSlot() !== undefined
            ? this.appointmentService.getSelectedSlot()
            : "";
        let now = moment();
        console.log(now.format());
        if (date.isSame(now, "day")) {
          let timezone = this.appointmentService.getTimezone();
          let hour_from_now = now.tz(timezone).add(1, "hours");
          this.timeslots.slots = this.timeslots.slots.filter(x =>
            moment
              .tz(
                `${this.selectedDate} ${x.start}`,
                "dddd, MMMM D YYYY h:mm a",
                timezone
              )
              .isAfter(hour_from_now, "hour")
          );
        }
        this.isReady = true;
      });
      // });
    } else {
      this.router.navigateByUrl(
        `/reschedule-calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }

  setTimeslot(timeslot: any) {
    //let selectedTimeslot = `${timeslot.start} - ${timeslot.end}`;
    //this.appointmentService.setSelectedSlot(selectedTimeslot);
    this.appointmentService.setSelectedSlot(timeslot);
    this.router.navigateByUrl("/reschedule-confirm-appointment");
  }

  getOldTimeDisabled() {
    this.oldDate = this.appointmentService.getOldAppointmentDate();
    //console.log(this.oldDate);
    let date = this.appointmentService.getSelectedDate();
    this.newDate = date.toDate();
    //console.log(this.newDate);
    this.getLocations();
    if (
      this.newDate.getDate() == this.oldDate.getDate() &&
      this.newLocation == this.oldLocation
    ) {
      this.showSlot = true;
      this.appointmentService.checkSlotsAvailability().then(bookedSlots => {
        this.oldTimeslot = this.appointmentService.getOldAppointmentTime();
        console.log(this.oldTimeslot);
      });
    } else {
      this.showSlot = false;
      console.log(this.showSlot);
    }
  }

  getLocations() {
    let currentLocation = this.appointmentService.getSettings();
    this.newLocation = currentLocation.location.name;
    console.log(this.newLocation);
    this.oldLocation = this.appointmentService.getOldLocation();
    console.log(this.oldLocation);
  }

  goBack() {
    //this.appointmentService.setSelectedSlot('');
    this.appointmentService.setSelectedSlot({});
    if (this.appointmentService.multipleLocations === true) {
      this.router.navigate(
        [
          "/reschedule-calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { multipleLocations: true } }
      );
    } else if (this.appointmentService.providerLocations === true) {
      this.router.navigate(
        [
          "/reschedule-calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { providerLocations: true } }
      );
    } else if (this.appointmentService.multipleProviders === true) {
      this.router.navigate(
        [
          "/reschedule-calendar",
          this.appointmentService.acc_slug,
          this.appointmentService.calendar_slug
        ],
        { queryParams: { multipleProviders: true } }
      );
    } else {
      this.router.navigateByUrl(
        `/reschedule-calendar/${this.appointmentService.acc_slug}/${this.appointmentService.calendar_slug}`
      );
    }
  }
}
