export const US_States = [
    { value: 'AL', viewValue: 'Alabama' },
    { value: 'AK', viewValue: 'Alaska' },
    { value: 'AZ', viewValue: 'Arizona' },
    { value: 'AR', viewValue: 'Arkansas' },
    { value: 'CA', viewValue: 'California' },
    { value: 'CO', viewValue: 'Colorado' },
    { value: 'CT', viewValue: 'Connecticut' },
    { value: 'DE', viewValue: 'Delaware' },
    { value: 'FL', viewValue: 'Florida' },
    { value: 'GA', viewValue: 'Georgia' },
    { value: 'HI', viewValue: 'Hawaii' },
    { value: 'ID', viewValue: 'Idaho' },
    { value: 'IL', viewValue: 'Illinois' },
    { value: 'IN', viewValue: 'Indiana' },
    { value: 'IA', viewValue: 'Iowa' },
    { value: 'KS', viewValue: 'Kansas' },
    { value: 'KY', viewValue: 'Kentucky' },
    { value: 'LA', viewValue: 'Louisiana' },
    { value: 'ME', viewValue: 'Maine' },
    { value: 'MD', viewValue: 'Maryland' },
    { value: 'MA', viewValue: 'Massachusetts' },
    { value: 'MI', viewValue: 'Michigan' },
    { value: 'MN', viewValue: 'Minnesota' },
    { value: 'MI', viewValue: 'Mississippi' },
    { value: 'MO', viewValue: 'Missouri' },
    { value: 'MT', viewValue: 'Montana' },
    { value: 'NE', viewValue: 'Nebraska' },
    { value: 'NV', viewValue: 'Nevada' },
    { value: 'NH', viewValue: 'New Hampshire' },
    { value: 'NJ', viewValue: 'New Jersey' },
    { value: 'NM', viewValue: 'New Mexico' },
    { value: 'NY', viewValue: 'New York' },
    { value: 'NC', viewValue: 'North Carolina' },
    { value: 'ND', viewValue: 'North Dakota' },
    { value: 'OH', viewValue: 'Ohio' },
    { value: 'OK', viewValue: 'Oklahoma' },
    { value: 'OR', viewValue: 'Oregon' },
    { value: 'PA', viewValue: 'Pennsylvania' },
    { value: 'RI', viewValue: 'Rhode Island' },
    { value: 'SC', viewValue: 'South Carolina' },
    { value: 'SD', viewValue: 'South Dakota' },
    { value: 'TN', viewValue: 'Tennessee' },
    { value: 'TX', viewValue: 'Texas' },
    { value: 'UT', viewValue: 'Utah' },
    { value: 'VT', viewValue: 'Vermont' },
    { value: 'VA', viewValue: 'Virginia' },
    { value: 'WA', viewValue: 'Washington' },
    { value: 'WV', viewValue: 'West Virginia' },
    { value: 'WI', viewValue: 'Wisconsin' },
    { value: 'WY', viewValue: 'Wyoming' },
];

export const IN_States = [
  {value: 'AP', viewValue: 'Andhra Pradesh'},
  {value: 'AR', viewValue: 'Arunachal Pradesh'},
  {value: 'AS', viewValue: 'Assam'},
  {value: 'BR', viewValue: 'Bihar'},
  {value: 'CG', viewValue: 'Chhattisgarh'},
  {value: 'GA', viewValue: 'Goa'},
  {value: 'GJ', viewValue: 'Gujarat'},
  {value: 'HR', viewValue: 'Haryana'},
  {value: 'HP', viewValue: 'Himachal Pradesh'},
  {value: 'JK', viewValue: 'Jammu and Kashmir'},
  {value: 'JH', viewValue: 'Jharkhand'},
  {value: 'KA', viewValue: 'Karnataka'},
  {value: 'KL', viewValue: 'Kerala'},
  {value: 'MP', viewValue: 'Madhya Pradesh'},
  {value: 'MH', viewValue: 'Maharashtra'},
  {value: 'MN', viewValue: 'Manipur'},
  {value: 'ML', viewValue: 'Meghalaya'},
  {value: 'MZ', viewValue: 'Mizoram'},
  {value: 'NL', viewValue: 'Nagaland'},
  {value: 'OR', viewValue: 'Orissa'},
  {value: 'PB', viewValue: 'Punjab'},
  {value: 'RJ', viewValue: 'Rajasthan'},
  {value: 'SK', viewValue: 'Sikkim'},
  {value: 'TN', viewValue: 'Tamil Nadu'},
  {value: 'TR', viewValue: 'Tripura'},
  {value: 'UK', viewValue: 'Uttarakhand'},
  {value: 'UP', viewValue: 'Uttar Pradesh'},
  {value: 'WB', viewValue: 'West Bengal'},
  {value: 'TS', viewValue: 'Telangana'},
  {value: 'AN', viewValue: 'Andaman and Nicobar Islands'},
  {value: 'CH', viewValue: 'Chandigarh'},
  {value: 'DH', viewValue: 'Dadra and Nagar Haveli'},
  {value: 'DD', viewValue: 'Daman and Diu'},
  {value: 'DL', viewValue: 'Delhi'},
  {value: 'LD', viewValue: 'Lakshadweep'},
  {value: 'PY', viewValue: 'Pondicherry'},
];
