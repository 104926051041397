<div class="spinner" *ngIf="loading" style="overflow-y: hidden;">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <p>Loading...</p>
</div>
<div *ngIf="!loading">
    <div *ngIf="calenderArray.length>0 else noCalender"
        style="display: flex; flex-flow: row wrap;justify-content: center;padding-top: 1em;">
        <div *ngFor="let calender of calenderArray">
            <mat-card id="matCard">
                <a [routerLink]="['/recurring', acc_slug, 'book',calender.slug,contactId]">
                    <h4>
                        {{calender.name}}
                    </h4>
                </a>
                <p>
                    Total Follow-up Visits: {{calender.recuringCalndarSetting.numberOfVisit}}
                </p>
                <p>
                    Minimum Interval : {{calender.recuringCalndarSetting.recurringInterval.minDay}} {{'days'}}
                </p>
                <p>
                    Maximum Interval: {{calender.recuringCalndarSetting.recurringInterval.maxDay}} {{'days'}}
                </p>
                <a [routerLink]="['/recurring', acc_slug, 'book',calender.slug,contactId]">
                    <h4>
                        {{calender.slug}}
                    </h4>
                </a>
            </mat-card>
        </div>
    </div>
    <ng-template #noCalender>
        <h3 style="text-align: center;padding-top: 4em;">
            Your have not configured any calendar for follow-up visits.
        </h3>
    </ng-template>
</div>