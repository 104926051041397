<div style="overflow-x: hidden;">
  <div class="row">
    <div class="col-md-12">
      <div class="col-md-1">
      </div>
      <div class="col-md-11">
        <h3 style="padding: 1rem;"> Confirm
          Appointments</h3>
      </div>
    </div>
  </div>

  <div class="container-fluid" style="    padding-top: 30px;">
    <form>
      <div class="col-md-1">
      </div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="first_name" [(ngModel)]="contactInfo.first_name" size="100" placeholder="First Name"
                readonly>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field class="example-full-width">
              <input matInput name="last_name" [(ngModel)]="contactInfo.last_name" placeholder="Last Name" readonly>
            </mat-form-field>
          </div>
        </div>

        <!-- Removed phone number pattern check -->
        <div class="row">
          <div class="col-md-3">
            <mat-form-field class="example-full-width">

              <input matInput id="phone_no" #country_code="ngModel" name="country_code"
                [(ngModel)]="contactInfo.countryCode" pattern="" placeholder="Country Code" readonly />
              <!-- <mat-select name="countryCode" [(ngModel)]="contactInfo.countryCode" [disabled]="true">
                <mat-option *ngFor="let country of countryCodes" [value]="country.code">
                  {{ country.code }} ({{ country.name }})
                </mat-option>
              </mat-select> -->
            </mat-form-field>
          </div>
          <div class="col-md-9">
            <mat-form-field class="example-full-width">
              <input matInput id="phone_no" #phone_no="ngModel" name="phone_no" [(ngModel)]="contactInfo.phone"
                pattern="" placeholder="Phone Number" readonly />
            </mat-form-field>
          </div>
        </div>

        <!-- Removed email pattern check [A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$ -->
        <div class="row">
          <div class="col-md-12">
            <mat-form-field class="example-full-width">
              <input matInput id="email" #email="ngModel" name="email" [(ngModel)]="contactInfo.email"
                placeholder="Email" readonly />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-1">
      </div>
    </form>
  </div>

  <div class="container-fluid" style="    padding-top: 30px;">
    <form>
      <div class="col-md-1">
      </div>
      <div class="col-md-10">
        <div class="row">
          <table>
            <tr>
              <th>Sr. no</th>
              <th>Date</th>
              <th>Slot</th>
            </tr>
            <tr *ngFor="let content of recurringSchedule;let i=index">
              <td>{{i+1}}</td>
              <td>{{content.date | date:'fullDate'}}</td>
              <td>{{content.selectedSlot.start }} - {{content.selectedSlot.end}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-md-1">
      </div>
    </form>
  </div>


  <div class="row">
    <div class="col-md-12">
      <div class="col-md-1">
      </div>
      <div class="col-md-5" style="text-align: start;">
        <button mat-raised-button (click)="back()" color="primary" type="submit">
          Back
        </button>
      </div>
      <div class="col-md-6" style="text-align: right;">
        <button mat-raised-button (click)="confirmApoinments()" color="primary" type="submit">
          Confirm Appointment
        </button>
      </div>
    </div>
  </div>
</div>