<div class="spinner" *ngIf="loading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  <p>Loading...</p>
</div>


<div *ngIf="!loading" class="container-fluid" id="deskView">

  <div *ngIf="status === 'confirmed'else unconfirmDesk">
    <div class="row" id="subHeader">
      <div class="col-xs-4">
      </div>
      <div style="text-align: center;" class="col-xs-4">
        <b>Confirmation</b>
      </div>
      <div class="col-xs-4">

      </div>
    </div>

    <div class="row" style="background-color: white;height: 60vh;margin-top: 1em;">
      <div class="col-md-12 text-center">
        <div style="height: 6em;" *ngIf="clinic_img_url">
          <img id="clinic_url" [src]="clinic_img_url">
          <!-- <h4>{{location.name}}</h4> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" style="margin-top: 3em;">
          <div class="row">
            <div class="col-md-2">
            </div>
            <div class="col-md-10">
              <b style="font-size: large;">
                {{appointmentService.accDetails.requestedAppointment?'Appointment Requested!':
                rescheduleApptService.formData!= undefined?'Appointment Rescheduled!'
                :'Appointment Confirmed!'}}
              </b>
              <p>Please check your email for confirmation.</p>
              <br>
              <div *ngIf="providerDeatils.img_url" class="row">
                <div class="col-md-3">
                  <div>
                    <img id="doctorImg" [src]="providerDeatils.img_url" alt="imageAlt">
                  </div>
                </div>
                <div class="col-md-9" style="padding: 0;">
                  <p style="margin-top: 1em;">You have
                    {{appointmentService.accDetails.requestedAppointment?'requested ':
                    'booked ' }}an appointment with {{providerDeatils.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1 vertical_dotted_line">
        </div>
        <div class="col-md-5" style="margin-left: -3em;
      margin-top: 3em;">

          <div class="row" *ngIf="setting.services.enabled==true && selectedServices">
            <div class="col-md-2">
              <span class="material-icons">
                add_circle
              </span>
            </div>
            <div class="col-md-10" style="text-align: left;">
              <span>
                <p> {{selectedServices}}
                </p>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <span class="material-icons">
                watch_later
              </span>
            </div>
            <div class="col-md-10" style="text-align: left;">
              <span>
                <p>{{selectedDate |date:'fullDate'}},{{" "}}{{selectedSlot.start}}</p>

              </span>
            </div>

          </div>
          <div class="row ">
            <div class="col-md-2">
              <span style="font-size: xx-large;margin-left: -5px;" class="material-icons">
                location_on
              </span>
            </div>
            <div style="text-align: left;" class="col-md-10">
              <span *ngIf="appointmentService.formData.type=='Virtual Visit';else displayLocation">
                <p>{{calendar_configurations.virtualConfirmationMessege}}</p>
              </span>
              <ng-template #displayLocation>
                <span>
                  <p>{{location.address}}</p>
                  <a [href]="location.map_link" target="_blank">
                    <p>Get Directions</p>
                  </a>
                </span>
              </ng-template>
            </div>

          </div>
          <div class="row">
            <div class="col-md-2">
              <span class="material-icons">
                call
              </span>
            </div>
            <div class="col-md-10" style="text-align: left;">
              <span>
                <div class="row">
                  <p> Phone:
                    <a href="tel:{{ location.phone }}">
                      {{location.phone}}
                    </a>
                  </p>
                </div>

              </span>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="settings.hideBookAnotherButton!=true" class="container text-center" id="bookAnotherAptButton">
      <button type="button" style="margin-bottom: -4em;" (click)="bookAnotherAppointmentClick()" class="btn">Book
        Another
        Appointment</button>
    </div>
  </div>

  <ng-template #unconfirmDesk>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div *ngIf="showLogo" style="padding-bottom: 20px;">
          <img class="imageSize" [src]="settings.redirectDetails.displayLink">
        </div>

        <div *ngIf="status === 'unconfirmed' && errorIn === 'saving'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">
            There was an error while adding your appointment. Please try again.
          </p>
          <button class="btn" (click)="router.navigateByUrl('/confirm-appointment')">
            Re-confirm appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'confirming'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, this slot has now become unavailable.</p>
          <p>Please pick a different date and/or slot.</p>
          <button class="btn" (click)="goBack()">
            Pick Date
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'payment'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, the transaction failed</p>
          <p>Please try to book an appointment again by clicking the button below</p>
          <button class="btn" (click)="gobackToCalendarProject()">
            Book Appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'timeout'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, this transaction timed out.</p>
          <p>Please try to book an appointment again by clicking the button below</p>
          <button class="btn" (click)="gobackToCalendarProject()">
            Book Appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'paymentCanceled'">
          <i class="material-icons status-symbol">cancel</i>
          <div *ngIf="showRedirectUrlIfNotInIframe" style="padding-bottom: 10px;">
            <a [href]="settings.redirectDetails.redirectLink">{{settings.redirectDetails.redirectLinkText}}</a>
          </div>
          <p class="highlight">You have canceled the appointment</p>
          <p>You can book an appointment again by clicking the button below</p>
          <button class="btn" (click)="gobackToCalendarProject()">
            Book Appointment
          </button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </ng-template>
</div>

<div *ngIf="!loading" class="container-fluid" id="mobileView">
  <div *ngIf="status === 'confirmed' else unconfirMob">
    <div class="row" id="subHeader">
      <div class="col-xs-12" style="font-size:large;text-align: center;">
        <b>Confirmation</b>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center" *ngIf="clinic_img_url">
        <div>
          <img id="clinic_url" [src]="clinic_img_url">
        </div>
      </div>
      <div class="col-xs-12">
        <b style="font-size: large;"> {{appointmentService.accDetails.requestedAppointment?'Appointment Requested!':
          rescheduleApptService.formData!= undefined?'Appointment Rescheduled!'
          :'Appointment Confirmed!'}}</b>
        <p>Please check your email for confirmation.</p>
      </div>
      <div class="col-xs-12">
        <div class="row" *ngIf="providerDeatils.img_url;">
          <div class="col-xs-3">
            <div>
              <img id="doctorImg" [src]="providerDeatils.img_url" alt="imageAlt">
            </div>
          </div>
          <div class="col-xs-9" style="    padding-left: 35px;
        padding-bottom: 10px;">
            <p>You have
              {{appointmentService.accDetails.requestedAppointment?'requested ':
              'booked ' }}an appointment with {{providerDeatils.name}} </p>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row" *ngIf="setting.services.enabled==true && selectedServices">
          <div class="col-xs-2">
            <span class="material-icons">
              add_circle
            </span>
          </div>
          <div class="col-xs-10">
            <span>
              <p> {{selectedServices}}
              </p>
            </span>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2">
            <span class="material-icons">
              watch_later
            </span>
          </div>
          <div class="col-xs-10">
            <span>
              <p>
                {{selectedDate |date:'fullDate'}},{{selectedSlot.start}}
              </p>
            </span>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2">
            <span style="font-size: xx-large;margin-left: -5px;" class="material-icons">
              location_on
            </span>
          </div>
          <div class="col-xs-10">
            <span *ngIf="appointmentService.formData.type=='Virtual Visit';else displayLocation">
              <p>{{calendar_configurations.virtualConfirmationMessege}}</p>
            </span>
            <ng-template #displayLocation>
              <span>
                <p>{{location.address}}</p>
                <a [href]="location.map_link" target="_blank">
                  <p>Get Directions</p>
                </a>
              </span>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-2">
            <span class="material-icons">
              call
            </span>
          </div>
          <div class="col-xs-10">
            <span>
              <div class="row">
                <p> Phone:
                  <a href="tel:{{ location.phone }}">
                    {{location.phone}}
                  </a>
                </p>
              </div>
            </span>
          </div>
        </div>
      </div>


    </div>
  </div>
  <ng-template #unconfirMob>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div *ngIf="showLogo" style="padding-bottom: 20px;">
          <img class="imageSize" [src]="settings.redirectDetails.displayLink">
        </div>

        <div *ngIf="status === 'unconfirmed' && errorIn === 'saving'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">
            There was an error while adding your appointment. Please try again.
          </p>
          <button class="back-btn" (click)="router.navigateByUrl('/confirm-appointment')" mat-raised-button
            color="primary">
            Re-confirm appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'confirming'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, this slot has now become unavailable.</p>
          <p>Please pick a different date and/or slot.</p>
          <button class="btn" (click)="goBack()">
            Pick Date
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'payment'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, the transaction failed</p>
          <p>Please try to book an appointment again by clicking the button below</p>
          <button class="btn" (click)="goBack()">
            Book Appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'timeout'">
          <i class="material-icons status-symbol">cancel</i>
          <p class="highlight">Sorry, this transaction timed out.</p>
          <p>Please try to book an appointment again by clicking the button below</p>
          <button class="btn" (click)="goBack()" mat-raised-button>
            Book Appointment
          </button>
        </div>
        <div *ngIf="status === 'unconfirmed' && errorIn === 'paymentCanceled'">
          <i class="material-icons status-symbol">cancel</i>
          <div *ngIf="showRedirectUrlIfNotInIframe" style="padding-bottom: 10px;">
            <a [href]="settings.redirectDetails.redirectLink">{{settings.redirectDetails.redirectLinkText}}</a>
          </div>
          <p class="highlight">You have canceled the appointment</p>
          <p>You can book an appointment again by clicking the button below</p>
          <button class="btn" (click)="goBack()">
            Book Appointment
          </button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </ng-template>
</div>

<div class="footer">
  <app-footer [back]="false" [summary]="false" [next]="false" (goBack)="goBack()"
    [bookAnotherAppointment]="!errorExist">
  </app-footer>
</div>